// React
import React from 'react';
import { StrictMode } from 'react';

// Components
import TournamentMatchesDaysAdminComponent from '../tournament-matches-days-admin/TournamentMatchesDaysAdminComponent'; 
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentGroupsNavbarComponent from 'app/leagues/components/tournament-groups-navbar/TournamentGroupsNavbarComponent';
import TournamentMatchAdminFilterComponent from '../tournament-match-admin-filter/TournamentMatchAdminFilterComponent';

// Styles
import './TournamentMatchesAdminComponent.styles.css';

// Globals
import { api_url } from "config/GlobalData";

//i18
import { useTranslation } from 'react-i18next';



function TournamentMatchesAdminComponent( props ) {    

    const { t } = useTranslation('league');
    const [activeDate, setActiveDate] = React.useState({});
    const [filterParams, setFilterParams] = React.useState({});
    const [activeGroupId, setActiveGroupId] = React.useState('');
    const [localDateMatches, setLocalDateMatches] = React.useState([]);

    React.useEffect( () => {
        if(props.tournament_dates?.length > 0 ){
            setActiveDate(props.tournament_dates[0]);
            props.handleDateClick(props.tournament_dates[0]);
        }else if(props.tournament_dates?.length === 0){
            props.handleDateClick({});
        }
    }, [ JSON.stringify(props.tournament_dates) ] )   // eslint-disable-line 

    React.useEffect( () => {
        setLocalDateMatches(props.date_matches);
    }, [ JSON.stringify(props.date_matches) ] )   // eslint-disable-line 

    const handleFilterSearchMatchAdmin = async (params, date_id) => {
        
        let match_queries = "";
        let day_queries = "";
        
        setFilterParams(params);
        
        if(date_id) {
            activeDate.id = date_id;
        }

        for (const key in params) {            
            if (params.hasOwnProperty(key) && (params[key] || params[key] === 0)) {
                if (["team_id", "field_id", "official_referee", "state", "match_date", "order_by_match"].includes(key)) {
                    match_queries += `${key}=${params[key]}&`;
                } else if (["matchday", "order_by_day", "match_order_by"].includes(key)) {
                    day_queries += `${key}=${params[key]}&`;
                }
            }
        }

        // Limpia y prepara los query params
        match_queries = match_queries.slice(0, -1);
        day_queries = day_queries.slice(0, -1);
        match_queries = match_queries.replace("order_by_match=match_older_recent", "order_by=Asc");
        match_queries = match_queries.replace("order_by_match=match_recent_older", "order_by=Desc");
        day_queries = day_queries.replace("order_by_day=older_recent", "order_by=Desc");
        day_queries = day_queries.replace("order_by_day=recent_older", "order_by=Asc");

        let active_date_id = date_id ? date_id : activeDate.id;    
        
        if (match_queries && !day_queries) {
            await props.handleTournamentPhaseDaysFilters(activeGroupId, '?pagination=False');          
            await props.handleTournamentPhaseMatchFilters(active_date_id, `${match_queries}`);
        } else if (!match_queries && day_queries) {
            await props.handleTournamentPhaseDaysFilters(activeGroupId, `?${day_queries}&pagination=False`);
            await props.handleTournamentPhaseMatchFilters(active_date_id, '');
        } else if (match_queries && day_queries){
            await props.handleTournamentPhaseDaysFilters(activeGroupId, `?${day_queries}&pagination=False`);
            await props.handleTournamentPhaseMatchFilters(active_date_id, `${match_queries}`);
        } else {
            await props.handleTournamentPhaseDaysFilters(activeGroupId, '?pagination=False');
            await props.handleTournamentPhaseMatchFilters(active_date_id, '');
        } 
    }

    const handleDateClick = (day) => {
        setActiveDate(day);
        if(hasNonEmptyValues()) {
            handleFilterSearchMatchAdmin(filterParams, day.id);
        } else {
            props.handleDateClick(day);
        }        
    }
    
    // Revisa si los filtros tienen valores
    function hasNonEmptyValues() {
        for (const key in filterParams) {
            if (filterParams.hasOwnProperty(key) && (filterParams[key] !== undefined && filterParams[key] !== '' && filterParams[key] !== null)) {
            return true;
            }
        }
        return false;
    }

    /* const handleFilter=(ev)=> {
        let filteredMatches = props.date_matches?.filter(match => ( match.local_team_name?.toLowerCase().includes(ev.target.value?.toLowerCase()) || match.visitant_team_name?.toLowerCase().includes(ev.target.value?.toLowerCase()) || match.field?.toLowerCase().includes(ev.target.value?.toLowerCase()) || match.referee?.full_name?.toLowerCase().includes(ev.target.value?.toLowerCase()) ));
        setLocalDateMatches(filteredMatches);
    } */

    return (
        <div className='tournament-matches-admin__container' >
            <div className='tournament-matches-admin__board'>
                <div className='tournament-matches-admin__board__btns-add'>
                    <div className='baloa-subtitle-2'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelMatches')}</div>
                    <div className='tournament-matches-admin__board__btns-add__btns'>
                        <SecondaryButtonComponent 
                            onClick={ () => props.handleNewMatchTournament(true) }
                            disabled={props.tournament_active_phase?.state === 'Finalized'}
                        >
                            <span className='hubicon-add_option' />
                            <span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewMatch')}</span>
                        </SecondaryButtonComponent>
                        <SecondaryButtonComponent 
                            onClick={ () => props.handleNewDateTournamentClick() }
                            disabled={props.tournament_active_phase?.state === 'Finalized'}
                        >
                            <span className='hubicon-add_option' />
                            <span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewDay')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div>
                <div className='tournament-matches-admin__groups'>
                    <TournamentGroupsNavbarComponent       
                        groups = { props.groups }                    
                        handleGroupClick={ ( group ) => {
                            setActiveGroupId(group.id);
                            props.handleGroupClick( group );
                        }}
                        showGroupNavBar = { props.showGroupNavBar }   
                        tournament_active_phase={props.tournament_active_phase}
                    />
                </div>
                <div className='tournament-matches-admin__board__input-search__btn-calendar'>
                    {/* <div className='tournament-matches-admin__board__input-search' >
                        <span className="icon-search" />
                        <input className="league-tournament__search_input baloa-names"
                            type="text" placeholder={t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.placeholderSearchMatch')}
                            onChange={(event) => { handleFilter(event) }}></input>
                    </div> */}
                    <SecondaryButtonComponent 
                        onClick={ () => {
                            const url = `${api_url}/tournament-phases/${props.tournament_active_phase.id}/export-calendar/`;
                            window.location.href = url;
                        }}
                        className='tournament-matches-admin__export-excel'
                        
                    >
                        <span className='hubicon-file_download' />
                        <span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelDownloadCalendar')}</span>
                    </SecondaryButtonComponent>
                </div>
                <div className='tournament-matches-admin__board__filters '>
                    {/* FiltersComponent */}
                    <StrictMode>
                        <TournamentMatchAdminFilterComponent
                            handleFilterSearchMatchAdmin={ (params) => handleFilterSearchMatchAdmin(params, null) }
                            //phase_teams={ props.phase_teams }
                            tournament_fields={ props.tournament_fields }
                            tournament_dates={ props.tournament_dates }
                            language_code =  {props.language_code }
                            phase_teams={props.list_phase_teams}//Se envia el listado de equipos apropiadopar el componente
                            tournament_staff={props.tournament_staff}
                        />
                    </StrictMode>
                </div>
                <TournamentMatchesDaysAdminComponent
                    tournament_dates={ props.tournament_dates }
                    date_matches={ localDateMatches }
                    staff={props.staff}
                    handleDateClick={ (day) => handleDateClick(day) }
                    handleDeleteMatchTournament={ props.handleDeleteMatchTournament }
                    deleteDate={ (day, tournament_dates_length)=> {props.deleteDate(day, tournament_dates_length, activeGroupId) } }
                    handleOnFinishMatchActionClick={ props.handleOnFinishMatchActionClick }
                    handleEditMatchTournament={ props.handleEditMatchTournament }
                    tournament_fields={ props.tournament_fields }
                    handleSubmitEditInfoMatch={ props.handleSubmitEditInfoMatch }
                    cities = { props.cities }
                    new_page_field={ props.new_page_field }
                    handleFieldsAdminActions={ props.handleFieldsAdminActions }
                    handleTournamentFieldActions={ props.handleTournamentFieldActions }
                    tournament_selected_date={ props.tournament_selected_date }
                    has_filters={ hasNonEmptyValues() }
                    handleNewMatchTournament={ () => props.handleNewMatchTournament(true) }
                    tournament_dates_filter={ props.tournament_dates_filter }
                    handleAddStaf= { props.handleAddStaf }
                    handleMatchReport={ props.handleMatchReport }
                    date_matches_is_loading={ props.date_matches_is_loading }
                    handleDownloadMatchReport={ props.handleDownloadMatchReport }

                    next_date_matches={props.next_date_matches}
                    handleViewMoreMatches={props.handleViewMoreMatches}
                    active_group_id={activeGroupId}
                />
            </div>
            <div className='tournament-matches-admin__actions'>
                <SecondaryButtonComponent 
                    onClick={ () => props.handleOnAutomaticFixture() }
                    disabled={props.tournament_active_phase?.state === 'Finalized'}
                >
                    {t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelTitleAutomaticFixtue')}
                </SecondaryButtonComponent>
                <SecondaryButtonComponent onClick={ () => props.handleTournamentFieldsAdmin() } >{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelFieldsAdmin')}</SecondaryButtonComponent>
                <SecondaryButtonComponent onClick={ () => props.changeTab(5) } >{t('options.tournaments.pages.newTournament.tabs.tabStaff.labelTitle')}</SecondaryButtonComponent>
            </div>
        </div>
    );
}

export default TournamentMatchesAdminComponent;