/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMatchVideosComponent.styles.css';

//Assets
import whatsAppImg from 'assets/images/help_WhatsApp_2.png';
import empty_match_clips from 'assets/images/empty_match_clips.png';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaPlayMatchVideosComponent( props ) {

    return(
        <div className='baloa-play-match-videos__container'>
            <div className='baloa-play-match-videos__left-col'>
                <div className='baloa-play-match-videos__match-info'>
                    <div className='baloa-subtitle-2'>{props.selected_match?.match_name}</div>
                    <div className='baloa-play-match-videos__match-info__category-info'>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.selected_match?.tournament_type}`)}</div>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.selected_match?.football_type}`)}</div>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.selected_match?.gender}`)}</div>                        
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabTeams.${props.selected_match?.category}`)}</div>
                    </div>
                    <div className='baloa-play-match-videos__match-info__location'>
                        {props.selected_match?.visibility === 'Visible'?
                            <span className='hubicon-public'/>
                            :
                            <span className='hubicon-public_off'/>
                        }                  
                        <div className='baloa-table'>{props.t(`videoVisibility.${props.selected_match?.visibility}`)}</div>
                        <span className='hubicon-location'/>
                        <div className='baloa-table'>{`${props.selected_match?.main_country} - ${props.selected_match?.main_city}`}</div>
                    </div>
                    <div className='baloa-play-match-videos__match-info__buttons'>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{ props.selected_match?.is_blocked? props.handleOnPlayBlockedMatch(props.selected_match?.id) : props.handleOnPlayVideo(0)}}
                            //disabled={props.selected_match?.is_blocked}
                        >
                            <span className='hubicon-play_arrow icon'></span>
                            <span>{props.t('playMatchBtnLbl')}</span>
                        </PrimaryButtonComponent>
                        <SecondaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnShareMatch(props.selected_match?.id)}}
                        >
                            <span className='hubicon-share_v2 icon'></span>
                            <span>{props.t('shareMatchBtnLbl')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div>
                <div className='baloa-play-match-videos__asset-container'>
                    {props.selected_match?.assets?.map((asset, index) => 
                        <div className='baloa-play-match-videos__asset' key={index} onClick={()=>{ asset.is_blocked? props.handleOnPlayBlockedMatch(props.selected_match?.id) : props.handleOnPlayVideo(index)}}>
                            <div className='baloa-play-match-videos__asset__image'>
                                {asset.is_blocked &&
                                    <span className='hubicon-lock_fill'/>
                                }
                                {!asset.is_blocked &&
                                    <div className='baloa-play-match-videos__asset__play-image' />
                                }
                                <img src={asset.post_stream?.thumbnail? asset.post_stream?.thumbnail: ''} alt={props.selected_match?.match_name} />
                            </div>
                            <div className='baloa-subtitle-2'>{asset.post_video_caption}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='baloa-play-match-videos__right-col'>
                {(props.selected_match?.user?.subscription_plan === 'FREE' || !props.selected_match?.user) && props.selected_match?.is_blocked &&
                    <div className='baloa-play-match-videos__payment-widget'>
                        <div className='baloa-play-match-videos__payment-widget__title'>
                            <span className='hubicon-play_circle'/>
                            <div className='baloa-normal-medium'>{props.t('paymentTitle')}</div>
                        </div>
                        <div className='baloa-names'>{props.t('paymentMessage',{_value: props.selected_match?.baloa_match_recording_price?.value, _currency: props.selected_match?.baloa_match_recording_price?.currency})}</div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnPayMatch(props.selected_match?.id)}}
                        >
                            <span>{props.t('paymentBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                }
                {
                    <div className='baloa-play-match-videos__clips'>
                        <div className='baloa-play-match-videos__clips-title'>
                            <span className='hubicon-video_library'/>
                            <div className='baloa-normal-medium'>{props.t('clipsTitle')}</div>
                            {props.match_clips?.length > 0 &&
                                <div className='baloa-names' onClick={()=>{ props.handleViewAllClips()}}>{props.t('clipViewAllLinkLbl')}</div>
                            }
                        </div>
                        {props.match_clips?.length > 0 &&
                            <React.Fragment>
                                <div className='baloa-names'>{props.t('clipsMessage')}</div>
                                <div className='baloa-play-match-videos__clips-container'>
                                    {props.match_clips?.map( (clip, indc) =>
                                        <div className='baloa-play-match-videos__clips__clip' key={indc} onClick={()=>{ props.handleOnPlayClip(indc)}}>
                                            <img src={clip.thumbnail} alt=''/>
                                            <div className='baloa-table-column'>{clip.post_video_caption}</div>
                                        </div>
                                    )}
                                </div>
                                {!props.selected_match?.is_blocked &&
                                    <React.Fragment>
                                        <div className='baloa-names'>{props.t('clipsSupportMessage')}</div>
                                        <a className='baloa-play-match-videos__clips-support' target='_blank' href={'https://wa.link/pwhk7x'}>
                                            <img src={whatsAppImg}/>
                                            <div className='baloa-table-column'>{props.t('clipsSupportLbl')}</div>
                                            <span className='icon-chevron-roght'/>
                                        </a>
                                    </React.Fragment>
                                }
                                {props.selected_match?.is_blocked &&
                                    <div className='baloa-play-match-videos__clips-payment-message'>
                                        <div className='baloa-play-match-videos__clips-payment__title'>
                                            <span className='hubicon-play_arrow'/>
                                            <div className='baloa-normal-medium'>{props.t('clipsPaymentTitle')}</div>
                                        </div>
                                        <div className='baloa-names'>{props.t('paymentMessage',{_value: props.selected_match?.baloa_match_recording_price?.value, _currency: props.selected_match?.baloa_match_recording_price?.currency})}</div>
                                        <PrimaryButtonComponent
                                            input_type={'button'}
                                            onClick={()=>{props.handleOnPayMatch(props.selected_match?.id)}}
                                        >
                                            <span>{props.t('paymentBtnLbl')}</span>
                                        </PrimaryButtonComponent>
                                    </div>
                                }
                                
                            </React.Fragment>
                        }
                        {props.match_clips?.length <= 0 &&
                            <div className='baloa-play-match-videos__empty-message'>
                                <img src={empty_match_clips} alt='No clips'/>
                                <div className='baloa-names'>{props.t('clipsEmptyMessage')}</div>
                                <div className='baloa-play-match-videos__empty-support-message'>
                                    <div className='baloa-names'>{props.t('emptyClipsSupportMessage')}</div>
                                    <a className='baloa-play-match-videos__clips-support' target='_blank' href={'https://wa.link/pwhk7x'}>
                                        <img src={whatsAppImg}/>
                                        <div className='baloa-table-column'>{props.t('clipsSupportLbl')}</div>
                                        <span className='icon-chevron-roght'/>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchVideosComponent);