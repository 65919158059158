/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayClipsModalComponent.styles.css';

//Assests
import whatsAppImg from 'assets/images/help_WhatsApp_2.png';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


function BaloaPlayClipsModalComponent( props ) {

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                <div className='baloa-play-match-videos__clips-title'>
                    <span className='hubicon-video_library'/>
                    <div className='baloa-normal-medium'>{props.t('clipsTitle')}</div>
                </div>
            }
            className="baloa-play-clips-modal"
        >
            <div className={`baloa-play-clips-modal__container `}>
                <div className='baloa-names'>{props.t('clipsMessage')}</div>
                <div className='baloa-play-clips-modal__clips-container'>
                    {props.match_clips?.map( (clip, indc) =>
                        <div className='baloa-play-clips-modal__clips__clip' key={indc} onClick={()=>{ props.handleOnPlayClip(indc)}}>
                            <img src={clip.thumbnail} alt=''/>
                            <div className='baloa-table-column'>{clip.post_video_caption}</div>
                        </div>
                    )}
                </div>
                {!props.match_is_blocked &&
                    <div className="baloa-play-clips-modal__mobile-support">
                        <div className='baloa-names'>{props.t('clipsSupportMessage')}</div>
                        <a className='baloa-play-clips-modal__clips-support' target='_blank' href={'https://wa.link/pwhk7x'}>
                            <img src={whatsAppImg}/>
                            <div className='baloa-table-column'>{props.t('clipsSupportLbl')}</div>
                            <span className='icon-chevron-roght'/>
                        </a>
                    </div>
                }
                {props.match_is_blocked &&
                    <div className='baloa-play-clips-modal__clips-payment-message'>
                        <div className='baloa-play-clips-modal__clips-payment__title'>
                            <span className='hubicon-play_arrow'/>
                            <div className='baloa-normal-medium'>{props.t('clipsPaymentTitle')}</div>
                        </div>
                        <div className='baloa-names'>{props.t('clipsPaymentMessage',{_value: props.selected_match?.baloa_match_recording_price?.value, _currency: props.selected_match?.baloa_match_recording_price?.currency})}</div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnPayMatch(props.selected_match?.id)}}
                        >
                            <span>{props.t('paymentBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                }
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayClipsModalComponent);