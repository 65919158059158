/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './BaloaProSubscriptionWidgetComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import PrimaryButtonComponent from '../primary-button/PrimaryButtonComponent';

function BaloaProSubscriptionWidgetComponent( props ) {

    return(
        <div className='baloa-pro-subscription-widget__container'>
            <div className='baloa-pro-subscription-widget__title'>
                <span className='hubicon-award_star'/>
                <div className='baloa-subtitle-2'>{props.t('baloaProHeader.title')}</div>
            </div>
            <div className='baloa-names'>{props.t('baloaProHeader.subscriptionMsg')}</div>
            <PrimaryButtonComponent
                type={'button'}
                className={''}
                onClick={()=>{ props.handleOnBaloaProSubscription() }}
            >
                <span>{props.t('baloaProSubscriptionBtnLbl')}</span>
            </PrimaryButtonComponent>
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProSubscriptionWidgetComponent);