/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMyClipsComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components


function BaloaPlayMyClipsComponent( props ) {
    return(
        <div className='baloa-play-my-clips__container'>
            {props.my_clips?.map((clip,index) =>
                !isMobile?
                    <div key={index} className='baloa-play-my-clips__clip-container' onClick={()=>{props.handleOnClip(clip.id)}}>
                        <img src={ clip.thumbnail } alt={ clip.post_video_caption } />
                        <div className='baloa-names'>{ clip.post_video_caption }</div>
                        <div className='baloa-play-my-clips__match-data'>
                            <span className='icon-field' />
                            <div className='baloa-table-column'>{clip.post_match_recording_caption}</div>
                        </div>
                    </div>    
                :
                    <div key={index} className='baloa-play-my-clips__clip-container' onClick={()=>{props.handleOnClip(clip.id)}}>
                        <div className='baloa-names'>{ clip.post_video_caption }</div>                        
                        <div className='baloa-play-my-clips__mobile-data'>
                            <img src={ clip.thumbnail } alt={ clip.post_video_caption } />                        
                            <div className='baloa-play-my-clips__match-data'>
                                <span className='icon-field' />
                                <div className='baloa-table-column'>{clip.post_match_recording_caption}</div>
                            </div>
                        </div>
                    </div>    
                
                        
            )}
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMyClipsComponent);