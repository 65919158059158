// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


/* export const GetHomePostsApi = ( next_page ) => {

    return Axios.instance.get( next_page ? next_page : `${api_url}/home-feed/?version=2`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

} */

export const GetBaloaPlaySubSectionsApi = ( ) => {

    return Axios.instance.get( `${api_url}/post-match-subsection/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlaySubSectionApi = ( id_subsection, pager, next_page )=> {

    return Axios.instance.get( next_page ? next_page : `${api_url}/post-match-subsection/${id_subsection}/matches/?page_size=${pager}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}


export const SearchMatchVideosApi = ( search )=> {

    return Axios.instance.get( `${api_url}/post-match-recording/?search=${search}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayMatchApi = ( match_id )=> {

    return Axios.instance.get( `${api_url}/post-match-recording/${match_id}/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayMatchClipsApi = ( post_clip_recording_id )=> {

    return Axios.instance.get( `${api_url}/post-clip-recording/${post_clip_recording_id}/clips/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const SetMatchPaymentApi= ( match_id, metadata )=> {

    return Axios.instance.post( `${api_url}/post-match-recording/${match_id}/checkout/`,
        {  
            redirect: metadata.redirect,
            mobile: metadata.mobile,
            payment_type: metadata.payment_type
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/* export const SetClipPaymentApi= ( clip_id, metadata )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/checkout/`,
        {  
            redirect: metadata.redirect,
            mobile: metadata.mobile,
            payment_type: metadata.payment_type
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
} */

export const SetClipClaimApi = ( clip_id )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/claim/`,
        {  
            mobile: false,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetBaloaPlayClipsApi = ( page_size ) => {

    return Axios.instance.get( `${api_url}/post-clip-asset-recording/?page_size=${page_size}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayClipApi = ( clip_id ) => {

    return Axios.instance.get( `${api_url}/post-clip-asset-recording/${clip_id}/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const UpdateClipApi = ( clip_id, title )=> {

    return Axios.instance.put( `${api_url}/post-clip-asset-recording/${clip_id}/`,
        {  
            post_video_caption: title,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PostClipApi = ( clip_id, body )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/post_video/`,
        body,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}
