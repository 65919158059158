import React from 'react';
import { Link } from 'react-router-dom';


//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentInformationComponent from '../tournament-information/TournamentInformationComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import SelectTeamFieldComponent from 'shared/components/select-team-field/SelectTeamFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueTournamentReportDatesComponent from 'app/leagues/components/league-tournament-report-dates-component/LeagueTournamentReportDatesComponent';
import LeagueTournamentReportCalendarComponent from 'app/leagues/components/league-tournament-report-calendar-component/LeagueTournamentReportCalendarComponent';
import LeagueMatchReportComponent from 'app/leagues/components/league-match-report-component/LeagueMatchReportComponent';
import TournamentPositionGoalScorerTableComponent from 'app/leagues/components/tournament-position-goal-scorer-table/TournamentPositionGoalScorerTableComponent';
import TournamentStatisticsComponent from 'app/leagues/components/tournament-statistics/TournamentStatisticsComponent';
import TournamentNewPodiumComponent from 'app/leagues/components/tournament-new-podium/TournamentNewPodiumComponent';
import InputSearchWithButtonComponent from 'shared/components/input-search-with-button/InputSearchWithButtonComponent';

// Material UI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Assets
import help_icon from 'assets/icons/help-resume-icon.svg';


// Styles
import './TournamentDetailComponent.styles.css';

import estirando1 from 'assets/images/estirando1.png';

// React device detect
import { isMobile } from 'react-device-detect';

//i18
import { withTranslation } from 'react-i18next';


function TabPanel(props) {
	const { children, value, index,  ...other } = props;
    const [listTeamsPlayer,setListTeamsPlayer] = React.useState([]);
    const [listTeamsStaff,setListTeamsStaff] = React.useState([]);
    const [selectedFilter, setSelectedFilter] = React.useState('all-states');
    const [activePhase, setActivePhase] = React.useState('Phase 1'); //eslint-disable-line
    const [ boxPosition, setBoxPosition ] = React.useState(false);
    const [ isBoxOpen, setBoxOpen ] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedindex, setIndex] = React.useState(0);
    const [searchPlayer, setSearchPlayer] = React.useState('');

    const handleSelectedTabChange = (newValue) => {
        setSelectedTab(newValue);
        const newIndex = newValue === 0 ? 0 : 1; 
        setIndex(newIndex);
    };

    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            color: 'var(--primary-dark-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-quarter)',
            fontFamily: 'var(--secondary-font)',
            boxShadow: '0px 1px 4px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
        },
    }));
    const classes = useStyles();

    const handleOnCopyPlayerCode = async (code) => {
        await navigator.clipboard.writeText(code);
        props.showCopyPostMessage(props.t('viewTournament.details.copyLinkSuccess'));
    };

    React.useEffect(()=>{
        if(props.team_players){
            setListTeamsPlayer(props.team_players);
        }
    },[props.team_players])// eslint-disable-line

    React.useEffect(()=>{
        if(props.teams_manager_and_staff){
            setListTeamsStaff (props.teams_manager_and_staff);
        }
    },[props.teams_manager_and_staff])

    const handleFilterState = value => {
        setSelectedFilter(value);
        /* if(value === 'Pending' || value === 'Enrolled'){
            setListTeamsPlayer(props.team_players.filter((item)=> item.player_state === value))
        }else setListTeamsPlayer(props.team_players); */
        props.handleSearchHubTournamentTeamPlayers( searchPlayer, value )
    };

    const handleSearchDetail = (player) => {
        /* const currentWord = word.toLowerCase();
        currentWord.length > 0
        ? setListTeamsPlayer(listTeamsPlayer.filter((item)=>{
            return item['full_name'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(currentWord.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        }))
        : setListTeamsPlayer(props.team_players); */
        setSearchPlayer(player);
        setSelectedFilter('all-states');
        props.handleSearchHubTournamentTeamPlayers( player, 'all-states' );
    };

    const setTeamData = (value) =>{
        if(props.tournament_team_id != value){
            setListTeamsPlayer([]);//Se limpia el listado de jugadores cuando se cambia de equipo para visualizar el skeleton
            props.changeSelectedTournamentTeam(value);
        }
    }

    const handleBoxOpen = (index) => {
        if (!isBoxOpen){
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    }
    
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<div className="edit-team__tabpanel-line">
				{/* tab tournament info */}
				{value === index && index === 0 && (
                    <TournamentInformationComponent
                        tournament={props.tournament}
                        tournament_information_title={props.t('viewTournament.details.title')}
                    />
                )}

                {/* tab tournament teams */}
                {value === index && index === 1 && (
                    <div className='tournament-detail__tournament-teams'>
                        <div className='tournament-detail__tournament-teams__title baloa-subtitle-2'>{props.t('viewTournament.teams.title')}</div>
                        {props.tournament_teams.map((team, index) =>
                            <div key={index} className='tournament-detail__tournament-team'  onClick={() => props.handleTeamMembersModal(team)}>
                                <div key={index} className='tournament-detail__tournament-team__image'>
                                    {team.team_logo?
                                        <img src={team.team_logo} alt={team.team} />
                                    :
                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                    }
                                </div>
                                <div className='tournament-detail__tournament-team__data'>
                                    <div className='baloa-names'>{team.team}</div>
                                    <div className='baloa-username'>
                                        {team.team_gender? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+team.team_gender):''}
                                        &nbsp;&bull;&nbsp;{team.category? props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.'+ team.category):''}</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* tab tournament inscriptions */}
                {value === index && index === 2 && (
                    <div className='tournament-detail__team-container'>
                        {/** esta enrolado y no ha pagado las estadisticas del torneo */}
                        {props.player_state === 'Enrolled' && !props.unlocked_stats &&
                        <div className='tournament-detail__unlocked__info'>
                            <div className='tournament-detail__unlocked__team-info'>
                                <span className='hubicon-info' />
                                <div className='baloa-table-column' >{props.t('viewTournament.inscription.unlockedStats')}</div>
                            </div>
                            <PrimaryButtonComponent 
                                className={"small"} 
                                onClick={() => {props.handleOnResumePaymentStats()}} 
                            >
                                {props.t('profile:tournamentSummary.unlockBtn')}
                            </PrimaryButtonComponent>
                        </div>
                        }
                        <div className='tournament-detail__tournament-data'>   
                        {!isMobile
                            ? <React.Fragment>
                                {/** Vista Desktop */}
                                {(props.team_state === 'Register' || props.team_state === 'Enrolled' || props.team_state === 'Rejected') &&
                                    <React.Fragment>
                                        {/* Team Inscription Info */}
                                        <div className='tournament-detail__team-inscription'>                                                                                     
                                            <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.teamPlayerWidget.widgetTitle')}</div>
                                                {(props.request_is_admin) &&
                                                <React.Fragment>
                                                    {/** Messages */}
                                                    { props.team_state === 'Register' &&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingMsg')}</div>
                                                        </div>
                                                    }
                                                    { props.team_state === 'Rejected' &&
                                                        <div className='tournament-detail__team-rejected-msg' >
                                                            <span className='icon-x' />
                                                            <div className='baloa-table-column'>{props.t('viewTournament.inscription.teamRejectedMsg')} {`${(props.tournament_registration_type === 'Paid' || props.tournament_registration_type === 'Team_paid')? props.t('viewTournament.inscription.refundMsg'): ''}`}</div>
                                                        </div>
                                                    }
                                                    {/** Team Inscrito con formulario y pago pendientes */}
                                                    {props.team_state === 'Enrolled' && props.has_team_form && (props.tournament_registration_type == "Paid" || props.tournament_registration_type === 'Team_paid' ) && (props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') && (props.team_payment_state === 'Postponed' || props.team_payment_state === 'Pending') && !props.team_has_paid &&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingFormPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Team Inscrito con formulario pendiente */}
                                                    {props.team_state === 'Enrolled' && props.has_team_form  && (props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') && (((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && props.team_has_paid)  || props.tournament_registration_type == "Free" )&&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingFormMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Team Inscrito con pago pendiente */}
                                                    {props.team_state === 'Enrolled' && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && !props.team_has_paid && (props.team_form_state === 'Done' || props.team_form_state === 'No_Needed') &&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Info Equipo */}
                                                    <div className='tournament-detail__team-inscription__data'>
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            <div className='tournament-detail__team-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.team')}</div>
                                                            <FormComponent
                                                                formInitialValues={{teamname: props.tournament_team_id}}
                                                                functionDisabled={() => { }}
                                                                onSubmit={() => {}}
                                                                enableReinitialize={true}
                                                                //innerRef={formRef}
                                                            >
                                                                <SelectTeamFieldComponent
                                                                    field_name="teamname"
                                                                    className="tournament-detail__team-inscription-column__team-info"
                                                                    validationFunction={()=>{}}
                                                                    disabled={false}
                                                                    resetError={()=>{}}
                                                                    error={{teamname: ''}}
                                                                    message=""
                                                                    placeholder=""
                                                                    options={props.my_teams}
                                                                    onChange={(value) => {                                                                        
                                                                        if(value){
                                                                            setTeamData(value);
                                                                        }
                                                                        
                                                                    }}
                                                                    defaultValue={props.tournament_team_id}
                                                                />
                                                            </FormComponent>
                                                            
                                                        </div>
                                                        {/** Formulario */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            {/* props.form_state && */ props.has_team_form &&
                                                                <React.Fragment>
                                                                    <div className='tournament-detail__team-inscription-column__title  baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                    {(props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') &&
                                                                        <TextButtonComponent
                                                                            className={'tournament-detail__team-inscription-column__button baloa-username'}
                                                                            onClick={()=>{props.handleRealizeTeamFormButton('teams')}}
                                                                        >
                                                                            <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                        </TextButtonComponent>
                                                                    }
                                                                    {(props.team_form_state === 'Done' || !props.team_form_state) &&
                                                                        <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {(!props.has_team_form || props.team_form_state === 'No_Needed') &&
                                                                <div className='tournament-detail__team-inscription-column__title baloa-table-column'>&nbsp;</div>
                                                            }
                                                        </div>
                                                        {/** Pago */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            {(props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && props.team_payment_state !== 'No_Needed' &&
                                                                <React.Fragment>
                                                                    <div className='tournament-detail__team-inscription-column__title baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div>
                                                                    {(props.team_payment_state === 'Postponed' || props.team_payment_state === 'Pending') &&
                                                                        <TextButtonComponent
                                                                            className={'tournament-detail__team-inscription-column__button baloa-username'}
                                                                            onClick={()=>{props.handleRealizeTeamPaymentButton('teams')}}
                                                                        >
                                                                            <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                        </TextButtonComponent>
                                                                    }
                                                                    {(props.team_payment_state === 'Done' || !props.team_payment_state) &&
                                                                        <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {(props.tournament_registration_type == "Free" || props.tournament_registration_type == "Player_paid" || props.team_payment_state === 'No_Needed') &&
                                                                <div className='tournament-detail__team-inscription-column__title baloa-table-column'>&nbsp;</div>
                                                            }
                                                        </div>
                                                        {/** Estado */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            <div className='tournament-detail__team-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.status')}</div>
                                                            <div className={`tournament-detail__team-inscription-column__status baloa-username ${props.team_state === 'Enrolled'? 'enrolled': (props.team_state === 'Register')? 'register' : ''}`} >{props.t(`viewTournament.inscription.inscriptionStates.${props.team_state}`)}</div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                }
                                                {/** Info Player */}
                                                {(props.request_is_member || props.is_transfer_player) && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.username &&
                                                <React.Fragment>
                                                    {/** Messages */}
                                                    { props.player_state === 'Register' && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingMsg')}</div>
                                                        </div>
                                                    }
                                                    { props.player_state === 'Rejected' &&
                                                        <div className='tournament-detail__team-rejected-msg' >
                                                            <span className='icon-x' />
                                                            <div className='baloa-table-column'>{props.t('viewTournament.inscription.teamRejectedMsg')} {`${(props.tournament_registration_type === 'Paid' || props.tournament_registration_type === 'Player_paid')? props.t('viewTournament.inscription.refundMsg'): ''}`}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con formulario y pago pendientes */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && props.has_player_form && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && (props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') && !props.player_has_paid && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingFormPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con formulario pendiente */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && props.has_player_form && (props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') && (((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && props.player_has_paid) || props.tournament_registration_type == "Free") && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingFormMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con pago pendiente */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && !props.player_has_paid && (props.player_form_state === 'Done' || props.player_form_state === 'No_Needed') && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player no enrolled con fecha inscripcion vencida */}
                                                    {!props.tournament_registration_deadline && (props.player_state === 'Register' || props.player_state === 'Pending' ) &&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerInvalidDateMsg')}</div>
                                                        </div>
                                                    }
                                                    <div className='tournament-detail__team-inscription__data'>
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            <div className='tournament-detail__team-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.you')}</div>
                                                            <div className='tournament-detail__team-inscription-column__team-info'>
                                                                { props.profile_photo
                                                                    ?
                                                                    <img src={props.profile_photo} alt={`${props.username} img`} />
                                                                    :
                                                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                }
                                                                <div className='tournament-detail__team-inscription-column__team-info__name-username'>
                                                                    <div className='baloa-names'>{props.full_name}</div>
                                                                    <div className='baloa-username'>@{props.username}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/** Formulario */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            {/* props.form_state && */ props.has_player_form &&
                                                                <React.Fragment>
                                                                    <div className='tournament-detail__team-inscription-column__title  baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                    {(props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') &&
                                                                        <TextButtonComponent
                                                                            className={'tournament-detail__team-inscription-column__button baloa-username'}
                                                                            onClick={()=>{
                                                                                //props.handleRealizePlayerFormButton(props.team_players?.filter(team => team.username == props.username)[0],'player');
                                                                                props.handleRealizePlayerFormButton(props.is_tournament_player,'player')
                                                                                
                                                                            }}
                                                                            disabled={!props.tournament_registration_deadline} 
                                                                        >
                                                                            <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                        </TextButtonComponent>
                                                                    }
                                                                    {(props.player_form_state === 'Done' || !props.player_form_state) &&
                                                                        <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {(!props.has_player_form || props.player_form_state === 'No_Needed') &&
                                                                <div className='tournament-detail__team-inscription-column__title baloa-table-column'>&nbsp;</div>
                                                            }
                                                        </div>
                                                        {/** Pago */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            {(props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid") && props.player_payment_state !== 'No_Needed' && //TODO: falta validar si tiene pago por jugador
                                                                <React.Fragment>
                                                                    <div className='tournament-detail__team-inscription-column__title baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div>
                                                                    {(props.player_payment_state === 'Postponed' || props.player_payment_state === 'Pending') &&
                                                                        <TextButtonComponent
                                                                            className={'tournament-detail__team-inscription-column__button baloa-username'}
                                                                            //onClick={()=>{props.handleRealizePlayerPaymentButton(props.team_players?.filter(team => team.username == props.username)[0],'player')}}
                                                                            onClick={()=>{props.handleRealizePlayerPaymentButton(props.is_tournament_player,'player')}}
                                                                            disabled={!props.tournament_registration_deadline} 
                                                                        >
                                                                            <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                        </TextButtonComponent>
                                                                    }
                                                                    {(props.player_payment_state === 'Done' || !props.player_payment_state) &&
                                                                        <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {(props.tournament_registration_type == "Free" || props.tournament_registration_type == "Team_paid" || props.player_payment_state === 'No_Needed') &&
                                                                <div className='tournament-detail__team-inscription-column__title baloa-table-column'>&nbsp;</div>
                                                            }
                                                        </div>
                                                        {/** Estado */}
                                                        <div className='tournament-detail__team-inscription-column'>
                                                            <div className='tournament-detail__team-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.status')}</div>
                                                            <div className={`tournament-detail__team-inscription-column__status baloa-username ${props.player_state === 'Enrolled'? 'enrolled': (props.player_state === 'Register')? 'register' : ''}`} >{props.t(`viewTournament.inscription.inscriptionStates.${props.player_state}`)}</div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                }
                                                {/** Info Staff */}
                                                {(props.request_is_staff) && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.staff_username &&
                                                    <React.Fragment>
                                                        {/** Messages */}
                                                        {/** Staff con formulario pendiente */}
                                                        {props.has_player_form && !props.staff_has_form && 
                                                            <div className='tournament-detail__team-info'>
                                                                <span className='hubicon-info' />
                                                                <div className='baloa-table-column' >{props.t('viewTournament.inscription.staffPendingFormMsg')}</div>
                                                            </div>
                                                        }
                                                        <div className='tournament-detail__team-inscription__data'>
                                                            <div className='tournament-detail__staff-info-inscription-column'>
                                                                <div className='tournament-detail__staff-info-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.you')}</div>
                                                                <div className='tournament-detail__team-inscription-column__team-info'>
                                                                    { props.staff_photo
                                                                        ?
                                                                        <img src={props.staff_photo} alt={`${props.staff_username} img`} />
                                                                        :
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='tournament-detail__team-inscription-column__team-info__name-username'>
                                                                        <div className='baloa-names'>{props.staff_full_name}</div>
                                                                        <div className='baloa-username'>@{props.staff_username}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/** Formulario */}
                                                            <div className='tournament-detail__staff-info-inscription-column'>
                                                                {/* props.form_state && */ props.has_player_form &&
                                                                    <React.Fragment>
                                                                        <div className='tournament-detail__staff-info-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                        {(!props.staff_has_form) &&
                                                                            <TextButtonComponent
                                                                                className={'tournament-detail__team-inscription-column__button baloa-username'}
                                                                                onClick={()=>{
                                                                                    props.handleRealizePlayerFormButton(props.teams_manager_and_staff?.filter(staff => staff.username == props.staff_username)[0],'staff')
                                                                                }}
                                                                                disabled={!props.tournament_registration_deadline} 
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(props.staff_has_form) &&
                                                                            <div className='tournament-detail__staff-info-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(!props.has_player_form) &&
                                                                    <div className='tournament-detail__staff-info-inscription-column__title last-column baloa-table-column'>&nbsp;</div>
                                                                }
                                                            </div>                                                        
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        
                                        {/* Team Players Info */}
                                        {(props.team_state === 'Enrolled' || props.team_state === 'Register') &&                                        
                                        <div className='tournament-detail__team-players' >
                                            <div className='tournament-detail__players-title baloa-hyperlink'>
                                                {props.t('viewTournament.inscription.playersLbl')}
                                                { props.request_is_admin && 
                                                    <div className='tournament-detail__players__bottons'>
                                                        {props.tournament_registration_deadline &&
                                                            <div className='tournament-detail__players__bottons-margin'>
                                                                <SecondaryButtonComponent
                                                                    className={'tournament-detail__team-players__share-button'}
                                                                    onClick={()=>{props.openMultiplePlayerForms()}}
                                                                >
                                                                    <span>{props.t('viewTournament.inscription.teamPlayerWidget.shareForms')}</span>
                                                                </SecondaryButtonComponent>
                                                            </div>
                                                        }
                                                        <SecondaryButtonComponent
                                                            className={'tournament-detail__team-players__share-button'}
                                                            //onClick={()=>{props.handleOnShareInscriptionTournamentLinkClick(props.tournament_team_id)}}
                                                            onClick={()=>{handleBoxOpen(true)}}
                                                            >

                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.shareInscription')}</span>
                                                                <span className='hubicon-person_add'></span>
                                                        </SecondaryButtonComponent>
                                                        <div className={`tournament-detail__players__button-options__box ${ boxPosition ? "active" : ""}`} >
                                                            <div className='tournament-detail__button-options__box__box-option'      
                                                                    onClick={()=> {
                                                                        props.handleOnShareAddMembersWithAccountClick()
                                                                        handleBoxOpen(false);
                                                                    }}>
                                                                    <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelAddMemberWithAccount')}</div>
                                                                    <div className={"baloa-username"} >{props.t('viewTournament.inscription.teamPlayerWidget.subLabelAddMemberWithAccount')}</div>
                                                            </div>
                                                            <div className='tournament-detail__button-options__box__box-option'      
                                                                onClick={()=> {
                                                                    props.handleOnShareAddMembersWithoutAccountClick()
                                                                    handleBoxOpen(false);
                                                                }}>
                                                                <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelAddMemberWithoutAccount')}</div>
                                                                <div className={"baloa-username"} >{props.t('viewTournament.inscription.teamPlayerWidget.subLabelAddMemberWithoutAccount')}</div>
                                                            </div>
                                                            <div className='tournament-detail__button-options__box__box-option' 
                                                                onClick={()=> {
                                                                    props.handleOnShareInvitedPlayerTournamentLinkClick(props.tournament_team_id)
                                                                    handleBoxOpen(false);
                                                                }}>
                                                                <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelInvitedPlayerTournament')}</div>
                                                                <div className={"baloa-username"} >{props.t('viewTournament.inscription.teamPlayerWidget.subLabelInvitedPlayerTournament')}</div>
                                                            </div>
                                                            
                                                            <div className='tournament-detail__button-options__box__box-option' 
                                                                onClick={()=> {
                                                                    props.handleOnShareInscriptionTournamentLinkClick(props.team_page)
                                                                    handleBoxOpen(false);
                                                                }}>
                                                                <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelInvitedTeamTournament')}</div>
                                                                <div className={"baloa-username"}>{props.t('viewTournament.inscription.teamPlayerWidget.subLabelInvitedTeamTournament')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }                  
                                                
                                            </div>
                                            <div className='enrolled-players baloa-username'>{props.t('viewTournament.inscription.enrolledPlayers', {_enrolledPlayers: props.enrolled_players, _maxParticipantPlayers: props.max_participant_players})}</div>
                                            {/* <div class="tournament-detail_search_player-form">
                                                <span className="icon-search" />
                                                <input class="tournament-detail_search_input baloa-names"
                                                    type="text" placeholder={props.t('viewTournament.inscription.teamPlayerWidget.searchPlayerLbl')}
                                                    onChange={(event) => { handleSearchDetail(event.target.value)}}>
                                                </input>
                                            </div> */}
                                            <InputSearchWithButtonComponent
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ handleSearchDetail(search) } }
                                            />
                                            <div className='tournament-detail__teams-filter'>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'all-states' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('all-states')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.allFilter')}
                                                </div>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'Enrolled' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('Enrolled')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.enrolledFilter')}
                                                </div>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'Pending' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('Pending')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.pendingFilter')}
                                                </div>
                                            </div>
                                            <div className='tournament-detail__player-inscription'>
                                                <div className='tournament-detail__player-inscription-column__title baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.player')}</div>
                                                <div className='tournament-detail__player-inscription-column__title baloa-table-column'>{((!props.has_player_form || props.form_state === 'No_Needed') || !props.request_is_admin)? '' : props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                {/* <div className='tournament-detail__player-inscription-column__title baloa-table-column'>{(props.tournament_registration_type == "Free" || props.payment_state === 'No_Needed' || props.tournament_registration_type === 'Team_paid')? '' : props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div> */}
                                                {((props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid') && props.request_is_admin)
                                                    ? <div className='tournament-detail__player-inscription-column__title baloa-table-column'>
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}
                                                        </div> 
                                                    : <div className='tournament-detail__player-inscription-column__title baloa-table-column'></div> 
                                                }
                                                <div className='tournament-detail__player-inscription-column__title baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.status')}</div>
                                            </div>
                                            {listTeamsPlayer.length === 0 && props.loading_team_players?
                                                <React.Fragment>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className='tournament-detail__team-players__players scroll'>
                                                    { listTeamsPlayer.map( (player, index) => (
                                                        <div key={index} className='player-container'>
                                                            <div className='tournament-detail__player-inscription-column'>
                                                                { player.photo
                                                                    ?
                                                                    <img src={player.photo} alt={`${player.full_name} img`} />
                                                                    :
                                                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                }
                                                                <div className='name-username'>
                                                                    <div className='baloa-names'>{player.full_name}</div>
                                                                    <div className='baloa-username'>@{player.username}</div>
                                                                </div>
                                                            </div>
                                                            {/** Player form */}
                                                            <div className='tournament-detail__player-inscription-column'>
                                                                {/* props.form_state && */( props.has_player_form && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.request_is_admin) &&
                                                                    <React.Fragment>
                                                                        {(player.form_state === 'Postponed' || player.form_state === 'Pending') &&
                                                                            <TextButtonComponent
                                                                                className={`tournament-detail__team-inscription-column__button-player baloa-username ${(!props.tournament_registration_deadline && player.state !== 'Enrolled') ? 'disabled' : ''}`} 
                                                                                onClick={()=>{props.handleRealizePlayerFormButton(player, 'adminteams')}}
                                                                                disabled={ (!props.tournament_registration_deadline && player.state !== 'Enrolled') }  
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(player.form_state === 'Done' || !player.form_state) &&
                                                                            <div className='baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(!props.has_player_form || player.form_state === 'No_Needed') &&
                                                                    <div className='baloa-username'><span className='green-bullet color'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.pendinigForm')}</div> 
                                                                }
                                                            </div>
                                                            {/** Player payment */}
                                                            <div className='tournament-detail__player-inscription-column'>
                                                                {((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && player.payment_state !== 'No_Needed' && props.request_is_admin) &&
                                                                    <React.Fragment>
                                                                        {(player.payment_state === 'Postponed' || player.payment_state === 'Pending') && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.request_is_admin &&
                                                                            <TextButtonComponent
                                                                                className={`tournament-detail__team-inscription-column__button-player baloa-username ${ (!props.tournament_registration_deadline && player.state !== 'Enrolled') ? 'disabled' : ''}`} 
                                                                                onClick={()=>{props.handleRealizePlayerPaymentButton(player, 'player')}}
                                                                                disabled={(!props.tournament_registration_deadline && player.state !== 'Enrolled')}  
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(player.payment_state === 'Done' || !player.payment_state) &&
                                                                            <div className='baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(props.tournament_registration_type == "Free" || player.payment_state === 'No_Needed') &&
                                                                    <div className='tournament-detail__player-inscription-column baloa-table-column'>&nbsp;</div>
                                                                }
                                                            </div>

                                                            <div className='tournament-detail__player-inscription-column'>
                                                                <div className={`tournament-detail__team-inscription-column__status baloa-username ${(player.state === 'Pending' || player.state === 'Late_Register')? 'pending': player.state === 'Enrolled'?  'enrolled': player.state === 'Rejected'? 'rejected': (props.team_state === 'Register')? 'register' : '' }`} >
                                                                    {props.t(`viewTournament.inscription.inscriptionStates.${player.state}`)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))
                                                    }
                                                    {props.next_team_players && !props.loading_team_players &&
                                                        <div className='tournament-detail__team-players__players__view-more-link baloa-names' onClick={()=>{props.handleOnNextTeamPlayers()}}>{props.t('viewTournament.inscription.teamPlayerWidget.viewMore')}</div>
                                                    }
                                                    {listTeamsPlayer.length === 0 &&
                                                        <div className='tournament-detail__team-players__players__empty-msg baloa-names '>{props.t('viewTournament.inscription.teamPlayerWidget.emptyPlayersMsg')}</div>
                                                    }
                                                    {listTeamsPlayer.length > 0 && props.loading_team_players &&
                                                        <React.Fragment>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        } 
                                        {/* coaches and staff info */}
                                        {(props.team_state === 'Enrolled' || props.team_state === 'Register') && 
                                        <div className='tournament-detail__staff'>
                                           <div className='tournament-detail__staff-title baloa-hyperlink'>
                                                {props.t('viewTournament.inscription.teamPlayerWidget.coachesAndStaff')}
                                           </div>
                                           <div className='tournament-detail__staff-inscription'>
                                                <div className='tournament-detail__staff-inscription-column__title baloa-table-column'>{"Nombre"}</div>
                                                <div className='tournament-detail__staff-inscription-column__title baloa-table-column tournament-detail__staff-inscription-column__title__width'></div>
                                                <div className='tournament-detail__staff-inscription-column__title baloa-table-column '>{((!props.has_player_form || props.form_state === 'No_Needed') || !props.request_is_admin)? '' : props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                <div className='tournament-detail__staff-inscription-column__title baloa-table-column tournament-detail__staff-inscription-column__title__width'></div> 
                                                <div className='tournament-detail__staff-inscription-column__title baloa-table-column'>{"Rol"}</div>
                                            </div>
                                            {listTeamsStaff.length === 0 && props.loading_team_staff?
                                                <React.Fragment>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className='tournament-detail__team-players__players scroll'>
                                                    { listTeamsStaff.map( (staff, index) => (
                                                            <div key={index} className='player-container'>
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    { staff.photo
                                                                        ?
                                                                        <img src={staff.photo} alt={`${staff.full_name} img`} />
                                                                        :
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='name-username'>
                                                                        <div className='baloa-names'>{staff.first_name} {staff.last_name}</div>
                                                                        <div className='baloa-username'>@{staff.username}</div>
                                                                    </div>
                                                                </div>
                                                                {/** staff form */}
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    { props.request_is_admin && staff.has_form && 
                                                                        <React.Fragment>
                                                                            {staff.form_id === null &&
                                                                                <TextButtonComponent
                                                                                    className={`tournament-detail__team-inscription-column__button-player baloa-username ${staff.state === 'Late_Register' ? 'disabled' : ''}`} 
                                                                                    onClick={()=>{props.handleRealizePlayerFormButton(staff, 'staff')}}
                                                                                    disabled={ staff.state === 'Late_Register' }  
                                                                                >
                                                                                    <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                                </TextButtonComponent>
                                                                            }
                                                                            {staff.form_id !== null &&
                                                                                <div className='margin_form baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    {/* {(staff.form_id != null && !props.request_is_admin) &&
                                                                        <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                    }
                                                                    {(!staff.has_form) &&
                                                                        <div className='baloa-username'><span className='green-bullet color'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.pendinigForm')}</div> 
                                                                    } */}
                                                                </div>
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    <div className='tournament-detail__team-inscription-column__status'>
                                                                        {props.t(`viewTournament.inscription.rolStaff.${staff.role.team_role}`)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {props.next_team_staff && !props.loading_team_staff &&
                                                        <div className='tournament-detail__team-players__players__view-more-link baloa-names' onClick={()=>{props.handleOnNextTeamStaff()}}>{props.t('viewTournament.inscription.teamPlayerWidget.viewMore')}</div>
                                                    }
                                                    {listTeamsStaff.length === 0 &&
                                                        <div className='tournament-detail__team-players__players__empty-msg baloa-names '>{props.t('viewTournament.inscription.teamPlayerWidget.emptyStaffMsg')}</div>
                                                    }
                                                    {listTeamsStaff.length > 0 && props.loading_team_staff &&
                                                        <React.Fragment>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }
                                        </div> }                                      
                                    </React.Fragment>
                                }
                                {/** Cupon code Widget */}
                                {props.request_is_admin && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" || props.tournament_registration_type == "Player_paid") && props.player_code &&
                                    <div className='tournament-detail__player-code'>
                                        <div className='baloa-normal-medium'>
                                            {props.t('viewTournament.details.playerCodeLbl')}
                                            <Tooltip
                                                title={props.t('viewTournament.details.playerCodeToolTip')}
                                                arrow
                                                placement="right-end"
                                                classes={{ tooltip: classes.customMessage }}>
                                                <img src={help_icon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div className='tournament-detail__player-code__code' onClick={()=>{handleOnCopyPlayerCode(props.player_code)}}><span className='icon-copy-86'></span>{props.player_code}</div>
                                        <div className='tournament-detail__player-code__quantity baloa-username'><span className='tournament-detail__player-code__quantity-text'>{props.t('viewTournament.details.availablePlayerCodeLbl')}</span>{props.availableCodes}</div>
                                    </div>
                                }
                                {/** Team Widget */}
                                {(props.request_is_member || props.is_transfer_player || props.request_is_staff) && !props.request_is_admin && 
                                    <div className={`tournament-detail__player-code ${(props.player_state === 'Enrolled' && !props.unlocked_stats)? 'move-top' : ''}`}>
                                        <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.teamPlayerWidget.team')}</div>
                                        <FormComponent
                                            formInitialValues={{teamname_player: props.tournament_team_id}}
                                            functionDisabled={() => { }}
                                            onSubmit={() => {}}
                                            enableReinitialize={true}
                                            //innerRef={formRef}
                                        >
                                            <SelectTeamFieldComponent
                                                field_name="teamname_player"
                                                className="tournament-detail__team-inscription-column__team-info"
                                                validationFunction={()=>{}}
                                                disabled={false}
                                                resetError={()=>{}}
                                                error={{teamname_player: ''}}
                                                message=""
                                                placeholder=""
                                                options={props.my_teams}
                                                onChange={(value) => {
                                                    if(value){
                                                        setTeamData(value);
                                                    }
                                                    
                                                }}
                                                defaultValue={props.tournament_team_id}
                                            />
                                        </FormComponent>
                                        <div className={`tournament-detail__team-inscription-column__status team-widget baloa-username ${props.team_state === 'Enrolled'? 'enrolled': (props.team_state === 'Register')? 'register' : ''}`} >{props.t(`viewTournament.inscription.inscriptionStates.${props.team_state}`)}</div>
                                    </div>
                                }
                            </React.Fragment>
                            : <React.Fragment>
                                {/* Vista Mobile*/}
                                {(props.team_state === 'Register' || props.team_state === 'Enrolled' || props.team_state === 'Rejected') &&
                                    <React.Fragment>
                                        {/* Team Inscription Info */}
                                        <div className='tournament-detail__team-inscription_mobile'>                                            
                                            <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.teamPlayerWidget.widgetTitle')}</div>
                                            {(props.request_is_admin) &&
                                            <React.Fragment>
                                            {/** Messages */}
                                            { props.team_state === 'Register' &&
                                                <div className='tournament-detail__team-info'>
                                                    <span className='hubicon-info' />
                                                    <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingMsg')}</div>
                                                </div>
                                            }
                                            { props.team_state === 'Rejected' &&
                                                <div className='tournament-detail__team-rejected-msg' >
                                                    <span className='icon-x' />
                                                    <div className='baloa-table-column'>{props.t('viewTournament.inscription.teamRejectedMsg')} {`${(props.tournament_registration_type === 'Paid' || props.tournament_registration_type === 'Team_paid')? props.t('viewTournament.inscription.refundMsg'): ''}`}</div>
                                                </div>
                                            }
                                            {/** Team Inscrito con formulario y pago pendientes */}
                                            {props.team_state === 'Enrolled' && props.has_team_form && (props.tournament_registration_type == "Paid" || props.tournament_registration_type === 'Team_paid' ) && (props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') && (props.team_payment_state === 'Postponed' || props.team_payment_state === 'Pending') && !props.team_has_paid &&
                                                <div className='tournament-detail__team-info'>
                                                    <span className='hubicon-info' />
                                                    <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingFormPaymentMsg')}</div>
                                                </div>
                                            }
                                            {/** Team Inscrito con formulario pendiente */}
                                            {props.team_state === 'Enrolled' && props.has_team_form  && (props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') && (((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && props.team_has_paid)  || props.tournament_registration_type == "Free" )&&
                                                <div className='tournament-detail__team-info'>
                                                    <span className='hubicon-info' />
                                                    <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingFormMsg')}</div>
                                                </div>
                                            }
                                            {/** Team Inscrito con pago pendiente */}
                                            {props.team_state === 'Enrolled' && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && !props.team_has_paid && (props.team_form_state === 'Done' || props.team_form_state === 'No_Needed') &&
                                                <div className='tournament-detail__team-info'>
                                                    <span className='hubicon-info' />
                                                    <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingPaymentMsg')}</div>
                                                </div>
                                            }
                                            {/** Info Equipo */}
                                            <div className='tournament-detail__team-inscription__data'>
                                                <div className='tournament-detail__team-inscription-team_inf'>
                                                    <div className='tournament-detail__team-inscription-column-mobile'>
                                                        <div className='tournament-detail__team-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.team')}</div>                                                        
                                                        <FormComponent
                                                            formInitialValues={{teamname: props.tournament_team_id}}
                                                            functionDisabled={() => { }}
                                                            onSubmit={() => {}}
                                                            enableReinitialize={true}
                                                            //innerRef={formRef}
                                                        >
                                                            <SelectTeamFieldComponent
                                                                field_name="teamname"
                                                                className="tournament-detail__team-inscription-column__team-info"
                                                                validationFunction={()=>{}}
                                                                disabled={false}
                                                                resetError={()=>{}}
                                                                error={{teamname: ''}}
                                                                message=""
                                                                placeholder=""
                                                                options={props.my_teams}
                                                                onChange={(value) => {
                                                                    if(value){
                                                                        setTeamData(value);
                                                                    }
                                                                    
                                                                }}
                                                                defaultValue={props.tournament_team_id}
                                                            />
                                                        </FormComponent>
                                                    </div>
                                                    {/** Estado */}
                                                    <div className='tournament-detail__team-inscription-column-mobile'>
                                                        <div className='tournament-detail__team-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.status')}</div>
                                                        <div className={`tournament-detail__team-inscription-column__status baloa-username ${props.team_state === 'Enrolled'? 'enrolled': (props.team_state === 'Register')? 'register' : ''}`} >
                                                            {props.t(`viewTournament.inscription.inscriptionStates.${props.team_state}`)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='tournament-detail__team-inscription_buttons_form'>
                                                    {/** Formulario */}
                                                    <div className='tournament-detail__team-inscription-column-mobile'>
                                                        {/* props.form_state && */ props.has_team_form &&
                                                            <React.Fragment>
                                                                <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                {(props.team_form_state === 'Postponed' || props.team_form_state === 'Pending') &&
                                                                    <TextButtonComponent
                                                                        className={'tournament-detail__team-inscription-column__button-mobile baloa-username'}
                                                                        onClick={()=>{props.handleRealizeTeamFormButton('teams')}}
                                                                    >
                                                                        <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                    </TextButtonComponent>
                                                                }
                                                                {(props.team_form_state === 'Done' || !props.team_form_state) &&
                                                                    <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        {(!props.has_team_form || props.team_form_state === 'No_Needed') &&
                                                            <div className='baloa-names'>&nbsp;</div>
                                                        }
                                                    </div>
                                                    {/** Pago */}
                                                    <div className='tournament-detail__team-inscription-column-mobile'>
                                                        {(props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team_paid" ) && props.team_payment_state !== 'No_Needed' &&
                                                            <React.Fragment>
                                                                <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div>
                                                                {(props.team_payment_state === 'Postponed' || props.team_payment_state === 'Pending') &&
                                                                    <TextButtonComponent
                                                                        className={'tournament-detail__team-inscription-column__button-mobile baloa-username'}
                                                                        onClick={()=>{props.handleRealizeTeamPaymentButton('teams')}}
                                                                    >
                                                                        <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                    </TextButtonComponent>
                                                                }
                                                                {(props.team_payment_state === 'Done' || !props.team_payment_state) &&
                                                                    <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        {(props.tournament_registration_type == "Free" || props.team_payment_state === 'No_Needed') &&
                                                            <div className='baloa-names'>&nbsp;</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>                                           
                                            </React.Fragment>
                                            }
                                            {/** Info Player */}
                                            {(props.request_is_member || props.request_is_staff) && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.username &&
                                                <React.Fragment>
                                                    {/** Messages */}
                                                    { props.player_state === 'Register' && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.teamPendingMsg')}</div>
                                                        </div>
                                                    }
                                                    { props.player_state === 'Rejected' &&
                                                        <div className='tournament-detail__team-rejected-msg' >
                                                            <span className='icon-x' />
                                                            <div className='baloa-table-column'>{props.t('viewTournament.inscription.teamRejectedMsg')} {`${(props.tournament_registration_type === 'Paid' || props.tournament_registration_type === 'Player_paid')? props.t('viewTournament.inscription.refundMsg'): ''}`}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con formulario y pago pendientes */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && props.has_player_form && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && (props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') && !props.player_has_paid && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingFormPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con formulario pendiente */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && props.has_player_form && (props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') && (((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && props.player_has_paid) || props.tournament_registration_type == "Free") && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingFormMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con pago pendiente */}
                                                    {(props.player_state === 'Register' || props.player_state === 'Pending' || props.player_state === 'Enrolled' ) && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && !props.player_has_paid && (props.player_form_state === 'Done' || props.player_form_state === 'No_Needed') && props.tournament_registration_deadline && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerPendingPaymentMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Player Inscrito con fecha inscripcion vencida */}
                                                    { !props.tournament_registration_deadline && (props.player_state === 'Register' || props.player_state === 'Pending' ) &&
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.playerInvalidDateMsg')}</div>
                                                        </div>
                                                    }
                                                    {/** Info Player */}
                                                    <div className='tournament-detail__team-inscription__data'>
                                                        <div className='tournament-detail__team-inscription-team_inf'>
                                                            <div className='tournament-detail__team-inscription-column-mobile'> 
                                                                <div className='tournament-detail__team-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.you')}</div>
                                                                <div className='tournament-detail__team-inscription-column__team-info'>
                                                                    { props.profile_photo
                                                                        ?
                                                                        <img src={props.profile_photo} alt={`${props.username} img`} />
                                                                        :
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='tournament-detail__team-inscription-column__team-info__name-username'>
                                                                        <div className='baloa-names'>{props.full_name}</div>
                                                                        <div className='baloa-username'>@{props.username}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/** Estado */}
                                                            <div className='tournament-detail__team-inscription-column-mobile'>
                                                                <div className='tournament-detail__team-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.status')}</div>                                                            
                                                                <div className={`tournament-detail__team-inscription-column__status baloa-username ${props.player_state === 'Enrolled'? 'enrolled': (props.player_state === 'Register')? 'register' : ''}`} >{props.t(`viewTournament.inscription.inscriptionStates.${props.player_state}`)}</div>
                                                            </div>
                                                        </div>
                                                        <div className='tournament-detail__team-inscription_buttons_form'>
                                                            {/** Formulario */}
                                                            <div className='tournament-detail__team-inscription-column-mobile'>
                                                                {/* props.form_state && */ props.has_player_form && 
                                                                    <React.Fragment>
                                                                        <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                        {(props.player_form_state === 'Postponed' || props.player_form_state === 'Pending') &&
                                                                            <TextButtonComponent
                                                                                className={'tournament-detail__team-inscription-column__button-mobile baloa-username'}
                                                                                onClick={()=>{
                                                                                    //props.handleRealizePlayerFormButton(props.team_players?.filter(team => team.username == props.username)[0],'player');
                                                                                    props.handleRealizePlayerFormButton(props.is_tournament_player,'player');
                                                                                }}
                                                                                disabled={!props.tournament_registration_deadline} 
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(props.player_form_state === 'Done' || !props.player_form_state) &&
                                                                            <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(!props.has_player_form || props.player_form_state === 'No_Needed') &&
                                                                    <div className='baloa-names'>&nbsp;</div>
                                                                }
                                                            </div>
                                                            {/** Pago */}
                                                            <div className='tournament-detail__team-inscription-column-mobile'>
                                                                {(props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid") && props.player_payment_state !== 'No_Needed' && //TODO: falta validar si tiene pago por jugador
                                                                    <React.Fragment>
                                                                        <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div>
                                                                        {(props.player_payment_state === 'Postponed' || props.player_payment_state === 'Pending') &&
                                                                            <TextButtonComponent
                                                                                className={'tournament-detail__team-inscription-column__button-mobile baloa-username'}
                                                                                //onClick={()=>{props.handleRealizePlayerPaymentButton(props.team_players?.filter(team => team.username == props.username)[0],'player')}}
                                                                                onClick={()=>{props.handleRealizePlayerPaymentButton(props.is_tournament_player,'player')}}
                                                                                disabled={!props.tournament_registration_deadline}  
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(props.player_payment_state === 'Done' || !props.player_payment_state) &&
                                                                            <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(props.tournament_registration_type == "Free" || props.player_payment_state === 'No_Needed') &&
                                                                    <div className='baloa-names'>&nbsp;</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {/** Info Staff */}
                                            {(props.request_is_staff) && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.staff_username &&
                                                <React.Fragment>
                                                    {/** Messages */}
                                                    {/** Staff con formulario pendiente */}
                                                    {props.has_player_form && !props.staff_has_form && 
                                                        <div className='tournament-detail__team-info'>
                                                            <span className='hubicon-info' />
                                                            <div className='baloa-table-column' >{props.t('viewTournament.inscription.staffPendingFormMsg')}</div>
                                                        </div>
                                                    }
                                                    <div className='tournament-detail__team-inscription__data'>
                                                        <div className='tournament-detail__team-inscription-team_inf'>
                                                            <div className='tournament-detail__team-inscription-column-mobile'> 
                                                                <div className='tournament-detail__team-inscription-column__title first-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.you')}</div>
                                                                <div className='tournament-detail__team-inscription-column__team-info'>
                                                                    { props.staff_photo
                                                                        ?
                                                                        <img src={props.staff_photo} alt={`${props.staff_username} img`} />
                                                                        :
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='tournament-detail__team-inscription-column__team-info__name-username'>
                                                                        <div className='baloa-names'>{props.staff_full_name}</div>
                                                                        <div className='baloa-username'>@{props.staff_username}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/** Formulario */}
                                                            <div className='tournament-detail__team-inscription-column-mobile'>
                                                                {/* props.form_state && */ props.has_player_form && 
                                                                    <React.Fragment>
                                                                        <div className='tournament-detail__team-inscription-column__title last-column baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                        {(!props.staff_has_form) &&
                                                                            <TextButtonComponent
                                                                                className={'tournament-detail__team-inscription-column__button-mobile baloa-username'}
                                                                                onClick={()=>{
                                                                                    props.handleRealizePlayerFormButton(props.teams_manager_and_staff?.filter(staff => staff.username == props.staff_username)[0],'staff')
                                                                                }}
                                                                                disabled={!props.tournament_registration_deadline} 
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(props.staff_has_form) &&
                                                                            <div className='tournament-detail__team-inscription-column__state baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.realizedStatus')}</div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(!props.has_player_form) &&
                                                                    <div className='baloa-names'>&nbsp;</div>
                                                                }
                                                            </div>
                                                        </div>                                           
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        {/* Widget Player Code */}
                                        {props.request_is_admin && (props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Team-paid" ) && props.player_code &&
                                            <div className='tournament-detail__player-code_mobile'>
                                                <div className='baloa-normal-medium'>
                                                    {props.t('viewTournament.details.playerCodeLbl')}
                                                    <Tooltip
                                                        title={props.t('viewTournament.details.playerCodeToolTip')}
                                                        arrow
                                                        placement="right-end"
                                                        classes={{ tooltip: classes.customMessage }}>
                                                        <img src={help_icon} alt="" />
                                                    </Tooltip>
                                                </div>
                                                <div className='tournament-detail__player-code__code' onClick={()=>{handleOnCopyPlayerCode(props.player_code)}}>
                                                    <span className='icon-copy-86'/>{props.player_code}
                                                </div>
                                                <div className='tournament-detail__player-code__quantity baloa-username'>
                                                    <span className='tournament-detail__player-code__quantity-text'>
                                                        {props.t('viewTournament.details.availablePlayerCodeLbl')}
                                                    </span>{props.availableCodes}
                                                </div>
                                            </div>
                                        }
                                        {/* Widget Team Data */}
                                        {(props.request_is_member || props.request_is_staff) && !props.request_is_admin &&
                                        <div className='tournament-detail__player-code_mobile'>
                                            <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.teamPlayerWidget.team')}</div>
                                            <div className='tournament-detail__team-inscription-column__team-info'>                                        
                                                <FormComponent
                                                    formInitialValues={{teamname_player: props.tournament_team_id}}
                                                    functionDisabled={() => { }}
                                                    onSubmit={() => {}}
                                                    enableReinitialize={true}
                                                    //innerRef={formRef}
                                                >
                                                    <SelectTeamFieldComponent
                                                        field_name="teamname_player"
                                                        className="tournament-detail__team-inscription-column__team-info"
                                                        validationFunction={()=>{}}
                                                        disabled={false}
                                                        resetError={()=>{}}
                                                        error={{teamname_player: ''}}
                                                        message=""
                                                        placeholder=""
                                                        options={props.my_teams}
                                                        onChange={(value) => {
                                                            if(value){
                                                                setTeamData(value);
                                                            }
                                                            
                                                        }}
                                                        defaultValue={props.tournament_team_id}
                                                    />
                                                </FormComponent>
                                                <div className={`tournament-detail__team-inscription-column__status team-widget baloa-username ${props.team_state === 'Enrolled'? 'enrolled': (props.team_state === 'Register')? 'register' : ''}`} >
                                                    {props.t(`viewTournament.inscription.inscriptionStates.${props.team_state}`)}
                                                </div>
                                            </div>
                                        </div>
                                        } 
                                        {/* Team Players Info */}
                                        {(props.team_state === 'Enrolled' || props.team_state === 'Register') &&
                                        <div className='tournament-detail__team-players' >
                                            {!isMobile
                                                ? <div className='players-title baloa-table-column'>{props.t('viewTournament.inscription.playersLbl')}</div>
                                                : <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.playersLbl')}</div>
                                            }
                                            { props.request_is_admin && 
                                                <div className='players-title baloa-table-column'>
                                                    <PrimaryButtonComponent
                                                        className={'tournament-detail__team-players__share-button'}
                                                        //onClick={()=>{props.handleOnShareInscriptionTournamentLinkClick(props.tournament_team_id)}}
                                                        onClick={()=>{
                                                            handleBoxOpen(true)
                                                            // props.handleOnShareInscriptionTournamentLinkClick(props.team_page)
                                                        }
                                                        }
                                                    >
                                                        <span>{props.t('viewTournament.inscription.teamPlayerWidget.shareInscription')}</span>
                                                        <span className='hubicon-share_v2 icon'></span>
                                                    </PrimaryButtonComponent>
                                                    <div className={`tournament-detail__players__button-options__box ${ boxPosition ? "active" : ""}`} >
                                                        <div className='tournament-detail__button-options__box__box-option' 
                                                            onClick={()=> {
                                                                props.handleOnShareAddMembersWithoutAccountClick()
                                                                handleBoxOpen(false);
                                                            }}>
                                                            <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelInvitedPlayerTournament')}</div>
                                                            <div className={"baloa-username"} >{props.t('viewTournament.inscription.teamPlayerWidget.subLabelInvitedPlayerTournament')}</div>
                                                        </div>
                                                        
                                                        <div className='tournament-detail__button-options__box__box-option' 
                                                            onClick={()=> {
                                                                props.handleOnShareInscriptionTournamentLinkClick(props.team_page)
                                                                handleBoxOpen(false);
                                                            }}>
                                                            <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.labelInvitedTeamTournament')}</div>
                                                            <div className={"baloa-username"}>{props.t('viewTournament.inscription.teamPlayerWidget.subLabelInvitedTeamTournament')}</div>
                                                        </div>
                                                    </div>                                     
                                                </div>
                                            }
                                            <div className='enrolled-players baloa-username'>{props.t('viewTournament.inscription.enrolledPlayers', {_enrolledPlayers: props.enrolled_players, _maxParticipantPlayers: props.max_participant_players})}</div>
                                            {/* <div class="tournament-detail_search_player-form">
                                                <span className="icon-search" />
                                                <input class="tournament-detail_search_input baloa-names"
                                                    type="text" placeholder={props.t('viewTournament.inscription.teamPlayerWidget.searchPlayerLbl')}
                                                    onChange={(event) => { handleSearchDetail(event.target.value)}}>
                                                </input>
                                            </div> */}
                                            <InputSearchWithButtonComponent
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ handleSearchDetail(search) } }
                                            />
                                            <div className='tournament-detail__teams-filter'>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'all-states' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('all-states')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.allFilter')}
                                                </div>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'Enrolled' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('Enrolled')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.enrolledFilter')}
                                                </div>
                                                <div className={`filter-label baloa-table-column ${selectedFilter === 'Pending' ? 'selected-state': '' }`}
                                                    onClick={()=>{handleFilterState('Pending')}} >
                                                        {props.t('viewTournament.inscription.teamPlayerWidget.pendingFilter')}
                                                </div>
                                            </div>
                                            <div className='enrolled-players baloa-username'>{props.t('viewTournament.inscription.teamPlayerWidget.players') + " (" + (props.max_participant_players)+ ")"} </div>
                                            {listTeamsPlayer.length === 0 && props.loading_team_players?
                                                <React.Fragment>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className='tournament-detail__team-players__players scroll'>
                                                    { listTeamsPlayer.map( (player, index) => (
                                                        <div key={index} className='player-container'>
                                                            <div className='tournament-detail__player-inscription-row_mobile'>
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    { player.photo
                                                                        ? <img src={player.photo} alt={`${player.full_name} img`} />
                                                                        : <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='name-username'>
                                                                        <div className='baloa-names'>{player.full_name}</div>
                                                                        <div className='baloa-username'>@{player.username}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    <div className={`tournament-detail__team-inscription-column__status baloa-username ${(player.state === 'Pending' || player.state === 'Late_Register')? 'pending': player.state === 'Enrolled'?  'enrolled': player.state === 'Rejected'? 'rejected': (props.team_state === 'Register')? 'register' : '' }`} >
                                                                        {props.t(`viewTournament.inscription.inscriptionStates.${player.state}`)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tournament-detail__player-inscription-buttons_mobile">
                                                            {/** Player form */}
                                                            <div className='tournament-detail__player-inscription-buttons_text_mobile'>

                                                                {/* props.form_state && */ props.has_player_form &&
                                                                    <React.Fragment>
                                                                        <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                        {(player.form_state === 'Postponed' || player.form_state === 'Pending') && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.request_is_admin &&
                                                                            <TextButtonComponent
                                                                                className={`tournament-detail__team-inscription-column__button-player baloa-username ${ (!props.tournament_registration_deadline && player.state !== 'Enrolled') ? 'disabled' : ''}`}  
                                                                                onClick={()=>{props.handleRealizePlayerFormButton(player, 'player')}}
                                                                                disabled={ (!props.tournament_registration_deadline && player.state !== 'Enrolled') }  
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(player.form_state === 'Done' || !player.form_state) &&
                                                                            <div className='baloa-username'>
                                                                                <span className='green-bullet'>&bull;</span>
                                                                                {props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}
                                                                            </div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {(!props.has_player_form || player.form_state === 'No_Needed') &&
                                                                    <div className='tournament-detail__player-inscription-column baloa-table-column'>&nbsp;</div>
                                                                }
                                                            </div>
                                                            {/** Player payment */}
                                                                <div className='tournament-detail__player-inscription-buttons_text_mobile'>
                                                                    {((props.tournament_registration_type == "Paid" || props.tournament_registration_type == "Player_paid" ) && player.payment_state !== 'No_Needed') &&
                                                                        <React.Fragment>
                                                                            <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.inscriptionPayment')}</div>
                                                                            {(player.payment_state === 'Postponed' || player.payment_state === 'Pending') && (props.team_state === 'Enrolled' || props.team_state === 'Register') && props.request_is_admin &&
                                                                                <TextButtonComponent
                                                                                    className={`tournament-detail__team-inscription-column__button-player baloa-username ${ (!props.tournament_registration_deadline && player.state !== 'Enrolled')? 'disabled' : ''}`}  
                                                                                    onClick={()=>{
                                                                                        props.handleRealizePlayerPaymentButton(player, 'player');
                                                                                    }}
                                                                                    disabled={ (!props.tournament_registration_deadline && player.state !== 'Enrolled') }  
                                                                                >
                                                                                    <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblPayment')}</span>
                                                                                </TextButtonComponent>
                                                                            }
                                                                            {(player.payment_state === 'Done' || !player.payment_state) &&
                                                                                <div className='baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    {(props.tournament_registration_type == "Free" || player.payment_state === 'No_Needed') &&
                                                                            <div className='tournament-detail__player-inscription-column baloa-table-column'>&nbsp;</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))
                                                    }
                                                    {props.next_team_players && !props.loading_team_players &&
                                                        <div className='tournament-detail__team-players__players__view-more-link baloa-names' onClick={()=>{props.handleOnNextTeamPlayers()}}>{props.t('viewTournament.inscription.teamPlayerWidget.viewMore')}</div>
                                                    }
                                                    {listTeamsPlayer.length === 0 &&
                                                        <div className='tournament-detail__team-players__players__empty-msg baloa-names '>{props.t('viewTournament.inscription.teamPlayerWidget.emptyPlayersMsg')}</div>
                                                    }
                                                    {listTeamsPlayer.length > 0 && props.loading_team_players &&
                                                        <React.Fragment>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }                
                                        </div>
                                        }
                                        {/* coaches and staff info */}
                                        {(props.team_state === 'Enrolled' || props.team_state === 'Register') &&
                                        <div className='tournament-detail__staff' >
                                            {!isMobile
                                                ? <div className='players-title baloa-table-column'>{props.t('viewTournament.inscription.teamPlayerWidget.coachesAndStaff')}</div>
                                                : <div className='tournament-detail__team-inscription__title baloa-hyperlink'>{props.t('viewTournament.inscription.teamPlayerWidget.coachesAndStaff')}</div>
                                            }
                                            {listTeamsStaff.length === 0 && props.loading_team_staff?
                                                <React.Fragment>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                    <div className='tournament-detail__team-players__players-skeleton'>
                                                        <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                        <div className='tournament-detail__team-players__players-skeleton__text'>
                                                            <Skeleton  animation="wave"  width={135}/>
                                                            <Skeleton  animation="wave" width={80}/>
                                                        </div>
                                                        <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className='tournament-detail__team-players__players scroll'>
                                                    { listTeamsStaff.map( (staff, index) => (
                                                        <div key={index} className='player-container'>
                                                            <div className='tournament-detail__player-inscription-row_mobile'>
                                                                <div className='tournament-detail__player-inscription-column'>
                                                                    { staff.photo
                                                                        ? <img src={staff.photo} alt={`${staff.full_name} img`} />
                                                                        : <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                                    }
                                                                    <div className='name-username'>
                                                                        <div className='baloa-names'>{staff.first_name} {staff.last_name}</div>
                                                                        <div className='baloa-username'>@{staff.username}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='tournament-detail__player-inscription-column staff__roll'>                                     
                                                                    {props.t(`viewTournament.inscription.rolStaff.${staff.role.team_role}`)}  
                                                                </div>
                                                            </div>
                                                            <div className="tournament-detail__player-inscription-buttons_mobile">
                                                            {/** Player form */}
                                                            <div className='tournament-detail__player-inscription-buttons_text_mobile'>

                                                                { props.request_is_admin && staff.has_form && 
                                                                    <React.Fragment>
                                                                        <div className='baloa-names'>{props.t('viewTournament.inscription.teamPlayerWidget.form')}</div>
                                                                        { staff.form_id === null &&
                                                                            <TextButtonComponent
                                                                                className={`tournament-detail__team-inscription-column__button-player baloa-username ${staff.state === 'Late_Register' ? 'disabled' : ''}`}  
                                                                                onClick={()=>{props.handleRealizePlayerFormButton(staff, 'staff')}}
                                                                                disabled={ staff.state === 'Late_Register' }  
                                                                            >
                                                                                <span>{props.t('viewTournament.inscription.teamPlayerWidget.realizeButtonLblForm')}</span>
                                                                            </TextButtonComponent>
                                                                        }
                                                                        {(staff.form_id != null && !props.request_is_admin) &&
                                                                                <div className='baloa-username'><span className='green-bullet'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.fullStatus')}</div>
                                                                            }
                                                                        
                                                                    {(!staff.has_form) &&
                                                                        <div className='baloa-username'><span className='green-bullet color'>&bull;</span>{props.t('viewTournament.inscription.teamPlayerWidget.pendinigForm')}</div>            
                                                                    }
                                                                    </React.Fragment> 
                                                                }
                                                                {/* {(!staff.has_player_form || staff.form_state === 'No_Needed') &&
                                                                    <div className='tournament-detail__player-inscription-column baloa-table-column'>&nbsp;</div>
                                                                } */}
                                                            </div>
                                                            </div>
                                                        </div>
                                                        ))
                                                    }
                                                    {props.next_team_staff && !props.loading_team_staff &&
                                                        <div className='tournament-detail__team-players__players__view-more-link baloa-names' onClick={()=>{props.handleOnNextTeamStaff()}}>{props.t('viewTournament.inscription.teamPlayerWidget.viewMore')}</div>
                                                    }
                                                    {listTeamsStaff.length === 0 &&
                                                        <div className='tournament-detail__team-players__players__empty-msg baloa-names '>{props.t('viewTournament.inscription.teamPlayerWidget.emptyStaffMsg')}</div>
                                                    }
                                                    {listTeamsStaff.length > 0 && props.loading_team_staff &&
                                                        <React.Fragment>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                                    <Skeleton  animation="wave"  width={135}/>
                                                                    <Skeleton  animation="wave" width={80}/>
                                                                </div>
                                                                <Skeleton animation="wave" width={80} className='tournament-detail__team-players__players-skeleton__last-text'/>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        </div>
                    </div>
                )}
                {/* FIN tab tournament inscriptions */}
                {/* TAB PODIO */}
                {value === index && index === 3 && (
                    <div className='tournament-detail__tournament-podium'>
                        <TournamentNewPodiumComponent 
                            selected_tournament={props.league_selected_tournament}
                            tournament_teams={props.tournament_teams}
                            handleTeamMembersModal={props.handleTeamMembersModal}
                        />
                    </div>
                )}
                {/* TAB PARTIDOS */}
                {value === index && index === 4 && (
                    <React.Fragment>
                        { props.tournament_phases?.length > 0 &&  
                            <div className='league-tournament-report-tab-options_iframe'>
                                <div className='league-tournament-report__phases__list'>
                                    <FormControl >
                                        <Select
                                            value={props.phaseId? props.phaseId: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id }
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            variant='standard'
                                            >  
                                                { props.tournament_phases?.map((phase, index) =>                                                 
                                                    (
                                                        <MenuItem 
                                                            key={`Phase ${index+1}`}
                                                            value={phase.id} 
                                                            onClick = {() => {
                                                                if(phase.groups.length !== 0){
                                                                    setActivePhase(`Phase ${index+1}`)                                                                                                      
                                                                }
                                                                props.handleSetPhaseId(phase.id);
                                                                props.handleOnPhaseClick(phase);
                                                            }} 
                                                        >
                                                            {`${props.t('league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                        </MenuItem>
                                                    ))
                                                }
                                        </Select> 
                                    </FormControl>
                                </div>
                                <SecondaryButtonComponent
                                    className='tournament-edit-tournament_iframe_share-btn small'
                                    input_type={'button'}
                                    onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.tournament.id, 'matches')}}
                                    >
                                    <span className='hubicon-code'/>
                                    <span className="baloa-table">{props.t('league:options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                                </SecondaryButtonComponent>
                            </div>
                        }
                            <Tabs
                                className={`league-tournament-report-calendar__tab ${selectedindex === 0 ? "league-tournament-report-calendar__tabs" : "league-tournament-report-calendar__tabs_border"}`}
                                value={selectedTab} 
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(event, newValue) =>handleSelectedTabChange(newValue)}
                                TabIndicatorProps={{
                                    style: { display: 'none' }
                                }}
                                //TabIndicatorProps={{ hidden: true }} //TODO: MUI V.5
                            >
                                <Tab className="league-tournament-report-calendar__tabs" label={props.t('league:options.tournaments.pages.tournamentInfo.labelperday')}/>
                                <Tab className="league-tournament-report-calendar__tabs" label={props.t('league:options.tournaments.pages.tournamentInfo.labelcalendarview')}/>
                            </Tabs>
                        { (props.tournament_phases?.length > 0 /* && ((props.phase_dates !== undefined && props.phase_dates?.length > 0) || props.days_calendar_view.calendar?.length > 0 ) */ ) &&
                            (
                                (selectedindex === 0 && /* props.phase_dates?.length > 0 && */ ( //days view
                                <LeagueTournamentReportDatesComponent 
                                    phase_dates = { props.phase_dates }
                                    selected_tournament = { props.tournament }
                                    handleOnPostMatch = { props.handleOnPostMatch }
                                    handleOnPhaseDate = { props.handleOnPhaseDate }
                                    phase_matches_by_date = { props.phase_matches_by_date }
                                    groups_matches_by_date = { props.groups_matches_by_date }
                                    setDateTab = { props.setDateTab }
                                    dateTab = { props.dateTab }
                                    active_phase_id={props.active_phase_id}
                                    tournament_active_phase={props.tournament_active_phase}
                                    teams_match_assistance={props.teams_match_assistance}

                                    phase_date_groups={ props.phase_date_groups }
                                    phase_date_group_matches={ props.phase_date_group_matches }
                                    phase_date_group_matches_next={ props.phase_date_group_matches_next }
                                    loading_phase_date_groups={ props.loading_phase_date_groups }
                                    handleOnDateGroup={props.handleOnDateGroup}
                                    handleViewMoreDateMatches={props.handleViewMoreDateMatches}
                                />)
                            )
                            ||
                                (selectedindex === 1 && /* props.days_calendar_view.calendar?.length > 0 && */( //calendar view
                                    <LeagueTournamentReportCalendarComponent
                                        active_phase_id={props.active_phase_id}
                                        //days_calendar_view = { props.days_calendar_view }
                                        handleOnPhaseCalendar={props.handleOnPhaseCalendar}
                                        //loadPhaseCalendar={props.loadPhaseCalendar}                                        
                                        //groups_matches={ props.groups_matches }
                                        tournament_phase_id={props.tournament_phase_id}
                                        tournament_active_phase={props.tournament_active_phase}
                                        handleOnPostMatch = { props.handleOnPostMatch }

                                        calendar_matchdays={props.calendar_matchdays}
                                        calendar_group_matches={props.calendar_group_matches}
                                        calendar_group_matches_next={props.calendar_group_matches_next}
                                        handleOnMatchday={ props.handleOnMatchday }
                                        loading_phase_calendar={props.loading_phase_calendar}
                                        handleViewMoreMatches={props.handleViewMoreMatches}
                                    />
                                ))
                            )
                        }
                        { /* ((props.phase_dates?.length === 0 && selectedindex === 0) || (props.days_calendar_view.calendar?.length === 0 && selectedindex === 1)) &&
                            <div className='tournament-detail__no-dates-phases__container'>
                                <img  src={estirando1} alt=""/>
                                <p className='baloa-body-1'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseWithoutDates')}</p>
                                
                            </div> */
                        }
                        { (props.tournament_phases?.length <= 0 || props.days_calendar_view.calendar?.length <= 0) &&
                            <div className='league__tournament__report-msg-container'>
                                <p className='baloa-headline-6'>{props.t('league:options.tournaments.tabs.tabsTournamentsList.noDataToShowTitle')}</p>
                                <p className='baloa-body-1'>{props.t('league:options.tournaments.tabs.tabsTournamentsList.noDataToShowText')}</p>
                            </div>
                        }
                    </React.Fragment>
                    )}
                {/* TAB TABLA */}
                {value === index && index === 5 && (
                    <div>
                        { props.tournament_phases?.length > 0 &&
                            <div className='league-tournament-report__tab'>
                                <div className='league-tournament-report-tab-options_iframe'>
                                    <div className='league-tournament-report__phases__list'>
                                        <FormControl >
                                            <Select
                                                value={props.phaseId? props.phaseId: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id }
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                variant='standard'
                                                >  
                                                    { props.tournament_phases?.map((phase, index) => (
                                                            <MenuItem 
                                                                key={`Phase ${index+1}`}
                                                                value={phase.id} 
                                                                onClick = {() => {
                                                                    if(phase.groups.length !== 0){
                                                                        props.handleSetPhaseId(phase.id);
                                                                        setActivePhase(`Phase ${index+1}`);
                                                                        props.handleOnPhaseClick(phase);                                                       
                                                                    } else {
                                                                        console.log('la fase no tiene grupos')
                                                                    }
                                                                }} 
                                                            >
                                                                {`${props.t('league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                            </MenuItem>
                                                            )
                                                        )
                                                    }
                                            </Select> 
                                        </FormControl>                                        
                                    </div>
                                    <SecondaryButtonComponent
                                        className='tournament-edit-tournament_iframe_share-btn small'
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.tournament.id, 'results_table')}}
                                    >
                                        <span className='hubicon-code'/>
                                        <span className="baloa-table">{props.t('league:options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                                    </SecondaryButtonComponent>
                                </div>
                                <div className="league-tournament-report__classified classified">
                                    <div className="league-tournament-report__classified-square" />
                                    <div className="baloa-table">{props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamClasified')}</div>                                                      
                                </div>
                                { props.is_best_third &&
                                    <div className="league-tournament-report__classified">
                                        <div className="league-tournament-report__best-third-square" />
                                        <div className="baloa-table">{props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamBestThird')}</div>
                                    </div>
                                }
                                { 
                                    <TournamentPositionGoalScorerTableComponent
                                        className='league-tournament-report__tab__positions-table'
                                        phase_groups_results_table={ props.phase_groups_results_table }
                                        tournament_results_table = { props.tournament_results_table }                        
                                        phase_type = { props.tournament_active_phase?.phase_type }
                                        phase_name = { props.tournament_active_phase?.phase_name }
                                        handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                                        loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                                    />
                                }
                            </div>
                        }

                        { props.tournament_phases?.length === 0 &&
                            <div className='league__tournament__report-msg-container'>
                                <p className='baloa-headline-6'>{props.t('league:options.tournaments.tabs.tabsTournamentsList.noDataToShowTitle')}</p>
                                <p className='baloa-body-1'>{props.t('league:options.tournaments.tabs.tabsTournamentsList.noDataToShowText')}</p>
                            </div>
                        }
                    </div>
                )}
                {/* TAB ESTADISTICAS */}
                {value === index && index === 6 && (
                    <TournamentStatisticsComponent 
                        tournament_results_table = { props.tournament_results_table ? props.tournament_results_table : [] }
                        tournament_scorers = { props.tournament_scorers }
                        tournament_yellow_cards = { props.tournament_yellow_cards }
                        tournament_red_cards = { props.tournament_red_cards }
                        tournament_type = { props.tournament_active_phase?.phase_type}
                        loading_tournament_scorers = { props.loading_tournament_scorers }
                        loading_tournament_yellow_cards = { props.loading_tournament_yellow_cards }
                        loading_tournament_red_cards = { props.loading_tournament_red_cards }
                        next_tournament_scorers = { props.next_tournament_scorers }
                        next_tournament_yellow_cards = { props.next_tournament_yellow_cards }
                        next_tournament_red_cards = { props.next_tournament_red_cards }
                        handleOnViewMoreStatistics = { props.handleOnViewMoreStatistics }
                    />
                )}
            </div>
        </div>
    );
}

function TournamentDetailComponent( props ) {

    const { tournament, my_teams } = props;
    //const tournament = props.tournaments[0];
    const [tabSelected, setTabSelected] = React.useState(0);
    const [availableCodes, setAvailableCodes] = React.useState(0);
    const [postMatchDetail, setPostMatchDetail] = React.useState(false);
    const [statisticsMatch, setStatisticsMatch] =  React.useState(false);
    const [dateTab, setDateTab] =  React.useState(0);
    const [phaseId, setPhaseId] = React.useState(null);
    const [isRefereesPaymentResponse, setIsRefereesPaymentResponse] = React.useState(false);

    React.useEffect(() => {
        if (props.selected_team?.total_codes != 0 || props.selected_team?.total_redeemed_codes != 0) {
            setAvailableCodes((props.selected_team?.total_codes - props.selected_team?.total_redeemed_codes));
        }
    }, [props.selected_team?.total_codes, props.selected_team?.total_redeemed_codes])

    React.useEffect(()=>{
        if(props.tournament_phases.length > 0){
            if( props.referees_payment_response && (props.referees_payment_response.type === 'referee' || props.referees_payment_response.type === 'match') && (props.active_phase_id !== props.referees_payment_response.phase_id)) {
                props.handleOnPhaseClick(props.tournament_phases[props.tournament_phases.findIndex(phase => phase.id === props.referees_payment_response.phase_id)]);
                props.handleMatchStatistics(props.referees_payment_response.match_id);
                setPostMatchDetail(false);
                setStatisticsMatch(true);
                if(props.referees_payment_response.type === 'referee'){
                    const timeout = setTimeout(() => {
                        setIsRefereesPaymentResponse(true);
                    }, 2000);
                    return () => clearTimeout(timeout);
                }                
            } else {
                if(!props.tournament_active_phase.is_last_active_phase){
                    props.handleOnPhaseClick(props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]);
                }
                
            }
        }
    }, [props.tournament_phases]); // eslint-disable-line

    React.useEffect(() => {
        if(props.is_inscription && props.referees_payment_response.hasOwnProperty('match')) {
            setTabSelected(1);
        }
    }, [props.referees_payment_response]); // eslint-disable-line

    const handleOnPostMatch = (match) => {
        props.handleMatchStatistics(match.id);
        if(match.state === 'Finalized') {      
            props.loadAllPlayersAttended(match.id);
            setPostMatchDetail(false);
            setStatisticsMatch(true);
        } else {
            if(props.match_report !== undefined){
                //props.handleOnMatchDetailModal(props.match_report);
                setPostMatchDetail(false);
                setStatisticsMatch(true);
                props.loadAdminPlayerAttended(match.id);
            } else {
                props.handleMatchPhaseError();
            }            
            //setPostMatchDetail(true);
            //setStatisticsMatch(false);
        }
    }

    const handleOnPostMatchBackBtn = () => {
        setPostMatchDetail(false);
        setStatisticsMatch(false);
        //props.handleCleanDeadlineInscriptionState();        
    }

    const handleSetPhaseId = (phase_id) => {
        setPhaseId(phase_id);
    }

    return (
        <React.Fragment>
            { !statisticsMatch &&  
                <div className='tournament-detail__container'>
                    <div className='tournament-detail__title baloa-subtitle-2'><span className='icon-arrow-left' onClick={()=>{props.handleBackButton()}}></span>{props.t('viewTournament.titleLbl')}</div>
                    {tournament?.photo &&
                        <div className="tournament-detail__image__background-img_container">
                            <img className="tournament-detail__image__background-img" src={tournament?.photo} alt={tournament?.name} />
                        </div>
                    }
                    <div className='tournament-detail__image'>
                        {tournament?.photo?
                            <img src={tournament?.photo} alt={tournament?.name}/>// eslint-disable-line
                        :
                            <span className={"icon-trophy"} color={getRandonIconBallColor()} ></span>
                        }
                        <div className='tournament-detail__image__tags'>
                            <div className='tournament-detail__image__tags-tag'>{tournament?.football_type? props.t('viewTournament.footballType.'+tournament?.football_type):''}</div>
                            <div className='tournament-detail__image__tags-tag'>{tournament?.gender? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+tournament?.gender):''}</div>
                            <div className='tournament-detail__image__tags-tag'>{tournament?.category? props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.'+ tournament?.category):''}</div>
                            <div className='tournament-detail__image__tags-tag'>{tournament?.type? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+ tournament?.type):''}</div>
                        </div>
                        {/* Boton de compartir torneo*/}
                        {tournament?.registration_type === "Free" && !isMobile &&
                            <SecondaryButtonComponent
                                className='tournament-detail__tabs__button small'
                                input_type={'button'}
                                onClick={ ()=>{ props.handleOnShareTournamentLinkClick(tournament) } }
                            >
                                <span className='icon-share'></span><span>{props.t('viewTournament.buttons.share')} </span>
                            </SecondaryButtonComponent>
                        }
                    </div>
                    <div className='tournament-detail__data'>
                        <div className='tournament-detail__data_share_mobile'>
                            <div className='tournament-detail__data__info baloa-subtitle-2'>
                                {tournament?.logo?
                                    <img src={tournament?.logo} alt={tournament?.name}/>// eslint-disable-line
                                :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                                &nbsp;{tournament?.name}
                            </div>
                            {tournament?.registration_type === "Free" && isMobile &&
                                    <span class="hubicon-share" onClick={ ()=>{ props.handleOnShareTournamentLinkClick(tournament) } }>
                                        <span class="path1">
                                        </span>
                                        <span class="path2">
                                        </span>
                                    </span>
                                }
                        </div>
                        <div className='tournament-detail__data_buttons'>
                            <SecondaryButtonComponent
                                className={'tournament-detail__data__button small'}
                                input_type={'button'}
                                onClick={ ()=>{ props.tournament?.following_tournament
                                    ? props.handleOnUnfollowTournament(props.tournament.id) 
                                    : props.handleOnFollowTournament(props.tournament.id) } 
                                }
                            >
                            {props.tournament?.following_tournament 
                                ? <div className='tournament-detail_data_buttons_icons'>
                                    <span class="icon-check-single-72" />
                                    {props.t('viewTournament.buttons.unFollow')}
                                </div>
                                :<div className='tournament-detail_data_buttons_icons'>
                                    <span className="icon-plus"></span>
                                    {props.t('viewTournament.buttons.follow')}
                                </div>

                            }
                            </SecondaryButtonComponent>

                            {props.is_tournament_admin && !isMobile &&
                                <Link to={`/league/tournament/${props.tournament.league_page}&setshowtournament=${props.tournament.id}&showtournament=true`}>
                                    <SecondaryButtonComponent
                                        className='tournament-detail__data__button small'
                                        input_type={'button'}
                                        onClick={ ()=>{  } }
                                    >
                                        <span className='icon hubicon-manage_accounts1'></span>
                                        <span>{props.t('viewTournament.buttons.manage')} </span>
                                    </SecondaryButtonComponent>
                                </Link>
                            }

                            {(!props.is_inscription || props.team_state === 'Invited') && props.tournament?.state != "Finalized" && props.tournament?.tournament_registration_deadline && 
                                <div className={`${isMobile ? 'tournament-detail__responsive__inscription-btn' : ''}`}>
                                    <PrimaryButtonComponent //TODO: revisar nuevo hub
                                        className='tournament-detail__data__button small'
                                        input_type={'button'}
                                        onClick={ ()=>{ /* props.profile_type === 'player' ? props.handleOnInscriptionPlayer() : */ props.handleOnInscriptionTeam() } } 
                                    >
                                        <span>{/* props.profile_type === 'player' ? props.t('viewTournament.buttons.inscribirJugador'): */props.t('viewTournament.buttons.inscribirEquipo')}</span> 
                                    </PrimaryButtonComponent>
                                </div>
                            }
                        </div>
                    </div>

                    <div >
                        <Tabs
                            variant="fullWidth"
                            className="tournament-detail__tabs"
                            value={tabSelected}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(ev, new_value) => {
                                setTabSelected(new_value);
                            }}
                            aria-label="Selection between "
                            /* TabIndicatorProps={{
                                style: {
                                  left: ""
                              },}} */
                        >
                            {/* Tab Inscripción */}
                            { props.is_inscription && (props.team_state === 'Register' || props.team_state === 'Rejected' || props.team_state === 'Enrolled') && 
                                <Tab label={props.t("viewTournament.tabs.inscriptionLbl")} />
                            }

                            {/* Tab Podio */}
                            { props.tournament?.state == 'Finalized' &&
                                <Tab label={props.t("viewTournament.tabs.podiumLbl")} />
                            }

                            {/* Tab Partidos */}
                            {props.tournament?.state !== 'Registration_Open' &&
                                    <Tab label={props.t("viewTournament.tabs.matchesLbl")} />
                            }
                            {/* Tab Tabla */}
                            {props.tournament?.state !== 'Registration_Open' &&
                                    <Tab label={props.t("viewTournament.tabs.resultsLabel")} />
                            }
                            {/* Tab Estadísticas */}
                            {props.tournament?.state !== 'Registration_Open' &&        
                                    <Tab label={props.t("viewTournament.tabs.statisticsLbl")} onClick={()=>{ props.loadStatistics( props.tournament.id )}} />                    
                            }

                            {/* Tab Informaión */}
                            <Tab label={props.t("viewTournament.tabs.detailsLbl")} />

                            {/* Tab Equipos */}
                            <Tab label={props.t("viewTournament.tabs.teamsLbl")} />
                            
                        </Tabs>

                        {/** Nombre del equipo sobre el cual rebi la invitacion o estoy inscrito */}
                        {props.team && props.teamname && !props.is_inscription && props.tournament?.state != "Finalized" &&
                            <div className={'tournament-detail__selected-team'}>
                                {props.team_logo?
                                    <img src={props.team_logo} alt={props.team}/>
                                :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                                {props.profile_type === 'player'? props.t('viewTournament.playerData', {_teamName: props.team }):props.t('viewTournament.teamData', {_teamName: props.team }) }

                            </div>
                        }

                        {/* tab tournament inscriptions */}
                        { props.is_inscription && (props.team_state === 'Register' || props.team_state === 'Rejected' || props.team_state === 'Enrolled') &&
                            <TabPanel
                                index={2}
                                value={tabSelected + 2}
                                my_teams = {my_teams}
                                team_logo={props.team_logo}
                                team={props.team}
                                teamname={props.teamname}
                                team_state={props.team_state}
                                //enrolled_players={props.tournament.team_enrolled_players}
                                //max_participant_players={props.tournament.players_count}total_team_players
                                enrolled_players={props.enrolled_players}
                                max_participant_players={props.max_participant_players}
                                team_players={props.team_players}
                                tournament_registration_type={props.tournament_registration_type}
                                next_team_players={props.next_team_players}
                                handleOnNextTeamPlayers={props.handleOnNextTeamPlayers}
                                t={props.t}
                                player_code={props.selected_team.player_code? props.selected_team.player_code : null} //TODO: CHECK AFTER CHANGE HUB APIS
                                showCopyPostMessage={props.showCopyPostMessage}
                                profile_type={props.profile_type}
                                has_team_form={props.tournament.has_team_form}//TODO: CHECK AFTER CHANGE HUB APIS
                                player_form_state={props.selected_team.is_tournament_player?.form_state}
                                team_form_state={props.selected_team.team_form_state}
                                //payment_state={props.tournament.payment_state}
                                team_payment_state={props.selected_team.team_payment_state}
                                player_payment_state={props.selected_team.is_tournament_player?.payment_state}
                                has_player_form={props.tournament.has_player_form}//TODO: CHECK AFTER CHANGE HUB APIS
                                total_codes={props.selected_team.total_codes? props.selected_team.total_codes : null}
                                total_redeemed_codes={props.selected_team.total_redeemed_codes? props.selected_team.total_redeemed_codes : null}
                                availableCodes={availableCodes}
                                player_has_paid={props.selected_team.is_tournament_player?.player_has_paid}
                                team_has_paid={props.selected_team.team_has_paid}//TODO: CHECK AFTER CHANGE HUB APIS
                                request_is_admin={props.selected_team.request_is_admin}

                                
                                request_is_member={props.selected_team.request_is_member}
                                is_tournament_admin={props.tournament.is_tournament_admin}                                

                                //Player Info
                                player_state={props.selected_team.is_tournament_player?.state}
                                profile_photo={props.selected_team.is_tournament_player?.photo}
                                full_name={props.selected_team.is_tournament_player?.full_name}
                                username={props.selected_team.is_tournament_player?.username}

                                //Staff info
                                request_is_staff={props.selected_team.request_is_staff}
                                staff_photo={props.selected_team.is_profile_member?.photo}
                                staff_full_name={props.selected_team.is_profile_member?.full_name}
                                staff_username={props.selected_team.is_profile_member?.username}
                                staff_has_form={props.selected_team.is_profile_member?.form_id} //Si el id es nulo no tiene formulario de stad diligenciado

                                //Actions
                                handleRealizePlayerFormButton={props.handleRealizePlayerFormButton}
                                handleRealizePlayerPaymentButton={props.handleRealizePlayerPaymentButton}
                                handleRealizeTeamFormButton={props.handleRealizeTeamFormButton}
                                handleRealizeTeamPaymentButton={props.handleRealizeTeamPaymentButton}
                                tournament_team_id={props.selected_team.id}
                                handleOnShareInscriptionTournamentLinkClick={props.handleOnShareInscriptionTournamentLinkClick}
                                handleOnShareInvitedPlayerTournamentLinkClick={props.handleOnShareInvitedPlayerTournamentLinkClick}
                                changeSelectedTournamentTeam={props.changeSelectedTournamentTeam}
                                //handleRejectButtonAction={props.handleRejectButtonAction}
                                is_transfer_player={props.selected_team.is_tournament_player?.is_transfer_player}
                                tournament_registration_deadline={props.tournament?.tournament_registration_deadline? props.tournament?.tournament_registration_deadline : false}
                                team_page={props.selected_team.team_page} //TODO: CHECK AFTER CHANGE HUB APIS
                                unlocked_stats={props.selected_team.is_tournament_player?.unlocked_stats} //TODO: CHECK AFTER CHANGE HUB APIS
                                handleOnResumePaymentStats={props.handleOnResumePaymentStats}
                                teams_manager_and_staff={props.teams_manager_and_staff}
                                handleOnShareAddMembersWithoutAccountClick={props.handleOnShareAddMembersWithoutAccountClick}
                                handleOnShareAddMembersWithAccountClick={props.handleOnShareAddMembersWithAccountClick}
                                openMultiplePlayerForms={props.openMultiplePlayerForms}
                                loading_team_players={props.loading_team_players}
                                handleOnNextTeamStaff={props.handleOnNextTeamStaff}
                                loading_team_staff={props.loading_team_staff}
                                next_team_staff={props.next_team_staff}
                                handleSearchHubTournamentTeamPlayers={props.handleSearchHubTournamentTeamPlayers}

                                is_tournament_player={props.selected_team.is_tournament_player}
                            />
                        }
                        { props.tournament?.state == 'Finalized' &&
                            <TabPanel
                                index={3}
                                value={ tabSelected + 3 }
                                tournament={tournament}
                                t={props.t}

                                league_selected_tournament={props.league_selected_tournament}
                                tournament_teams={props.tournament_teams}
                                handleTeamMembersModal={props.handleTeamMembersModal}
                            />
                        }
                        {props.tournament?.state !== 'Registration_Open' && 
                            <React.Fragment>
                                {/* TAB PARTIDOS */}
                                <TabPanel
                                    index={4}
                                    value={ props.is_inscription? tabSelected + 3: props.tournament?.state == 'Finalized' ? tabSelected +3 : tabSelected +4 }
                                    tournament={tournament}
                                    t={props.t}
                                    
                                    tournament_phases={props.tournament_phases}
                                    handleOnPhaseClick={ props.handleOnPhaseClick }
                                    phase_dates={props.phase_dates}
                                    //selected_tournament
                                    handleOnPostMatch={ handleOnPostMatch }
                                    handleOnPhaseDate={ props.handleOnPhaseDate }
                                    phase_matches_by_date={ props.phase_matches_by_date }
                                    groups_matches_by_date={ props.groups_matches_by_date }
                                    setDateTab={ (tab)=>{setDateTab( tab ) } }
                                    dateTab={ dateTab }
                                    tournament_active_phase = { props.tournament_active_phase }
                                    handleSetPhaseId={ (phase_id) => { handleSetPhaseId(phase_id) } }
                                    phaseId={phaseId}
                                    active_phase_id={props.active_phase_id}
                                    handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                                    teams_match_assistance={props.teams_match_assistance}
                                    handleOnPhaseCalendar={props.handleOnPhaseCalendar}
                                    loadPhaseCalendar={props.loadPhaseCalendar}
                                    days_calendar_view={props.days_calendar_view}
                                    groups_matches={props.groups_matches}
                                    tournament_phase_id={props.tournament_phase_id}

                                    calendar_matchdays={props.calendar_matchdays}
                                    calendar_group_matches={props.calendar_group_matches}
                                    calendar_group_matches_next={props.calendar_group_matches_next}
                                    handleOnMatchday={ props.handleOnMatchday }
                                    loading_phase_calendar={props.loading_phase_calendar}
                                    handleViewMoreMatches={props.handleViewMoreMatches}

                                    phase_date_groups={ props.phase_date_groups }
                                    phase_date_group_matches={ props.phase_date_group_matches }
                                    phase_date_group_matches_next={ props.phase_date_group_matches_next }
                                    loading_phase_date_groups={ props.loading_phase_date_groups }
                                    handleOnDateGroup={props.handleOnDateGroup}
                                    handleViewMoreDateMatches={props.handleViewMoreDateMatches}
                                />
                                {/* TAB TABLA */}
                                <TabPanel
                                    index={5}
                                    value={ props.is_inscription? tabSelected + 3: props.tournament?.state == 'Finalized' ? tabSelected +3 : tabSelected +4 }
                                    tournament={tournament}
                                    t={props.t}

                                    tournament_phases = { props.tournament_phases }
                                    //selected_tournament = { props.selected_tournament }  
                                    tournament_active_phase = { props.tournament_active_phase }
                                    phase_groups_results_table={ props.phase_groups_results_table }
                                    tournament_results_table = { props.tournament_results_table }
                                    handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                                    loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                                    handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                                    setDateTab = { (tab)=>{setDateTab( tab ) } }
                                    dateTab = { dateTab }
                                    tournament_teams={props.tournament_teams}
                                    //handleTeamMembersModal={props.handleTeamMembersModal}
                                    is_best_third={props.is_best_third}
                                    handleSetPhaseId={ (phase_id) => { handleSetPhaseId(phase_id) } }
                                    phaseId={phaseId}
                                    handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                                    
                                />
                                {/* TAB ESTADISTICAS */}
                                <TabPanel
                                    index={6}
                                    value={ props.is_inscription? tabSelected + 3: props.tournament?.state == 'Finalized' ? tabSelected +3 : tabSelected +4 }
                                    tournament={tournament}
                                    t={props.t}

                                    tournament_active_phase = { props.tournament_active_phase }
                                    tournament_results_table = { props.tournament_results_table }
                                    tournament_scorers = { props.tournament_scorers }
                                    tournament_yellow_cards = { props.tournament_yellow_cards }
                                    tournament_red_cards = { props.tournament_red_cards }
                                    loading_tournament_scorers = { props.loading_tournament_scorers }
                                    loading_tournament_yellow_cards = { props.loading_tournament_yellow_cards }
                                    loading_tournament_red_cards = { props.loading_tournament_red_cards }
                                    next_tournament_scorers = { props.next_tournament_scorers }
                                    next_tournament_yellow_cards = { props.next_tournament_yellow_cards }
                                    next_tournament_red_cards = { props.next_tournament_red_cards }
                                    handleOnViewMoreStatistics = { props.handleOnViewMoreStatistics }
                                />
                            </React.Fragment>
                        }
                        {/* tab tournament information */}
                        <TabPanel
                            index={0}
                            value={ (props.is_inscription && props.tournament?.state !== 'Registration_Open')? 
                                tabSelected - 4: (props.is_inscription && (props.team_state === 'Register' || props.team_state === 'Rejected' || props.team_state === 'Enrolled') && props.tournament?.state === 'Registration_Open')? 
                                    tabSelected - 1 : props.tournament?.state == 'Finalized' ? 
                                        tabSelected -4 : (props.team_state == "Invited" || (!props.is_inscription && props.tournament?.state === 'Registration_Open'))? 
                                            tabSelected : tabSelected -3 }
                            tournament={tournament}
                            t={props.t}
                        />

                        {/* tab tournament teams */}
                        <TabPanel
                            index={1}
                            value={ (props.is_inscription && props.tournament?.state !== 'Registration_Open')? 
                                tabSelected - 4: (props.is_inscription && (props.team_state === 'Register' || props.team_state === 'Rejected' || props.team_state === 'Enrolled') && props.tournament?.state === 'Registration_Open')? 
                                    tabSelected - 1 : props.tournament?.state == 'Finalized' ? 
                                        tabSelected -4: (props.team_state == "Invited" || (!props.is_inscription && props.tournament?.state === 'Registration_Open'))? 
                                            tabSelected : tabSelected -3  }
                            tournament_teams={props.tournament_teams}
                            t={props.t}
                            handleTeamMembersModal={props.handleTeamMembersModal}
                        />
                    </div>                
                </div>
            }
            { !postMatchDetail && statisticsMatch &&
                <div className='league-tournament-report__event-container'>                                
                    <LeagueMatchReportComponent 
                        data = { props?.match_report }
                        handleBackButton = { () => {handleOnPostMatchBackBtn()} } 
                        backButtonTitle =  {props.t(!isMobile ?'league:options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelMatchStatistics' : 'league:options.tournaments.pages.matchInfo.labelTitle')} 
                        tournament={ props.league_selected_tournament }

                        resetError={()=>{}}                
                        errors={props.errors}
                        onResetError={props.onResetError}               
                        tournament_staff={props.tournament_staff}
                        handleOnLoadRefereeingPaymentUrl={props.handleOnLoadRefereeingPaymentUrl}
                        handleUpdateRefereesMatch={props.handleUpdateRefereesMatch}
                        handleOnAcceptCrewPayment={props.handleOnAcceptCrewPayment}
                        isRefereesPaymentResponse={isRefereesPaymentResponse}
                        handleOnShareMatchLinkClick={props.handleOnShareMatchLinkClick}
                        teams_match_assistance={props.teams_match_assistance}
                        updatePlayersAssistanceAligment={props.updatePlayersAssistanceAligment}
                    />                
                </div>
            }
        </React.Fragment>
    )
}

export default withTranslation(["tournament", "league", "profile"])(TournamentDetailComponent);