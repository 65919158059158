// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetTournamentsApi = (next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/hub-tournaments/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetHubTournamentInfoApi = (tournament_id) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/hub-tournaments/${tournament_id}/`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentHubTeamsApi = (tournament_id, teams_state = '') => {

    return Axios.instance.get(`${api_url}/hub-tournaments/${tournament_id}/teams/?state=${teams_state}&version=2&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetFrontTournamentHubTeamsApi = (tournament_id, teams_state = '', page_size=200) => {

    return Axios.instance.get(`${api_url}/main-tournaments/${tournament_id}/teams/?state=${teams_state}&page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetHubTeamsTournamentsApi = (teams_state = '') => {

    return Axios.instance.get(`${api_url}/hub-tournament-teams/?state=${teams_state}&version=2&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetHubPlayerTournamentsApi = (player_state = '') => {

    return Axios.instance.get(`${api_url}/hub-tournament-players/?state=${player_state}&version=2&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const InviteTeamToTournamentApi = (id_tournament, team_name, team_state) => {
    return Axios.instance.post(`${api_url}/hub-tournaments/${id_tournament}/teams/`,
        {
            team: team_name,
            state: team_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const ChangeTournamentTeamApi = (tournament_team_id, team_state) => {
    return Axios.instance.patch(`${api_url}/hub-tournament-teams/${tournament_team_id}/state/`,
        {
            state: team_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const ChangeTournamentPlayerApi = (tournament_team_id, player_state) => {
    return Axios.instance.patch(`${api_url}/hub-tournament-players/${tournament_team_id}/state/`,
        {
            state: player_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const ChangeTournamentApi = (tournament_id, tournament_state) => {
    return Axios.instance.patch(`${api_url}/hub-tournaments/${tournament_id}/state/`,
        {
            state: tournament_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentTeamPlayersApi = (tournament_team_id, state, next_page) => {
    //return Axios.instance.get(`${api_url}/hub-tournament-teams/${tournament_team_id}/players/?state=${state}&pagination=False&version=2`,
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournament-teams/${tournament_team_id}/players/?state=${state}`,
    {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFrontTournamentTeamPlayersApi = (tournament_team_id, state, next_page) => {
    return Axios.instance.get(next_page ? next_page : `${api_url}/main-tournament-teams/${tournament_team_id}/players/?state=${state}`,
    {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetAllManagedTournamentsApi = ( filter ) => {
    return Axios.instance.get(`${api_url}/hub-tournaments-admin/?pagination=False${filter ?? ''}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

//TODO: pediente verificar para unificar
export const GetProfileAdminTeamsApi = ( queryParams ) => {
    return Axios.instance.get( `${ api_url }/hub-teams-admin/?${queryParams ?? ''}&pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentsFilterApi = (filter) => {
    return Axios.instance.get(`${api_url}/hub-tournaments/?pagination=False${filter ?? ''}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


export const GetPaymentApi = ( id_tournament, players, redirect ) => {
    return Axios.instance.post( `${ api_url }/hub-tournament-teams/${id_tournament}/payments/`, 
        {
            players_quantity: players,
            redirect: redirect
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}
    
export const PlayerInscriptionApi = ( data ) => {
    return Axios.instance.post( `${ api_url }/form/`,
        data ,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPlayerPaymentApi = ( tournament_player_id, player_code, redirect ) => {
    return Axios.instance.post( `${ api_url }/hub-tournament-players/${tournament_player_id}/payments/`, 
        {
            player_code: player_code,
            redirect: redirect
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetHubTeamTournamentInfoApi = (tournament_team_id) => { //TODO: Nuava api equipo integrado con player nuevo hub de torneos

    //return Axios.instance.get(`${api_url}/hub-tournament-teams/${tournament_team_id}/`,
    return Axios.instance.get(`${api_url}/hub-tournament-team/${tournament_team_id}/`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetHubPlayerTournamentInfoApi = (tournament_player_id) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/hub-tournament-players/${tournament_player_id}/`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentHubTournamentTeamsApi = (tournament_id, teams_state = '') => {

    return Axios.instance.get(`${api_url}/hub-tournament-teams/${tournament_id}/teams/?state=${teams_state}&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentInscriptionFormTemplateIdApi = ( form_type, id_torneo ) => {

    return Axios.instance.get(`${api_url}/form-template/?form_type=${form_type}&owner_id=${id_torneo}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentInscriptionFormTemplateApi = ( id_form_template ) => {

    return Axios.instance.get(`${api_url}/form-template/${id_form_template}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetUnauthenticatedTournamentsApi = (next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/hub-tournaments/`, {});

}

export const GetUnauthenticatedHubTournamentInfoApi = (tournament_id) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/hub-tournaments/${tournament_id}/`,{} );

}

export const GetUnauthenticatedTournamentHubTeamsApi = (tournament_id, teams_state = '', page_size=200) => {

    //return Axios.instance.get(`${api_url}/hub-tournaments/${tournament_id}/teams/?state=${teams_state}&version=2&pagination=False`,{});
    return Axios.instance.get(`${api_url}/main-tournaments/${tournament_id}/teams/?state=${teams_state}&page_size=${page_size}`,{}); //Cambio apis sin paginar hub de torneos
}

export const GetUnauthenticatedTournamentsFilterApi = (filter) => {

    return Axios.instance.get(`${api_url}/hub-tournaments/?pagination=False${filter ?? ''}`,{});

}

export const GetNewHubTournamentInfoApi = (tournament_id, page_size=100) => {

    //return Axios.instance.get(`${api_url}/hub-tournament/${tournament_id}/teams/?version=2`,
    return Axios.instance.get(`${api_url}/main-feed-tournaments/${tournament_id}/teams/?page_size=${page_size}`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetHubTournamentsSectionsApi = () => {

    return Axios.instance.get(`${api_url}/hub-tournament/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetHubTournamentsSubsectionsApi = (tournament_subsection, next_page) => {
    return Axios.instance.get(next_page ? next_page : `${api_url}/hub-tournament/?subsection=${tournament_subsection}`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetHubInformativeTournamentApi = (tournament_id) => {

    //return Axios.instance.get(`${api_url}/hub-tournament/${tournament_id}/`
    return Axios.instance.get(`${api_url}/main-feed-tournaments/${tournament_id}/`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetHubTournamentSearchApi = (tournament_query, next_page) => {
    return Axios.instance.get(next_page ? next_page : `${api_url}/hub-tournament-search/?${tournament_query}`,{
            headers: {  
                'Authorization': localStorage.getItem('token')
            }        
        });
}

export const PostFollowTournamentHubApi = (tournament_id) => {

    return Axios.instance.post(
        `${api_url}/tournaments/${tournament_id}/follow/`,
    {},
        {
            headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const DeleteUnfollowTournamentHubApi = (tournament_id) => {

    return Axios.instance.delete(`${api_url}/tournaments/${tournament_id}/unfollow/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetTournamentMembersTeamsApi = (tournament_team_id,) => {

    return Axios.instance.get(`${api_url}/hub-tournament-teams/${tournament_team_id}/members/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetHubTournamentMatchesApi = () => {

    return Axios.instance.get(`${api_url}/tournament-matches-widget/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetHubTournamentMatchesByDateApi = (match_date) => { //date-> yyyy-mm-dd

    return Axios.instance.get(`${api_url}/hub-tournament-matches/?match_date=${match_date}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetHubTournamentNextMatchesApi = () => {

    return Axios.instance.get(`${api_url}/tournament-matches-hub-widget/?first_four=True`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetHubTournamentMatchesDatesApi = () => {

    return Axios.instance.get(`${api_url}/tournament-matches-hub-calendar-dates/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SearchProfileAdminTeamsApi = ( queryParams ) => {
    return Axios.instance.get( `${ api_url }/hub-teams-admin/?${queryParams ?? ''}&pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentTeamManagerAndStaffApi = (team_name, tournament_id, next_page) => {
    return Axios.instance.get(next_page ? next_page :`${api_url}/teams-page/${team_name}/members/?tournament_id=${tournament_id}&only_staff=true`,
    {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPhaseCalendarApi = (tournament_phase_id) => {
    return Axios.instance.get(`${api_url}/tournament-phase/${tournament_phase_id}/calendar/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPhaseCalendarMatchdaysApi = (tournament_phase_id) => {
    return Axios.instance.get(`${api_url}/main-tournaments-phase/${tournament_phase_id}/calendar/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPhaseCalendarGroupMatchesApi = ( tournament_group_id, date_number, next_url, page_size = 10 ) => {
    return Axios.instance.get(next_url? next_url : `${api_url}/main-tournaments-group/${tournament_group_id}/calendar_matches/?date_number=${date_number}&page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFrontTournamentTeamSearchPlayersApi = (tournament_team_id, player, state) => {
    return Axios.instance.get(`${api_url}/main-tournament-teams/${tournament_team_id}/players/?search=${player}&state=${state}`,
    {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPhaseGroupsByDateApi = (tournament_phase_id, calendar_date, page_size = 1000) => {

    return Axios.instance.get(`${api_url}/main-tournaments-phase/${tournament_phase_id}/calendar_groups/?calendar_date=${calendar_date}&page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPhaseMatchesByGroupDateApi = (tournament_phase_id, calendar_date, group_number, next_url, page_size = 10) => {

    return Axios.instance.get( next_url? next_url : `${api_url}/main-tournaments-phase/${tournament_phase_id}/calendar_matches/?calendar_date=${calendar_date}&group_number=${group_number}&page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}
