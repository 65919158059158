/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayPaymentMessageModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayPaymentMessageModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-payment-message-modal"
        >
            <div className={`baloa-play-payment-message-modal__container `}>
                <span className='hubicon-play_circle' />
                <div className='baloa-normal-medium'>{props.t('paymentTitle')}</div>
                <div className='baloa-names'>{props.t('paymentMessage',{_value: props.match_recording_price?.value, _currency: props.match_recording_price?.currency})}</div>
                <div>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnPay()}}
                    >
                        <span>{props.t('paymentBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayPaymentMessageModalComponent);