/*eslint eqeqeq: "off"*/
// Tournament state
const tournament_state = {
    tournament:{
        category: undefined,
        champion: undefined,
        champion_coach: null,
        champion_coach_name: null,
        champion_coach_photo: null,
        champion_name: undefined,
        champion_page_owner: undefined,
        champion_photo: undefined,
        count_teams: undefined,
        currency_payment: undefined,
        description: undefined,
        end_date: undefined,
        id: undefined,
        league_page: undefined,
        least_beaten_fence: undefined,
        least_beaten_fence_name: undefined,
        least_beaten_fence_photo: undefined,
        main_city: undefined,
        main_country: undefined,
        material_prize: undefined,
        max_participant_players: undefined,
        max_participant_teams: undefined,
        min_participant_players: undefined,
        min_participant_teams: undefined,
        monetary_prize: undefined,
        name: undefined,
        organizer: undefined,
        registration_deadline: undefined,
        registration_price_per_player: undefined,
        registration_price_per_team: undefined,
        revelation_player: undefined,
        revelation_player_name: undefined,
        revelation_player_photo: undefined,
        rules: undefined,
        runner_up: undefined,
        runner_up_coach: undefined,
        runner_up_coach_name: undefined,
        runner_up_coach_photo: undefined,
        runner_up_name: undefined,
        runner_up_page_owner: undefined,
        runner_up_photo: undefined,
        scorer: undefined,
        scorer_name: undefined,
        scorer_photo: undefined,
        start_date: undefined,
        state: undefined,
        team_least_beaten_fence: undefined,
        team_least_beaten_fence_page_owner: undefined,
        team_least_beaten_fence_photo: undefined,
        team_name_least_beaten_fence: undefined,
        team_name_scorer: undefined,
        team_revelation_player: undefined,
        team_revelation_player_name: undefined,
        team_revelation_player_photo: undefined,
        team_scorer: undefined,
        team_scorer_page_owner: undefined,
        team_scorer_photo: undefined,
        team_tournament_player: undefined,
        team_tournament_player_name: undefined,
        team_tournament_player_photo: undefined,
        third_place: undefined,
        third_place_coach: undefined,
        third_place_coach_name: undefined,
        third_place_coach_photo: undefined,
        third_place_name: undefined,
        third_place_page_owner: undefined,
        third_place_photo: undefined,
        total_goals_scorer: undefined,
        total_goals_conceded: undefined,
        tournament_gender: undefined,
        tournament_logo: undefined,
        tournament_photo: undefined,
        tournament_player: undefined,
        tournament_player_name: undefined,
        tournament_player_photo: undefined,
        tournament_prize_type: undefined,
        tournament_referee_payment_config: [],
        tournament_registration_deadline: undefined,
        tournament_registration_pay: undefined,
        tournament_registration_type: undefined,
        tournament_summon_registration: undefined,
        tournament_tag: undefined,
        tournament_type: undefined,
        unlimited_participant_players: false,
        various_categories: false,
        various_editions: false,
        football_type: undefined,
        start_tournament: false,

        tournament_dates: undefined,
        tournament_members: [],
        tournament_teams: [],
        tournament_players: [],
        tournament_phases: [], 
        is_loaded_tournament_phases: false, 
        is_loaded_tournament_dates: false, 
        loaded_tournament_phases: false,
        phase_groups_results_table: [],
        phase_results_table: [],        
        tournament_active_phase: undefined,
        tournament_active_phase_teams: undefined,
        active_phase_groups_create: true,
        teams_classified_by_phases: [],
        unfinished_matches: {},
        unfinished_tournament_matches: false,
        tournament_scorer_team_members: [],
        tournament_beaten_fence_team_members: [],
        tournament_champion_team_members: [],
        posts: [],
        active_phase_fixture_resume: undefined,
        invited_tournament_teams: [],
        tournament_team_players: [],
        tournament_setup_teams: [],
        tournament_criteria_phase: [],
        fields_status_editing:{},
        tournament_day:[],
        tournament_timeday:[],
        tournament_filter_phase_teams:[],
        tournament_successful_phase_edition: false,
        tournament_forms_ids_template:[],
        tournament_members_team_players: [],
        tournament_staff: [],
        referee_fee_value: undefined,
        inter_groups_teams: undefined,
        number_unassigned_teams: 0,
        is_phase_finalized: undefined,
        tournament_active_phase_teams_modal: [],
        full_response_team_members: false,
        teams_match_assistance: [],
        inactive_players:0,
        active_players: 0,
        team_total_values_stats: 0,
        tournament_revelation_players_team_members: [],
        tournament_player_team_members: [],
        tournament_third_placer_team_members: [],
        tournament_runner_up_team_members: [],
        player_form_template: [],
        list_existing_form:[],
        carnet_id_template: undefined,
        card_id_template_image: null,
        card_id_orientation: null,
        card_id_form_info: null,
        list_coaches_staff:[],
    }, 
    league_tournaments_is_loading: false,  
    date_matches_is_loading: false,   
    league_tournaments: [],
    world_cities: [],
    countries:[],
    countryId:'',
    countryCurrency:undefined,
    modals: {
        end_tournament_is_open: false,            
        match_is_open: false,
        end_match_is_open: false,
        matchs_to_close_is_open: false,
        warning_leving_phase_is_open: false,
        finish_free_phase_is_open: false,
        finish_groups_phase_is_open: false,
        teams_disabled_dialog_is_open: false,
        tournament_save_tournament_is_open: false,
        warning_league_with_no_teams_is_open: false,
        add_team_to_group_is_open: false,
        remove_multiple_phases_is_open: false,
        delete_date_is_open: false,
        close_match_is_open: false,
        tournament_finalized_suggestion_is_open: false,
        phase_roundtrip_fixture_modal: false,
        add_team_advice: false,
        team_detail_is_open: false,
        start_tournament_modal: false,
        invite_team_to_tournament_modal: false,
        team_inscription_modal: false,
        invitation_inscription_actions_modal: false,
        view_form_data_modal: false,
        payment_information_modal: false,
        tournament_dynamic_forms_modal: false,
        tournament_start_info_modal: false,
        tournament_transfer_player_modal: false,
        add_staff_modal: false,
        edit_phase_modal: false,
        edit_phase_validation_modal: false,
        reject_multiple_players_modal: false,
        form_existing_is_open: false,
        card_id_validation_modal: false,
        card_id_delete_validation_modal: false,
        card_id_teams_select_modal: false,
        card_id_pdf_generator_modal: false,
        card_id_exit_validation_modal: false,
        page_fields_admin_modal: false,
        page_fields_create_modal: false,
        page_fields_warning_modal: false,
        tournament_fields_warning_modal: false,
        tournament_fields_admin_modal: false,
        tournament_add_existing_fields: false,
        fixture_matches_available_dates: false,
        fixture_matches_block_dates: false,
        fixture_add_team_to_field: false,
        fixture_fields_matches_available_dates: false,
        fixture_fields_matches_block_dates: false,
        accept_automatic_fixture: false,
        match_report_pdf: false,
        view_more_statistics_is_open: false,
    },
    phase_name_values: [
        { 
            value: "",
            content: "",
        }
    ],
    errors: {
        tournament_basic_data:{
            name: '',
            category: '',
            main_country: '',
            main_city: '',
            start_date: '',
            end_date: '',
            description: '',
            tournament_gender: '',
            rules: '',
            football_type: '',
            tournament_type:'',
        },
        new_match:{
            local_team: '',
            visitant_team: '',
            match_date: '',
            match_time: '',
            field: '',
            persons: '',
            match_city: '',
        },
        scoreboard_match:{
            local_scoreboard: '',
            visitant_scoreboard: '',
            local_team_penalty_score: '',
            visitant_team_penalty_score: '',
        },
        comment_match:{
            comment: '',
        },
        end_tournament:{
            champion: '',
            runner_up: '',
            third_place: '',
            scorer: '',
            least_beaten_fence: '',
            champion_coach: '', 
        },
        edit_match:{
            local_team: '',
            visitant_team: '',
            match_date: '',
            match_time: '',
            field: '',
            persons: '',
            match_city: '',
            match_date_time: '',
        },
        new_tournament_groups:{
            number_of_groups: '',
            best_third: '',
            best_third_teams: '',
            clasified_by_group: '',
            groups_teams: [],
            phase_name: '',
            match_duration:''
        
        },
        new_tournament_inscription:{
            bank_account: '',
            material_prize: '',
            max_participant_players: '',
            max_participant_teams: '',
            min_participant_players: '',
            min_participant_teams: '',
            monetary_prize: '',
            registration_deadline: '',
            registration_price_per_player: '',
            registration_price_per_team: ''
        },
        list_tournament_filters :{
            football_type: '',
            tournament_type: '',
            tournament_category: '',
            tournament_gender: '',
            start_date: '',
            end_date: '',
            modality: '',
            console: '',
            video_game: '',
            game_mode: '',
            main_city: '',
            main_country: ''
        },
        player_inscription_form:{
            nombres: '',
            apellidos: '',
            file_registro_ti:"",
            file_registro_civil:"",
            file_autorizacion:"",
            file_foto:"",
            file_firma:"",
            file_huella:"",
            first_name_player: "",
            middle_name_player: "",
            last_name_player: "",
            email_player: "",
            date_birth: "",
            file_photo_player:"",
            file_photo_player_base64:"",
            phone_player: "",
            best_third:"",
            date_last_game: "",
            address_player:"",
            address_player_2:"",
            parent_firts_name:"",
            parent_last_name:"",
            parent_cell_phone:"",
            parent_home_phone:"",
            parent_email:"",
            last_foreign_club_participated:"",
            country_foreign_club:"",
            league_foreign_club:"",
            city:"",
            country:"",
            postal_code:"",
            country_birth:"",
            country_citizenship:"",
            state_province:"",
            parent_firts_name_2:"",
            parent_last_name_2:"",
            parent_cell_phone_2:"",
            parent_home_phone_2:"",
            parent_email_2:"",
            team_nsl_participate:"",
            country_participate:"",
            category_participate:"",
            last_participation_team:"",
            check1: "",
            check2: "",
            check3: "",
            team_name: "", 
            team_mail: "", 
            team_address: "", 
            team_country: "", 
            team_city: "", 
            team_state_province: "", 
            team_postal_code: "", 
            team_phone: "", 
            team_firts_name_manager: "", 
            team_last_name_manager: "", 
            team_second_firts_name_manager: "", 
            team_second_last_name_manager: "", 
            team_second_mail: "",
            team_second_phone: "", 
            team_conference: "",
            team_division: "", 
            team_area: "", 
            team_league: "",
            team_name_col: "", 
            team_country_col: "", 
            team_city_col: "", 
            team_recognition_number: "", 
            team_affiliation_number: "", 
            team_club_number: ""
        },
        create_field: {
            name: "",
            address: "",
        }

    },
    selected_date: {
        id: undefined,
        name: undefined,
        tournament: undefined,
        matches: [],
        next_date_matches: null,
    },
    // end_tournament_error: {
    //     champion_team: '',
    //     goal_scorer: '',
    //     subchampion_team: '', 
    //     less_goals: '',
    //     third_place: '', 
    //     champion_coach: '',      
    // },
    new_tournament_data: {
        basic_data: {},
        my_teams: [],
        added_teams: [],
    },
    tournament_members: [],
    baloa_teams: [],
    baloa_members: [],
    tournament_results: {
        tournament_dates: [],
        selected_date_matches: []
    },
    selected_form_data: undefined,
    disabled_submit_button: false,
    baloa_users: [],
    refereeing_payment_url: undefined,
    items_tournament_type:[],
    preselected_page_team_player_form_template: undefined,
    start_cards_id_component: false,
    card_ids_data: [],
    page_fields: [],
    new_page_field: {},
    tournament_fields: [],
    tournament_available_fields: [],
    fixture_available_days: [],
    grouped_fixture_available_days: [],
    fixture_locked_days: [],
    fixture_field_teams: [],
    full_fixture_config: undefined,
    fields_fixture_available_days: [],
    fields_fixture_locked_days: [],
    tournament_dates_filter: [],
    next_tournament_teams: null,
    tournament_teams_loading: false,
    tournament_teams_count: 0,
    next_tournament_team_players: null,
    loading_phase_group_positions_table: false,
    tournament_scorers: [],
    tournament_yellow_cards: [],
    tournament_red_cards: [],
    loading_tournament_scorers: false,
    loading_tournament_yellow_cards: false,
    loading_tournament_red_cards: false,
    next_tournament_scorers: null,
    next_tournament_yellow_cards: null,
    next_tournament_red_cards: null,
}

const tournament_initial_state = {
    category: undefined,
    champion: undefined,
    champion_coach: null,
    champion_coach_name: null,
    champion_coach_photo: null,
    champion_name: undefined,
    champion_page_owner: undefined,
    champion_photo: undefined,
    count_teams: undefined,
    currency_payment: undefined,
    description: undefined,
    end_date: undefined,
    id: undefined,
    league_page: undefined,
    least_beaten_fence: undefined,
    least_beaten_fence_name: undefined,
    least_beaten_fence_photo: undefined,
    main_city: undefined,
    main_country: undefined,
    material_prize: undefined,
    max_participant_players: undefined,
    max_participant_teams: undefined,
    min_participant_players: undefined,
    min_participant_teams: undefined,
    monetary_prize: undefined,
    name: undefined,
    organizer: undefined,
    registration_deadline: undefined,
    registration_price_per_player: undefined,
    registration_price_per_team: undefined,
    revelation_player: undefined,
    revelation_player_name: undefined,
    revelation_player_photo: undefined,
    rules: undefined,
    runner_up: undefined,
    runner_up_coach: undefined,
    runner_up_coach_name: undefined,
    runner_up_coach_photo: undefined,
    runner_up_name: undefined,
    runner_up_page_owner: undefined,
    runner_up_photo: undefined,
    scorer: undefined,
    scorer_name: undefined,
    scorer_photo: undefined,
    start_date: undefined,
    state: undefined,
    team_least_beaten_fence: undefined,
    team_least_beaten_fence_page_owner: undefined,
    team_least_beaten_fence_photo: undefined,
    team_name_least_beaten_fence: undefined,
    team_name_scorer: undefined,
    team_revelation_player: undefined,
    team_revelation_player_name: undefined,
    team_revelation_player_photo: undefined,
    team_scorer: undefined,
    team_scorer_page_owner: undefined,
    team_scorer_photo: undefined,
    team_tournament_player: undefined,
    team_tournament_player_name: undefined,
    team_tournament_player_photo: undefined,
    third_place: undefined,
    third_place_coach: undefined,
    third_place_coach_name: undefined,
    third_place_coach_photo: undefined,
    third_place_name: undefined,
    third_place_page_owner: undefined,
    third_place_photo: undefined,
    total_goals_scorer: undefined,
    total_goals_conceded: undefined,
    tournament_gender: undefined,
    tournament_logo: undefined,
    tournament_photo: undefined,
    tournament_player: undefined,
    tournament_player_name: undefined,
    tournament_player_photo: undefined,
    tournament_prize_type: undefined,
    tournament_referee_payment_config: [],
    tournament_registration_deadline: undefined,
    tournament_registration_pay: undefined,
    tournament_registration_type: undefined,
    tournament_summon_registration: undefined,
    tournament_tag: undefined,
    tournament_type: undefined,
    unlimited_participant_players: false,
    various_categories: false,
    various_editions: false,
    football_type: undefined,
    start_tournament: false,

    tournament_dates: undefined,
    tournament_members: [],
    tournament_teams: [],
    tournament_players: [],
    tournament_phases: [], 
    is_loaded_tournament_phases: false, 
    is_loaded_tournament_dates: false, 
    loaded_tournament_phases: false,              
    phase_results_table: [],        
    tournament_active_phase: undefined,
    tournament_active_phase_teams: undefined,
    active_phase_groups_create: true,
    teams_classified_by_phases: [],
    unfinished_matches: {},
    unfinished_tournament_matches: false,
    tournament_scorer_team_members: [],
    tournament_beaten_fence_team_members: [],
    tournament_champion_team_members: [],
    posts: [],
    active_phase_fixture_resume: undefined,
    invited_tournament_teams: [],
    tournament_team_players: [],
    tournament_setup_teams: [],
    tournament_criteria_phase: undefined,
    fields_status_editing:{},
    tournament_successful_phase_edition: false,
    tournament_members_team_players: [],
    tournament_staff: [],
    inter_groups_teams: undefined,
    number_unassigned_teams: 0,
    full_response_team_members:false,
    teams_match_assistance: [],
    inactive_players:0,
    active_players: 0,
    team_total_values_stats: 0,
    tournament_revelation_players_team_members: [],
    tournament_player_team_members: [],
    tournament_third_placer_team_members: [],
    tournament_runner_up_team_members: [],
    list_existing_form:[],
}

export default function LeagueTournamentReducer( state = tournament_state, action ) {

    switch ( action.type ) {

        /*case "TOURNAMENT:LOAD_DATES": {

            let load_tournament_dates = { ...state['tournament'], tournament_dates: action.data.data.results }
            return { ...state, tournament: load_tournament_dates}

        }*/

        case "TOURNAMENT:LOAD_GROUP_DATES": {
 
            let load_tournament_dates = { ...state['tournament'], tournament_dates: action.data.data.results }
            return { ...state, tournament: load_tournament_dates, selected_date: undefined}

        }

        case "TOURNAMENT:IS_LOADED_GROUP_DATES": {

            let is_loaded_tournament_dates = { ...state['tournament'], is_loaded_tournament_dates: action.data }
            return { ...state, tournament: is_loaded_tournament_dates }

        }

        case "TOURNAMENT:LOAD_LEAGUE_TOURNAMENTES": {

            return { ...state, league_tournaments: action.data.data.results}

        }

        case "TOURNAMENT:CHANGE_BASIC_DATA_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }
        case "TOURNAMENT:CHANGE_NEW_MATCH_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }

        case "TOURNAMENT:CHANGE_NEW_MATCH_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], match_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:ADD_DATE": {

            let new_tournament_dates = { ...state['tournament'], tournament_dates: [...state['tournament'].tournament_dates, action.data.data]}
            return { ...state, tournament: new_tournament_dates}

        }

        case "TOURNAMENT:LOAD_DATE_MATCHES": {

            let new_date_matches = null;
            if(action.data.data.previous && state.selected_date.next_date_matches !== action.data.data.next ){
                new_date_matches = state.selected_date.matches.concat( action.data.data.results );
            }else{
                new_date_matches = action.data.data.results;
            }

            let new_selected_date_state = { ...state['selected_date'], matches: new_date_matches, next_date_matches: action.data.data.next }
            //return { ...state, tournament: load_tournament_teams, next_tournament_teams: action.data.data.next, tournament_teams_count: action.data.data.count }


            //let new_selected_date_state = { ...state['selected_date'], matches: action.data.data.results }
            return { ...state, selected_date: new_selected_date_state}

        }

        case "TOURNAMENT:SET_SELECTED_DATE": {

            
            let new_selected_date_state = { ...state['selected_date'], id: action.selected_date?.id, name: action.selected_date?.name, tournament: action.selected_date?.tournament }
            return { ...state, selected_date: new_selected_date_state}

        }

        case "TOURNAMENT:ADD_MEMBER": {

            //return { ...state, tournament_members: [...state.tournament_members, action.data.data]}
            let new_tournament_member = { ...state['tournament'], tournament_members: [...state['tournament'].tournament_members, action.data.data]}
            return { ...state, tournament: new_tournament_member}

        }

        case "TOURNAMENT:CHANGE_END_MATCH_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], end_match_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_WARNING_LEAGUE_WITH_NO_TEAMS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], warning_league_with_no_teams_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        /* eslint-disable */
        case "TOURNAMENT:CHANGE_END_MATCH_COMMMENT_ERROR": {
            const errorData = state.errors; 
        }
        

        case "TOURNAMENT:CHANGE_END_MATCH_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }
        /* eslint-enable */

        case "TOURNAMENT:END_TOURNAMENT_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], end_tournament_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }


        case "TOURNAMENT:LOAD_TOURNAMENT": {

            
            let load_tournament = { ...state['tournament'], category: action.data.data.category, champion: action.data.data.champion, champion_coach: action.data.data.champion_coach,
            champion_coach_name: action.data.data.champion_coach_name, champion_coach_photo: action.data.data.champion_coach_photo, champion_name: action.data.data.champion_name,
            champion_page_owner: action.data.data.champion_page_owner, champion_photo: action.data.data.champion_photo, count_teams: action.data.data.count_teams, currency_payment: action.data.data.currency_payment, description: action.data.data.description,
            end_date: action.data.data.end_date, id: action.data.data.id, league_page: action.data.data.league_page, least_beaten_fence: action.data.data.least_beaten_fence,
            least_beaten_fence_name: action.data.data.least_beaten_fence_name, team_least_beaten_fence_page_owner: action.data.data.team_least_beaten_fence_page_owner, least_beaten_fence_photo: action.data.data.least_beaten_fence_photo, main_city: action.data.data.main_city,
            main_country: action.data.data.main_country, material_prize: action.data.data.material_prize, max_participant_players: action.data.data.max_participant_players,
            max_participant_teams: action.data.data.max_participant_teams, min_participant_players: action.data.data.min_participant_players ,min_participant_teams: action.data.data.min_participant_teams,
            monetary_prize: action.data.data.monetary_prize, name: action.data.data.name, organizer: action.data.data.organizer, registration_deadline: action.data.data.registration_deadline,
            registration_price_per_player: action.data.data.registration_price_per_player,registration_price_per_team: action.data.data.registration_price_per_team,
            revelation_player: action.data.data.revelation_player, revelation_player_name: action.data.data.revelation_player_name, revelation_player_photo: action.data.data.revelation_player_photo, rules: action.data.data.rules,
            runner_up: action.data.data.runner_up, runner_up_coach: action.data.data.runner_up_coach, runner_up_coach_name: action.data.data.runner_up_coach_name, runner_up_coach_photo: action.data.data.runner_up_coach_photo,
            runner_up_name: action.data.data.runner_up_name, runner_up_page_owner: action.data.data.runner_up_page_owner, runner_up_photo: action.data.data.runner_up_photo, 
            scorer: action.data.data.scorer, scorer_name: action.data.data.scorer_name, scorer_photo: action.data.data.scorer_photo, start_date: action.data.data.start_date, 
            state: action.data.data.state, team_least_beaten_fence: action.data.data.team_least_beaten_fence, team_least_beaten_fence_photo: action.data.data.team_least_beaten_fence_photo, team_name_least_beaten_fence: action.data.data.team_name_least_beaten_fence,
            team_name_scorer: action.data.data.team_name_scorer, team_revelation_player: action.data.data.team_revelation_player, team_revelation_player_name: action.data.data.team_revelation_player_name, team_revelation_player_photo: action.data.data.team_revelation_player_photo,
            team_scorer: action.data.data.team_scorer, team_scorer_page_owner: action.data.data.team_scorer_page_owner, team_scorer_photo: action.data.data.team_scorer_photo, 
            team_tournament_player: action.data.data.team_tournament_player, team_tournament_player_name: action.data.data.team_tournament_player_name, team_tournament_player_photo: action.data.data.team_tournament_player_photo,
            third_place: action.data.data.third_place, third_place_coach: action.data.data.third_place_coach, third_place_coach_name: action.data.data.third_place_coach_name, third_place_coach_photo: action.data.data.third_place_coach_photo,
            third_place_name: action.data.data.third_place_name, third_place_page_owner: action.data.data.third_place_page_owner, third_place_photo: action.data.data.third_place_photo, total_goals_scorer: action.data.data.total_goals_scorer, total_goals_conceded: action.data.data.total_goals_conceded,
            tournament_gender: action.data.data.tournament_gender, tournament_photo: action.data.data.tournament_photo, tournament_player: action.data.data.tournament_player, tournament_player_name: action.data.data.tournament_player_name, tournament_player_photo: action.data.data.tournament_player_photo,
            tournament_prize_type: action.data.data.tournament_prize_type, tournament_referee_payment_config: action.data.data.tournament_referee_payment_config, tournament_registration_deadline: action.data.data.tournament_registration_deadline, tournament_registration_pay: action.data.data.tournament_registration_pay, tournament_registration_type: action.data.data.tournament_registration_type,
            tournament_summon_registration: action.data.data.tournament_summon_registration, tournament_tag: action.data.data.tournament_tag, tournament_type: action.data.data.tournament_type,
            unlimited_participant_players: action.data.data.unlimited_participant_players, various_categories: action.data.data.various_categories, various_editions: action.data.data.various_editions, 
            football_type: action.data.data.football_type, tournament_logo: action.data.data.tournament_logo, has_player_form: action.data.data.has_player_form, has_team_form: action.data.data.has_team_form, start_tournament: action.data.data.start_tournament,
            tournament_timeday: action.data.data.tournament_timeday, tournament_day: action.data.data.tournament_day,
            active_players: action.data.data.active_players, inactive_players: action.data.data.inactive_players, team_total_values_stats: action.data.data.team_total_values_stats}
            return { ...state, tournament: load_tournament}
        } 

        case "TOURNAMENT:CHANGE_END_TOURNAMENT_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }
        
        case "TOURNAMENT:ADD_TOURNAMENT_TEAM": {

            let new_tournament_teams = { ...state['tournament'], tournament_teams: [...state['tournament'].tournament_teams, action.data.data]}
            return { ...state, tournament: new_tournament_teams}

        }

        case "TOURNAMENT:ADD_TOURNAMENT_INVITED_TEAM":{
            let invited_tournament = { ...state['tournament'], invited_tournament_teams:[...state['tournament'].invited_tournament_teams, action.data.data] }
            return { ...state, tournament: invited_tournament}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_TEAMS": {

            let new_tournament_teams = null;
            if(action.data.data.previous && state.next_tournament_teams !== action.data.data.next ){
                new_tournament_teams = state.tournament.tournament_teams.concat( action.data.data.results );
            }else{
                new_tournament_teams = action.data.data.results;
            }

            let load_tournament_teams = { ...state['tournament'], tournament_teams: new_tournament_teams }
            return { ...state, tournament: load_tournament_teams, next_tournament_teams: action.data.data.next, tournament_teams_count: action.data.data.count }

        }

        case "TOURNAMENT:LOAD_TOURNAMENT_MEMBERS": {

            let load_tournament_members = { ...state['tournament'], tournament_members: action.data.data.results }
            return { ...state, tournament: load_tournament_members }

        }

        case "TOURNAMENT:LOAD_TOURNAMENT_INFO": {
            let load_tournament = { ...state['tournament'], category: action.data.data.category, champion: action.data.data.champion, champion_coach: action.data.data.champion_coach,
            champion_coach_name: action.data.data.champion_coach_name, champion_coach_photo: action.data.data.champion_coach_photo, champion_name: action.data.data.champion_name,
            champion_page_owner: action.data.data.champion_page_owner, champion_photo: action.data.data.champion_photo, count_teams: action.data.data.count_teams, currency_payment: action.data.data.currency_payment, description: action.data.data.description,
            end_date: action.data.data.end_date, id: action.data.data.id, league_page: action.data.data.league_page, least_beaten_fence: action.data.data.least_beaten_fence,
            least_beaten_fence_name: action.data.data.least_beaten_fence_name, team_least_beaten_fence_page_owner: action.data.data.team_least_beaten_fence_page_owner, least_beaten_fence_photo: action.data.data.least_beaten_fence_photo, main_city: action.data.data.main_city,
            main_country: action.data.data.main_country, material_prize: action.data.data.material_prize, max_participant_players: action.data.data.max_participant_players,
            max_participant_teams: action.data.data.max_participant_teams, min_participant_players: action.data.data.min_participant_players ,min_participant_teams: action.data.data.min_participant_teams,
            monetary_prize: action.data.data.monetary_prize, name: action.data.data.name, organizer: action.data.data.organizer, referee_payments: action.data.data.referee_payments, registration_deadline: action.data.data.registration_deadline,
            registration_price_per_player: action.data.data.registration_price_per_player,registration_price_per_team: action.data.data.registration_price_per_team,
            revelation_player: action.data.data.revelation_player, revelation_player_name: action.data.data.revelation_player_name, revelation_player_photo: action.data.data.revelation_player_photo, rules: action.data.data.rules,
            runner_up: action.data.data.runner_up, runner_up_coach: action.data.data.runner_up_coach, runner_up_coach_name: action.data.data.runner_up_coach_name, runner_up_coach_photo: action.data.data.runner_up_coach_photo,
            runner_up_name: action.data.data.runner_up_name, runner_up_page_owner: action.data.data.runner_up_page_owner, runner_up_photo: action.data.data.runner_up_photo, 
            scorer: action.data.data.scorer, scorer_name: action.data.data.scorer_name, scorer_photo: action.data.data.scorer_photo, start_date: action.data.data.start_date, 
            state: action.data.data.state, team_least_beaten_fence: action.data.data.team_least_beaten_fence, team_least_beaten_fence_photo: action.data.data.team_least_beaten_fence_photo, team_name_least_beaten_fence: action.data.data.team_name_least_beaten_fence,
            team_name_scorer: action.data.data.team_name_scorer, team_revelation_player: action.data.data.team_revelation_player, team_revelation_player_name: action.data.data.team_revelation_player_name, team_revelation_player_photo: action.data.data.team_revelation_player_photo,
            team_scorer: action.data.data.team_scorer, team_scorer_page_owner: action.data.data.team_scorer_page_owner, team_scorer_photo: action.data.data.team_scorer_photo, 
            team_tournament_player: action.data.data.team_tournament_player, team_tournament_player_name: action.data.data.team_tournament_player_name, team_tournament_player_photo: action.data.data.team_tournament_player_photo,
            third_place: action.data.data.third_place, third_place_coach: action.data.data.third_place_coach, third_place_coach_name: action.data.data.third_place_coach_name, third_place_coach_photo: action.data.data.third_place_coach_photo,
            third_place_name: action.data.data.third_place_name, third_place_page_owner: action.data.data.third_place_page_owner, third_place_photo: action.data.data.third_place_photo, total_goals_scorer: action.data.data.total_goals_scorer, total_goals_conceded: action.data.data.total_goals_conceded,
            tournament_gender: action.data.data.tournament_gender, tournament_photo: action.data.data.tournament_photo, tournament_player: action.data.data.tournament_player, tournament_player_name: action.data.data.tournament_player_name, tournament_player_photo: action.data.data.tournament_player_photo,
            tournament_prize_type: action.data.data.tournament_prize_type, tournament_referee_payment_config: action.data.data.tournament_referee_payment_config, tournament_registration_deadline: action.data.data.tournament_registration_deadline, tournament_registration_pay: action.data.data.tournament_registration_pay, tournament_registration_type: action.data.data.tournament_registration_type,
            tournament_summon_registration: action.data.data.tournament_summon_registration, tournament_tag: action.data.data.tournament_tag, tournament_type: action.data.data.tournament_type,
            unlimited_participant_players: action.data.data.unlimited_participant_players, various_categories: action.data.data.various_categories, various_editions: action.data.data.various_editions, 
            football_type: action.data.data.football_type, tournament_logo: action.data.data.tournament_logo, has_player_form: action.data.data.has_player_form, has_team_form: action.data.data.has_team_form, start_tournament: action.data.data.start_tournament,
            tournament_timeday: action.data.data.tournament_timeday, tournament_day: action.data.data.tournament_day,
            active_players: action.data.data.active_players, inactive_players: action.data.data.inactive_players,team_total_values_stats: action.data.data.team_total_values_stats}
            return { ...state, tournament: load_tournament}
            
        }

        case "TOURNAMENT:DELETE_TEAM_FROM_TOURNAMENT": {
            let new_tournament_teams = { ...state['tournament'], tournament_teams: state['tournament'].tournament_teams.filter(item => item.id != action.data) }
            return { ...state, tournament: new_tournament_teams }

        }

        case "TOURNAMENT:DISABLE_TEAM_FROM_TOURNAMENT": {

            let new_tournament_teams = { ...state.tournament, tournament_teams: state.tournament.tournament_teams.map(team =>
                team.id == action.data.data.id ? action.data.data : team
            )}

            return { ...state, tournament: new_tournament_teams }

        }

        case "TOURNAMENT:DELETE_MEMBER_FROM_TOURNAMENT": {

            let new_tournament_members = { ...state['tournament'], tournament_members: state['tournament'].tournament_members.filter(item => item.id != action.data) }
            return { ...state, tournament: new_tournament_members }

        }

        case "TOURNAMENT:LOAD_RESULTS_TABLE": {

            let tournament_results_table = { ...state['tournament'], tournament_results_table: action.data.data }
            return { ...state, tournament: tournament_results_table }

        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PHASES": {

            let tournament_phases = { ...state['tournament'], tournament_phases: action.data.data.results }
            return { ...state, tournament: tournament_phases }

        }

        case "TOURNAMENT:LOADEDED_TOURNAMENT_PHASES": {

            let loaded_tournament_phases = { ...state['tournament'], loaded_tournament_phases: action.data }
            return { ...state, tournament: loaded_tournament_phases }

        }

        case "TOURNAMENT:IS_LOADED_TOURNAMENT_PHASES": {

            let is_loaded_tournament_phases = { ...state['tournament'], is_loaded_tournament_phases: action.data }
            return { ...state, tournament: is_loaded_tournament_phases }

        }

        case "TOURNAMENT:LOAD_PHASE_GROUP_RESULTS_TABLE": {

            let new_phase_groups_results_table = { ...state['tournament'], phase_groups_results_table: action.data }
            return { ...state, tournament: new_phase_groups_results_table }

        }

        case "TOURNAMENT:LOAD_PHASE_GROUP_POSITION_TABLE": {

            let phase_results_table = { ...state['tournament'], phase_results_table: action.data.results }
            return { ...state, tournament: phase_results_table }

        }

        case "TOURNAMENT:RESET_GROUP_POSITION_TABLE": {

            let phase_results_table = { ...state['tournament'], phase_results_table: [] }
            return { ...state, tournament: phase_results_table }

        }

        case "TOURNAMENT:RESET_TOURNAMENT": {
            //return { ...state, tournament: {}}
            return { ...state, tournament: tournament_initial_state}
        }

        case "TOURNAMENT:RESET_MEMBERS_TOURNAMENT": {
            let new_tournament_members = { ...state['tournament'], tournament_members: []}
            return { ...state, tournament: new_tournament_members}
        }

        case "TOURNAMENT:RESET_TEAMS_TOURNAMENT": {
            let new_tournament_teams = { ...state['tournament'], tournament_teams: [], tournament_setup_teams: []}
            return { ...state, tournament: new_tournament_teams}
        }

        case "TOURNAMENT:RESET_ACTIVE_PHASE_TOURNAMENT": {
            let new_tournament_dates = { ...state['tournament'], tournament_active_phase: undefined}
            return { ...state, tournament: new_tournament_dates}
        }
        
        case "TOURNAMENT:RESET_PHASE_TEAMS_TOURNAMENT": {
            let new_phase_teams = { ...state['tournament'], tournament_active_phase_teams: undefined}
            return { ...state, tournament: new_phase_teams}
        }

        case "TOURNAMENT:RESET_DATES_TOURNAMENT": {
            let new_tournament_dates = { ...state['tournament'], tournament_dates: action.data? action.data: undefined}
            return { ...state, tournament: new_tournament_dates}
        }

        case "TOURNAMENT:RESET_MATCHES_TOURNAMENT": {

            let new_selected_date_state = { ...state['selected_date'], matches: [] }
            return { ...state, selected_date: new_selected_date_state}

        }

        case "TOURNAMENT:RESET_PHASES_TOURNAMENT": {

            let tournament_phases = { ...state['tournament'], tournament_phases: [] }
            return { ...state, tournament: tournament_phases }
        }
        
        case "TOURNAMENT:RESET_SELECTED_DATE": {

            let new_selected_date_state = { ...state['selected_date'], id: undefined, name: undefined, tournament: undefined, matches: [] }
            return { ...state, selected_date: new_selected_date_state}

        }

        case "TOURNAMENT:SEARCH_WORLD_CITIES": {
            return { ...state, world_cities: action.data?.data.results }
        }

        case "TOURNAMENT:GET_COUNTRIES": {
           return {...state, countries: action.data.data?.results, countryId: action.data.data?.results[0]?.id, countryCurrency: action.data.data.results[0].currency}
        }

        case "TOURNAMENT:GET_CITIES_BY_COUNTRY": {
           return {...state, world_cities: action.data?.data.cities}
        }

        case "TOURNAMENT:RESET_COUNTRY_CITIES": {
            return {...state, countries: [], countryId: '', countryCurrency: undefined, world_cities: []}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PLAYERS": {

            let load_tournament_players = { ...state['tournament'], tournament_players: action.data.data }
            return { ...state, tournament: load_tournament_players }

        }

        case "TOURNAMENT:LOAD_ADD_PHASE": {
            
            let load_phases = { ...state['tournament'], tournament_phases: [...state['tournament'].tournament_phases, action.data], tournament_active_phase: action.data}
            return { ...state, tournament: load_phases}

        }

        case "TOURNAMENT:SET_PHASE_TYPE_GROUPS": {

            let tournament_phases_mod = { ...state.tournament, tournament_phases: state.tournament.tournament_phases.map(phase =>
                phase.id == action.data.data.id ? action.data.data : phase
            ), tournament_active_phase: action.data.data}

            return { ...state, tournament: tournament_phases_mod}

        }

        case "TOURNAMENT:ADD_TEAM_TO_GROUP": {
  
            let group_index = state.tournament.tournament_active_phase.groups.findIndex(obj => obj.id === action.data.data.group_id);

            let tournament_phase_group = { ...state.tournament.tournament_active_phase.groups[group_index], teams:[...state.tournament.tournament_active_phase.groups[group_index].teams, action.data.data ]}

            let tournament_phase_mod = { ...state.tournament.tournament_active_phase, groups: state.tournament.tournament_active_phase.groups.map(groups =>
                groups.id == tournament_phase_group.id ? {...groups, teams: tournament_phase_group.teams } : groups
            )}
            
            let updated_tournament = { ...state['tournament'], tournament_phases: state.tournament.tournament_phases.map(phases =>
                phases.id == tournament_phase_mod.id ? { ...phases, groups: tournament_phase_mod.groups}: phases
                ), tournament_active_phase: tournament_phase_mod}
        
            return { ...state, tournament: updated_tournament }

        }

        case "TOURNAMENT:DELETE_TEAM_FROM_GROUP": {

            let group_index = state.tournament.tournament_active_phase.groups.findIndex(obj => obj.id === action.data.group_id);

            let tournament_phase_group = { ...state.tournament.tournament_active_phase.groups[group_index], teams: state.tournament.tournament_active_phase.groups[group_index].teams.filter( (team) => team.id != action.data.id)}

            let tournament_phase_mod = { ...state.tournament.tournament_active_phase, groups: state.tournament.tournament_active_phase.groups.map(groups =>
                groups.id == tournament_phase_group.id ? {...groups, teams: tournament_phase_group.teams } : groups
            )}

            let updated_tournament = { ...state['tournament'], tournament_phases: state.tournament.tournament_phases.map(phases =>
                phases.id == tournament_phase_mod.id ? { ...phases, groups: tournament_phase_mod.groups}: phases
                ), tournament_active_phase: tournament_phase_mod}

            return { ...state, tournament: updated_tournament }
            
        }
        
        case "TOURNAMENT:ACTIVE_PHASE_GROUPS_CREATE_STATE": {

            let new_active_phase_groups_create_state = { ...state['tournament'], active_phase_groups_create: action.new_state }
            return { ...state, tournament: new_active_phase_groups_create_state }

        }

        case "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE": { // This set made when some phase arrives from the view 'TournamentView'
            let set_phase = { ...state['tournament'], tournament_active_phase: action.data}
            return { ...state, tournament: set_phase }
        }

        case "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE_END_POINT": { // This set is only made when the response from back arrives
            let set_phase = { ...state['tournament'], tournament_active_phase: action.data.data}
            return { ...state, tournament: set_phase }

        }

        case "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE_EDIT": {
            let set_phase = { ...state['tournament'], tournament_active_phase: action.data}
            return { ...state, tournament: set_phase }

        }

        case "TOURNAMENT:CHANGE_MATCHS_TO_CLOSE_MODAL_STATE": {
             
            let new_modals_state = { ...state['modals'], matchs_to_close_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_WARNING_LEAVE_PHASE_MODAL_STATE": {
             
            let new_modals_state = { ...state['modals'], warning_leving_phase_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:CHANGE_TEAMS_DISABLED_DIALOG_MODAL_STATE": {
             
            let new_modals_state = { ...state['modals'], teams_disabled_dialog_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_FINISH_FREE_PHASE_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], finish_free_phase_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_FINISH_GROUPS_PHASE_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], finish_groups_phase_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:DELETE_PHASE": {
            let phases = state.tournament.tournament_phases.filter( (phase) => phase.id != action.data.id);
            let new_phases = { ...state['tournament'], tournament_phases: phases }
            return{ ...state, tournament: new_phases}
        }

        case "TOURNAMENT:TEAMS_CLASSIFIED_BY_PHASE":{
            let teams = { ...state['tournament'], teams_classified_by_phases: action.data , unfinished_matches: {}}
            return { ...state, tournament: teams }
        }

        case "TOURNAMENT: UNFINISHED_MATCHES":{
            let matches = { ...state['tournament'], unfinished_matches: action.data , teams_classified_by_phases: []}
            return { ...state, tournament: matches }
        }

        case "TOURNAMENT: FINISH_TOURNAMENT_PHASE": {
            let phases =  state.tournament.tournament_phases.map( (phase) => phase.id == action.data.id ? 
                {...phase, state: action.data.state} : {...phase, tournament_phases: phase}
            );
            let new_phases = { ...state['tournament'], tournament_phases: phases }
               
            return{ ...state, tournament: new_phases}
        }
        
        case "TOURNAMENT:LOAD_TOURNAMENT_PHASE_TEAMS": {
            let set_phase_teams = { ...state['tournament'], tournament_active_phase_teams: action.data.data.results }
            return { ...state, tournament: set_phase_teams }
        }

        case "TOURNAMENT:CHANGE_LOADING_LEAGUE_TOURNAMENTS": {            
            return { ...state, league_tournaments_is_loading: action.data }
        }

        case "TOURNAMENT:CHANGE_LOADING_DATE_MATCHES": {            
            return { ...state, date_matches_is_loading: action.data }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_SAVE_TOURNAMENT_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], tournament_save_tournament_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_ADD_TEAM_TO_GROUP_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], add_team_to_group_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_REMOVE_MULTIPLE_PHASES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], remove_multiple_phases_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_UNFINISHED_TOURNAMENT_MATCHES_STATE": {
             
            let unfinished_tournament_matches_state = { ...state['tournament'], unfinished_tournament_matches: action.new_state }
            return { ...state, tournament: unfinished_tournament_matches_state }
 
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_SCORER_TEAM_MEMBERS": {
            let set_scorer_members = { ...state['tournament'], tournament_scorer_team_members: action.data.data.results }
            return { ...state, tournament: set_scorer_members }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_BEATEN_FENCE_TEAM_MEMBERS": {
            let set_beaten_fence_members = { ...state['tournament'], tournament_beaten_fence_team_members: action.data.data.results }
            return { ...state, tournament: set_beaten_fence_members }
        }

        case "TOURNAMENT:DELETE_DATE": {

            let dates = state.tournament.tournament_dates.filter( (date) => date.id != action.data);
            let new_dates = { ...state['tournament'], tournament_dates: dates }
            return{ ...state, tournament: new_dates}
        }

        case "TOURNAMENT:CHANGE_DELETE_DATE_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], delete_date_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_CLOSE_MATCH_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], close_match_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:LOAD_TOURNAMENT_CHAMPION_TEAM_MEMBERS": {
            let set_champion_members = { ...state['tournament'], tournament_champion_team_members: action.data.data.results }
            return { ...state, tournament: set_champion_members }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_POSTS": {
            let tournament_posts = {...state['tournament'], posts: action.data.data}
            return { ...state, tournament: tournament_posts }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_FINALIZED_SUGGESTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_finalized_suggestion_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:SEARCHED_BALOA_TEAMS": {
            return { ...state, baloa_teams: action.data?.data.results }
        }

        case "TOURNAMENT:CLEAN_BALOA_TEAMS": {
            return { ...state, baloa_teams: [] }
        }

        case "TOURNAMENT:LOAD_ACTIVE_PHASE_FIXTURE_RESUME": {
            let phase_fixture = {...state['tournament'], active_phase_fixture_resume: action.data.data}
            return { ...state, tournament: phase_fixture }
        }

        case "TOURNAMENT:CHANGE_ROUNDTRIP_FIXTURE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], phase_roundtrip_fixture_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:SEARCHED_BALOA_MEMBERS": {
            return { ...state, baloa_members: action.data?.data.results }
        }

        case "TOURNAMENT:CHANGE_ADD_TEAM_ADVICE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], add_team_advice: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:LOAD_PHASE_MATCHES_BY_DATE": {
            let selected_date_matches = { ...state['tournament_results'], selected_date_matches: action.data.data.results }
            return { ...state, tournament_results: selected_date_matches }
        }

        case "TOURNAMENT:LOAD_PHASE_DATES": {
            let tournament_dates = { ...state['tournament_results'], tournament_dates: action.data.data }
            return { ...state, tournament_results: tournament_dates }
        }

        case "TOURNAMENT:RESET_PHASE_DATES_MATCHES": {
            let tournament_dates_matches = { ...state['tournament_results'], tournament_dates: [] ,selected_date_matches: []}
            return { ...state, tournament_results: tournament_dates_matches }
        }

        case "TOURNAMENT:CHANGE_TEAM_DETAIL_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], team_detail_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_START_TOURNAMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], start_tournament_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_INVITE_TEAM_TO_TOURNAMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], invite_team_to_tournament_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_TEAM_INSCRIPTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], team_inscription_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        /* case "TOURNAMENT:CHANGE_TEAM_TOURNAMENT_STATE": {
            let team_index = state.tournament.tournament_setup_teams.findIndex( team => team.tournament_team_id === action.data.data.tournament_team_id );
            let team_tournament = { ...state.tournament.tournament_setup_teams[team_index], team_state: action.data.data.team_state }
            let updated_teams_tournament = { ...state['tournament'], tournament_setup_teams: state.tournament.tournament_setup_teams.map( teams =>
                teams.tournament_team_id == team_tournament.tournament_team_id? team_tournament: teams    
            )}
            return { ...state, tournament: updated_teams_tournament }
        } */

        case "TOURNAMENT:CHANGE_TEAM_TOURNAMENT_STATE": {
            let team_index = state.tournament.tournament_teams.findIndex( team => team.tournament_team_id === action.data.data.tournament_team_id );
            let team_tournament = { ...state.tournament.tournament_teams[team_index], team_state: action.data.data.team_state }
            let updated_teams_tournament = { ...state['tournament'], tournament_teams: state.tournament.tournament_teams.map( teams =>
                teams.tournament_team_id == team_tournament.tournament_team_id? team_tournament: teams    
            )}
            return { ...state, tournament: updated_teams_tournament }
        }

        case "TOURNAMENT:CHANGE_INVITATION_INSCRIPTION_ACTIONS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], invitation_inscription_actions_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }
        
        case "TOURNAMENT:LOAD_TOURNAMENT_TEAM_PLAYERS": {
            let new_tournament_team_players = null;
            if((action.data.previous !== null && state.next_tournament_team_players !== action.data.data.next) || action.data.data.previous){
                new_tournament_team_players = state.tournament.tournament_team_players.concat( action.data.data.results );
            }else if(!action.data.previous){
                new_tournament_team_players = action.data.data.results;
            }else{
                new_tournament_team_players = state.tournament.tournament_team_players;
            } 

            let load_tournament_team_players = { ...state['tournament'], tournament_team_players: new_tournament_team_players }
            return { ...state, tournament: load_tournament_team_players, next_tournament_team_players: action.data.data.next}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_MEMBERS_TEAM_PLAYERS":{
            let load_tournament_members_team_players = { ...state['tournament'], tournament_members_team_players: action.data.data }
            return { ...state, tournament: load_tournament_members_team_players}
        }
        
        case "TOURNAMENT:CHANGE_NEW_NAMES_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }

        /* case "TOURNAMENT:LOAD_TOURNAMENT_SETUP_TEAMS": {
            let load_tournament_setup_teams = { ...state['tournament'], tournament_setup_teams: action.data.data.results }
            return { ...state, tournament: load_tournament_setup_teams }
        } */

        case "TOURNAMENT:LOAD_TOURNAMENT_SETUP_TEAMS": {
            let load_tournament_setup_teams = { ...state['tournament'], tournament_teams: action.data.data.results }
            return { ...state, tournament: load_tournament_setup_teams }
        }

        case "TOURNAMENT:ADD_TOURNAMENT_SETUP_TEAM": {
            let new_tournament_setup_teams = { ...state['tournament'], tournament_setup_teams: [...state['tournament'].tournament_setup_teams, action.data.data]}
            return { ...state, tournament: new_tournament_setup_teams}
        }

        case "TOURNAMENT:DELETE_SETUP_TEAM_FROM_TOURNAMENT": {
            let new_tournament_setup_teams = { ...state['tournament'], tournament_setup_teams: state['tournament'].tournament_setup_teams.filter(item => item.id != action.data) }
            return { ...state, tournament: new_tournament_setup_teams }

        }

        case "TOURNAMENT:LOAD_FORM_DATA": {
  
            return { ...state, selected_form_data: action.data.data }

        }

        case "TOURNAMENT:CHANGE_INCRIPTION_VIEW_FORM_DATA_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], view_form_data_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:CHANGE_PAYMENT_INFORMATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_information_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:REMOVE_PLAYER_FROM_TEAM": {
            let new_team_players = state['tournament'].tournament_team_players.filter(player => player.id != action.data)
            
            let new_team_players_tournament = { ...state['tournament'], tournament_team_players: new_team_players }
            return { ...state, tournament: new_team_players_tournament }
        }

        case "TOURNAMENT:CHANGE_START_TOURNAMENT_STATE": {
            let new_state = { ...state['tournament'], start_tournament: action.new_state.start_tournament }
            return { ...state, tournament: new_state }
        }

        /* case "TOURNAMENT:UPDATE_TOURNAMENT_TEAM": {
            let team_index = state.tournament.tournament_setup_teams.findIndex( team => team.tournament_team_id === action.data.tournament_team_id );
            let team_tournament = { ...state.tournament.tournament_setup_teams[team_index], enrolled_players: action.data.team_enrolled_players }
            let updated_team_tournament = { ...state['tournament'], tournament_setup_teams: state.tournament.tournament_setup_teams.map( teams =>
                    teams.tournament_team_id == team_tournament.tournament_team_id? team_tournament: teams    
                )}
            return { ...state, tournament: updated_team_tournament }
        } */

        case "TOURNAMENT:UPDATE_TOURNAMENT_TEAM": {
            let team_index = state.tournament.tournament_teams.findIndex( team => team.tournament_team_id === action.data.tournament_team_id );
            let team_tournament = { ...state.tournament.tournament_teams[team_index], enrolled_players: action.data.team_enrolled_players }
            let updated_team_tournament = { ...state['tournament'], tournament_teams: state.tournament.tournament_teams.map( teams =>
                    teams.tournament_team_id == team_tournament.tournament_team_id? team_tournament: teams    
                )}
            return { ...state, tournament: updated_team_tournament }
        }

        case "TOURNAMENT:LOAD_CRITERIA_TOURNAMENT_PHASE": {
            let set_phase_criteria_teams = { ...state['tournament'], tournament_criteria_phase: action.data }
            return { ...state, tournament: set_phase_criteria_teams }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_DYNAMIC_FORMS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_dynamic_forms_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:UPDATE_TOURNAMENT_TEAM_PLAYERS": {
            let player_index = state.tournament.tournament_team_players.findIndex( player => player.id === action.data.player_id );
            
            let team_player = { ...state.tournament.tournament_team_players[player_index], is_following: action.data.is_following }
            
            let updated_team_players = state.tournament.tournament_team_players.map( player => player.id == team_player.id? team_player: player )
            return { ...state, tournament: updated_team_players }
        }

        case "TOURNAMENT:LOAD_FIELDS_STATUS_EDITING": {
            let set_fields_status_editing = { ...state['tournament'], fields_status_editing: action.data }
            return { ...state, tournament: set_fields_status_editing }
        }

        case "TOURNAMENT:DISABLE_NEW_TOURNAMENT_SUBMIT_BUTTON": {
            return { ...state, disabled_submit_button: action.new_state }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_START_INFO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_start_info_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PHASE_FILTER_TEAMS": {
            let set_phase_filter_teams = { ...state['tournament'], tournament_filter_phase_teams: action.data.data.results }
            return { ...state, tournament: set_phase_filter_teams }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_TRANSFER_PLAYER_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_transfer_player_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CODE_FORM_ID_TEMPLATE_STATE":{
            let code_form_id_template = {...state['tournament'], tournament_forms_ids_template: action.data}
            return {...state, tournament: code_form_id_template}
        }
        
        case "TOURNAMENT:STATE_SUCCESSFUL_PHASE_EDITION":{
            let set_state_phase_edition = { ...state['tournament'], tournament_successful_phase_edition: action.data }
            return { ...state, tournament: set_state_phase_edition }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_STAFF":{
            let load_tournament_staff = { ...state['tournament'], tournament_staff: action.data.data.results }
            return { ...state, tournament: load_tournament_staff }
        }

        case "TOURNAMENT:CHANGE_ADD_STAFF_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], add_staff_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:SEARCH_BALOA_USERS": {
            return { ...state, baloa_users: action.data?.data.results }
        }

        case "TOURNAMENT:CLEAN_BALOA_USERS": {
            return { ...state, baloa_users: [] }
        }

        case "TOURNAMENT:ADD_USER_TO_STAFF":{
            let new_staff_member = { ...state['tournament'], tournament_staff: [...state['tournament'].tournament_staff, action.data.data]}
            return { ...state, tournament: new_staff_member}
        }
        
        case "TOURNAMENT:UPDATE_STAFF_STATE":{
            let staff_index = state.tournament.tournament_staff.findIndex(obj => obj.id === action.data.data.id);
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    tournament_staff: state.tournament.tournament_staff.map((el, index) =>
                        index === staff_index ? { ...el, ...action.data.data } : el,
                    ),
                },
              };
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PHASE_INTERGROUPS_TEAMS": {
            let set_phase_teams = { ...state['tournament'], inter_groups_teams: action.data.data.results }
            return { ...state, tournament: set_phase_teams }
        }

        case "TOURNAMENT:DELETE_STAFF":{
            let staff = state.tournament.tournament_staff.filter( (member) => member.id != action.data);
            let new_staff = { ...state['tournament'], tournament_staff: staff }
            return{ ...state, tournament: new_staff}
        }

        case "TOURNAMENT:LOAD_REFEREEING_PAYMENT_URL": {
            return { ...state, refereeing_payment_url: action.data }
        }
        
        case "TOURNAMENT:CALCULATE_REFEREE_FEE": {
            let referee_fee_value = { ...state['tournament'], referee_fee_value: action.data.data.value }
            return { ...state, tournament: referee_fee_value }
        }

        case "TOURNAMENT:RESET_REFEREE_FEE": {
            let referee_fee_value = { ...state['tournament'], referee_fee_value: action.data}
            return { ...state, tournament: referee_fee_value }
        }

        case "TOURNAMENT:LOAD_PHASE_TEAMS_NOT_ASSIGNED": {
            let number_teams = action.data.data.results.length;
            let number_phase_teams = { ...state['tournament'], number_unassigned_teams: number_teams }
            return { ...state, tournament: number_phase_teams }
        }

        case "TOURNAMENT:CHANGE_EDIT_PHASE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], edit_phase_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_EDIT_PHASE_VALIDATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], edit_phase_validation_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_PHASE_FINALIZED_STATE": {
            let phase_finalized_state = { ...state['tournament'], is_phase_finalized: action.new_state}
            return { ...state, tournament: phase_finalized_state }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PHASE_TEAMS_MODAL": {
            let set_phase_teams_modal = { ...state['tournament'], tournament_active_phase_teams_modal: action.data.data.results }
            return { ...state, tournament: set_phase_teams_modal }
        }

        case "TOURNAMENT:LOAD_FULL_RESPONSE_TEAM_MEMBERS": {
            let set_full_response_teams_members = { ...state['tournament'], full_response_team_members: action.state }
            return { ...state, tournament: set_full_response_teams_members }
        }

        case "TOURNAMENT:SET_TEAMS_MATCH_ASSISTANCE_ALIGMENT": {
            let set_team_match_assistance = {...state['tournament'], teams_match_assistance: action.data.data}
            return { ...state, tournament: set_team_match_assistance }
        }

        case "TOURNAMENT:RESET_TEAMS_MATCH_ASSISTANCE_ALIGMENT": {
            let set_team_match_assistance = {...state['tournament'], teams_match_assistance: []}
            return { ...state, tournament: set_team_match_assistance }
        }

        case "TOURNAMENT:LOAD_REVELATION_PLAYERS_TEAM_MEMBERS": {
            let set_revelations_members = { ...state['tournament'], tournament_revelation_players_team_members: action.data.data.results }
            return { ...state, tournament: set_revelations_members }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_PLAYER_TEAM_MEMBERS": {
            let set_player_members = { ...state['tournament'], tournament_player_team_members: action.data.data.results }
            return { ...state, tournament: set_player_members }
        }

        case "TOURNAMENT:LOAD_THIRD_PLACE_TEAM_MEMBERS": {
            let set_third_place_members = { ...state['tournament'], tournament_third_placer_team_members: action.data.data.results }
            return { ...state, tournament: set_third_place_members }
        }

        case "TOURNAMENT:LOAD_RUNNER_UP_TEAM_MEMBERS": {
            let set_runner_up_members = { ...state['tournament'], tournament_runner_up_team_members: action.data.data.results }
            return { ...state, tournament: set_runner_up_members }
        }

        case "TOURNAMENT:SET_ITEMS_FOR_TOURNAMENT_TYPE":{
            return { ...state, items_tournament_type: action.data.data}
        }

        case "TOURNAMENT:CHANGE_REJECT_MULTIPLE_PLAYER_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], reject_multiple_players_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_FORMS_ACTION": {
            let set_form = { ...state['tournament'], player_form_template: action.data }
            return { ...state, tournament: set_form }
        }
        
        case "TOURNAMENT:LOAD_LIST_EXISTING_FORM": { 
            let set_existing_form = { ...state['tournament'], list_existing_form: action.data }
            return { ...state, tournament: set_existing_form }
        }

        case "TOURNAMENT:LOAD_PAGE_FORM_TEMPLATE": {
            return { ...state, preselected_page_team_player_form_template: action.data}
        }

        case "TOURNAMENT:CREATE_CARNET_FORM_TEMPLATE": {
            let set_form = { ...state['tournament'], carnet_id_template: action.data, card_id_template_image: action.templateImage, card_id_orientation: action.orientation }
            return { ...state, tournament: set_form }
        }

        case "TOURNAMENT:SAVE_CARD_ID_FORM_INFO": { 
            let set_card_id_form_info = { ...state['tournament'], card_id_form_info: action.data }
            return { ...state, tournament: set_card_id_form_info }
        }
        case "TOURNAMENT:CHANGE_CARD_ID_COMPONENT_STATE": {
            return { ...state, start_cards_id_component: action.new_state}
        }

        case "TOURNAMENT:CHANGE_CARD_ID_VALIDATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], card_id_validation_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CLEAN_CARD_ID_INFO": {
            let new_cleancard_id_state = { ...state['tournament'], player_form_template: [],card_id_form_info: null, card_id_template_image: null, card_id_orientation: null }
            return { ...state, tournament: new_cleancard_id_state }
        }

        case "TOURNAMENT:LOAD_CARD_ID_TEMPLATE": {
            let set_form = { ...state['tournament'], carnet_id_template: action.data }
            return { ...state, tournament: set_form }
        }

        case "TOURNAMENT:CHANGE_CARD_ID_DELETE_VALIDATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], card_id_delete_validation_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:DELETE_CARD_ID_TEMPLATE": {
            let new_cleancard_id_state = { ...state['tournament'], carnet_id_template: undefined }
            return { ...state, tournament: new_cleancard_id_state }
        }
        
        case "TOURNAMENT:CHANGE_CARD_ID_TEAMS_SELECT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], card_id_teams_select_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_CARD_IDS_DATA": {
            return { ...state, card_ids_data: action.data }
        }

        case "TOURNAMENT:CHANGE_CARD_ID_PDF_GENERATOR_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], card_id_pdf_generator_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }
        
        case "TOURNAMENT:CHANGE_CARD_ID_EXIT_VALIDATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], card_id_exit_validation_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:TEAM_MANAGER_AND_STAFF": {
            let new_list_staff = {...state['tournament'], list_coaches_staff: action.data.results }
            return { ...state, tournament: new_list_staff }
        }

        case "TOURNAMENT:CHANGE_CREATE_FIELD_DATA_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }
        }

        case "TOURNAMENT:CHANGE_FIELDS_ADMIN_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], page_fields_admin_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_FIELDS_CREATE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], page_fields_create_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_PAGE_FIELDS_DATA": {
            return { ...state, page_fields: action.data }
        }

        case "TOURNAMENT:CHANGE_FIELDS_WARNING_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], page_fields_warning_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_FIELDS_WARNING_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_fields_warning_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }
        
        case "TOURNAMENT:CHANGE_TOURNAMENT_FIELDS_ADMIN_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_fields_admin_modal: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:PAGE_FIELD_DATA": {
            return { ...state, new_page_field: action.data }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_FIELDS_DATA": {
            return { ...state, tournament_fields: action.data }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_ADD_EXISTING_FIELDS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], tournament_add_existing_fields: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_AVAILABLE_FIELDS_DATA": {
            return { ...state, tournament_available_fields: action.data }
        }

        case "TOURNAMENT:CHANGE_FIXTURE_MATCHES_AVAILABLE_DATES_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], fixture_matches_available_dates: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_FIXTURE_MATCHES_BLOCK_DATES_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], fixture_matches_block_dates: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:SET_FIXTURE_AVAILABLE_DAYS": {
            return { ...state, fixture_available_days: action.data }
        }

        case "TOURNAMENT:SET_GROUPED_FIXTURE_AVAILABLE_DAYS": {
            return { ...state, grouped_fixture_available_days: action.data }
        }

        case "TOURNAMENT:CLEAR_TOURNAMENT_AVAILABLE_FIXTURE_DATES": {
            return { ...state, fixture_available_days: [] }
        }

        case "TOURNAMENT:SET_FIXTURE_LOCKED_DAYS": {
            return { ...state, fixture_locked_days: action.data }
        }

        case "TOURNAMENT:SET_FIXTURE_FIELD_TEAMS": {
            return { ...state, fixture_field_teams: action.data }
        }

        case "TOURNAMENT:CHANGE_FIXTURE_ADD_TEAM_TO_FIELD_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], fixture_add_team_to_field: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_FULL_FIXTURE_CONFIG": {
            return { ...state, full_fixture_config: action.data }
        }

        /* case "TOURNAMENT:SET_FIXTURE_AVAILABLE_DAYS": {
            return { ...state, fields_fixture_available_days: action.data }
        } */
        case "TOURNAMENT:SET_GROUPED_FIXTURE_FIELDS_AVAILABLE_DAYS": {
            return { ...state, grouped_fields_fixture_available_days: action.data }
        }

        case "TOURNAMENT:CHANGE_FIXTURE_FIELDS_MATCHES_AVAILABLE_DATES_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], fixture_fields_matches_available_dates: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_FIXTURE_FIELDS_MATCHES_BLOCK_DATES_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], fixture_fields_matches_block_dates: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_ACCEPT_AUTOMATIC_FIXTURE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], accept_automatic_fixture: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_DATES_FILTER": { 
            return { ...state, tournament_dates_filter: action.data.data.results }
        }
        
        case "TOURNAMENT:DELETE_FILTER_DATE": {
            let new_dates = state.tournament_dates_filter.filter( (date) => date.id != action.data);
            return{ ...state, tournament_dates_filter: new_dates}
        }

        case "TOURNAMENT:CLEAN_TOURNAMENT_DATES_FILTER": { 
            return { ...state, tournament_dates_filter: [] }
        }

        case "TOURNAMENT:CLEAN_PAGE_FIELD_DATA": {
            return { ...state, new_page_field: {} }
        }


        case "TOURNAMENT:CHANGE_MATCH_REPORT_PDF_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], match_report_pdf: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_TEAMS_STATE": {
            return { ...state, tournament_teams_loading: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_PHASE_GROUP_POSITIONS_TABLE_STATE": {
            return { ...state, loading_phase_group_positions_table: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_SCORERS_TABLE_STATE": {
            return { ...state, loading_tournament_scorers: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMEN_YELLOW_CARDS_TABLE_STATE": {
            return { ...state, loading_tournament_yellow_cards: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_RED_CARDS_TABLE_STATE": {
            return { ...state, loading_tournament_red_cards: action.new_state}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_SCORERS_TABLE": { 
            let new_scorers = null;
            if(action.data.previous && state.next_tournament_scorers !== action.data.next ){
                new_scorers = state.tournament_scorers.concat( action.data.results );
            }else if(!action.data.previous){
                new_scorers = action.data.results;
            }else{
                new_scorers = state.tournament_scorers;
            }

            return { ...state, tournament_scorers: new_scorers, next_tournament_scorers: action.data.next }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_YELLOW_CARDS_TABLE": { 
            let new_yellow_cards = null;
            if(action.data.previous && state.next_tournament_yellow_cards !== action.data.next ){
                new_yellow_cards = state.tournament_yellow_cards.concat( action.data.results );
            }else if(!action.data.previous){
                new_yellow_cards = action.data.results;
            }else{
                new_yellow_cards = state.tournament_yellow_cards;
            } 
            
            return { ...state, tournament_yellow_cards: new_yellow_cards, next_tournament_yellow_cards: action.data.next }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_RED_CARDS_TABLE": { 
            let new_red_cards = null;
            if(action.data.previous && state.next_tournament_red_cards !== action.data.next ){
                new_red_cards = state.tournament_red_cards.concat( action.data.results );
            }else if(!action.data.previous){
                new_red_cards = action.data.results;
            }else{
                new_red_cards = state.tournament_red_cards;
            }           

            return { ...state, tournament_red_cards: new_red_cards, next_tournament_red_cards: action.data.next }
        }

        case "TOURNAMENT:CHANGE_VIEW_MORE_STATISTICS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], view_more_statistics_is_open: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_SCORERS_TABLE_STATE": {
            return { ...state, loading_tournament_scorers: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMEN_YELLOW_CARDS_TABLE_STATE": {
            return { ...state, loading_tournament_yellow_cards: action.new_state}
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_RED_CARDS_TABLE_STATE": {
            return { ...state, loading_tournament_red_cards: action.new_state}
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_SCORERS_TABLE": { 
            let new_scorers = null;
            if(action.data.previous && state.next_tournament_scorers !== action.data.next ){
                new_scorers = state.tournament_scorers.concat( action.data.results );
            }else if(!action.data.previous){
                new_scorers = action.data.results;
            }else{
                new_scorers = state.tournament_scorers;
            }

            return { ...state, tournament_scorers: new_scorers, next_tournament_scorers: action.data.next }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_YELLOW_CARDS_TABLE": { 
            let new_yellow_cards = null;
            if(action.data.previous && state.next_tournament_yellow_cards !== action.data.next ){
                new_yellow_cards = state.tournament_yellow_cards.concat( action.data.results );
            }else if(!action.data.previous){
                new_yellow_cards = action.data.results;
            }else{
                new_yellow_cards = state.tournament_yellow_cards;
            } 
            
            return { ...state, tournament_yellow_cards: new_yellow_cards, next_tournament_yellow_cards: action.data.next }
        }

        case "TOURNAMENT:LOAD_TOURNAMENT_RED_CARDS_TABLE": { 
            let new_red_cards = null;
            if(action.data.previous && state.next_tournament_red_cards !== action.data.next ){
                new_red_cards = state.tournament_red_cards.concat( action.data.results );
            }else if(!action.data.previous){
                new_red_cards = action.data.results;
            }else{
                new_red_cards = state.tournament_red_cards;
            }           

            return { ...state, tournament_red_cards: new_red_cards, next_tournament_red_cards: action.data.next }
        }

        case "TOURNAMENT:CHANGE_VIEW_MORE_STATISTICS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], view_more_statistics_is_open: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "TOURNAMENT:RESET_TOURNAMENT_TEAM_PLAYERS": {
            let clean_tournament_team_players = { ...state['tournament'], tournament_team_players: [] }
            return { ...state, tournament: clean_tournament_team_players, next_tournament_team_players: null}
        }
        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt
        // no default
        
    }

    return state;
}
