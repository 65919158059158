/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProSubscriptionComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaProSubscriptionComponent( props ) {
    const [montValue, setMonthValue] = React.useState(null);
    const [yearValue, setYearValue] = React.useState(null);

    React.useEffect(() => {
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'month') ){
            setMonthValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').value );
        }else{
            setMonthValue(null);
        }
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'year') ){
            setYearValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').value );
        }else{
            setYearValue(null);
        }
    }, [props.prices]);// eslint-disable-line

    return(
        <div className='baloa-pro-subscription__container'>
            {!isMobile &&
                <div className='baloa-pro-subscription__content'>
                    <div className='baloa-pro-subscription__title'>{props.t('baloaProSubscriptionTitle')}</div>
                    <div className='baloa-pro-subscription__buttons'>
                        <PrimaryButtonComponent
                            type={'button'}
                            className={''}
                            onClick={()=>{ props.handleOnClickYearly(yearValue) }}
                            disable={ !yearValue }
                        >
                            <span>{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue })}</span>
                        </PrimaryButtonComponent>
                        <SecondaryButtonComponent
                            type={'button'}
                            className={'large'}
                            onClick={()=>{ props.handleOnClickMonthly(montValue) }}
                            disable={ !montValue }
                        >
                            <span>{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue })}</span>
                        </SecondaryButtonComponent>
                    </div>
                    <div className='baloa-pro-subscription__message baloa-username'>{props.t('baloaProHeader.autoRenewMsg')}</div>
                </div>
            }
            {isMobile &&
                <div className='baloa-pro-subscription__content'>
                    <div className='baloa-pro-subscription__message baloa-username'>{props.t('baloaProHeader.autoRenewMsg')}</div>
                    <div className='baloa-pro-subscription__buttons'>
                        <PrimaryButtonComponent
                            type={'button'}
                            className={''}
                            onClick={()=>{ props.handleOnClickYearly(yearValue) }}
                            disable={ !yearValue }
                        >
                            <span>{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue })}</span>
                        </PrimaryButtonComponent>
                        <SecondaryButtonComponent
                            type={'button'}
                            className={''}
                            onClick={()=>{ props.handleOnClickMonthly(montValue) }}
                            disable={ !montValue }
                        >
                            <span>{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue })}</span>
                        </SecondaryButtonComponent>
                    </div>
                    
                </div>
            }
            
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProSubscriptionComponent);