// React
import React from 'react';

//Stripe
import {CardNumberElement,
    CardExpiryElement,
    CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import InputFieldAutocompletePaymentCountries2 from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompletePaymentCountries2';

//Validators
import { EmailRequiredValidator, RequiredValidator } from 'shared/validators/RequiredValidator';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProStripePaymentModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';



function BaloaProStripePaymentModalComponent ( props ){
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const [clientSecret, setClientSecret] = React.useState('');
    const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = React.useState(false);
    const [cardCvcComplete, setCardCvcComplete] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const formRef = React.createRef();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [succeeded, setSucceeded] = React.useState(false);
    
    const formInitialValues = {
        country: '',
        nombre: '',
        email: '',
    }

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    const CARD_ELEMENT_OPTIONS_MOBILE = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '11px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    React.useEffect(() => {
        if(props.subscription){
            setClientSecret(props.subscription?.latest_invoice?.payment_intent?.client_secret);
            formRef.current.handleSubmit();
        }
    }, [props.subscription]);

    const handlePaymentClick = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        if(!clientSecret){
            await props.createStripeSubscription( formRef.current.values.nombre, formRef.current.values.email, props.countries?.find( country => country.name === formRef.current.values.country).iata_code);
        }else{
            formRef.current.handleSubmit();
        }
        //setLoading(false);
    }

    const handleSubmit = async ( values) => {
        //event.preventDefault();
        setLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: values.email,
                },
            },
            setup_future_usage: 'off_session',
        }  
        );

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        if (paymentIntent.status === 'succeeded') {
            setSucceeded(true);
            props.handleOnPaymentResumeModal( paymentIntent );                
        }

        setLoading(false);
    };

    const valiadateForm = ( values )=> {
        if(values === null){
            setFormValid(false);
        }else if(formRef?.current?.isValid && cardNumberComplete && cardExpiryComplete && cardCvcComplete && formRef?.current?.values.country){
            setFormValid(true);
        }else{
            setFormValid(false);
        }
    }

    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            //onClose={props.onClose}
            className={'baloa-pro-stripe-payment-modal'}
        >
            <div className='baloa-pro-stripe-payment-modal__container'>
                <div className='baloa-pro-stripe-payment-modal__header'>
                    <div className='baloa-pro-stripe-payment-modal__header-title'>
                        <span className='hubicon-award_star'/>
                        <div className='baloa-headline-2'>{props.t('baloaProStripePaymentModal.title')}</div>
                    </div>
                    <div className='baloa-pro-stripe-payment-modal__header-message baloa-username'>{props.t('baloaProStripePaymentModal.subtitle',{ _user_name: props.user_name})}</div>
                    <span className='hubicon-clear_option' onClick={props.onClose}/>
                </div>
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        handleSubmit(values);
                    }}
                    className="baloa-pro-stripe-payment-modal__form scroll"
                    innerRef={formRef}
                    onChange={ ()=>{ valiadateForm()}}
                >
                    
                    <InputFieldComponent
                        label={props.t('baloaProStripePaymentModal.emailLbl')}
                        className="baloa-pro-stripe-payment-modal__input"
                        input_id=""
                        field_name="email"
                        validationFunction={ EmailRequiredValidator}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{email: ''}}
                        message=""
                        onChange={() => { }}
                        //defaultValue={}
                    />
                    <label className='baloa-username'>
                    {props.t('baloaProStripePaymentModal.cardLbl')}
                    <CardNumberElement 
                        options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                        onChange={(event) => {setCardNumberComplete(event.complete); valiadateForm(); }}
                    />
                    </label>
                    <div className='baloa-pro-stripe-payment-modal__columns'>
                        <label className='baloa-username'>
                            {props.t('baloaProStripePaymentModal.expireDateLbl')}
                            <CardExpiryElement 
                                options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                onChange={(event) => {setCardExpiryComplete(event.complete); valiadateForm();}}
                            />
                        </label>
                        <label className='baloa-username'>
                            {props.t('baloaProStripePaymentModal.cvcLbl')}
                            <CardCvcElement 
                                options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                onChange={(event) => {setCardCvcComplete(event.complete); valiadateForm();}}
                            />
                        </label>

                    </div>
                    
                    <InputFieldComponent
                        label={props.t('baloaProStripePaymentModal.nameLbl')}
                        className="baloa-pro-stripe-payment-modal__input"
                        input_id=""
                        field_name="nombre"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{nombre: ''}}
                        message=""
                        onChange={() => {  }}
                        //defaultValue={}
                    />

                    <InputFieldAutocompletePaymentCountries2
                        options={ props.countries }
                        noOptionsText={props.t('baloaProStripePaymentModal.emptyCountryLbl')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "name" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( newCountryValue ) => props.handleSearchCountry( newCountryValue ) }
                        className="baloa-pro-stripe-payment-modal__country-select"
                        label={props.t('baloaProStripePaymentModal.countryLbl')}
                        placeholder=""
                        id="country"
                        field_name="country"
                        validationFunction={ RequiredValidator }
                        resetError={ () => {}}
                        //resetError={ () => props.onResetError( { 'country': "" } )}
                        error={ {country:''} }
                        //value={  }
                        onInputChange={(val)=>{ valiadateForm(val); forceUpdate(); }}
                        autoComplete='off'
                    />
                </FormComponent>
                <div className='baloa-pro-stripe-payment-modal__conditions'>
                    {props.subscription_type === 'month' &&
                        <div className='baloa-table'>{props.t('baloaProStripePaymentModal.monthPaymentBtnLbl', {_price: props.subscription_price, _currency: props.subscription_currency})}</div>
                    }
                    {props.subscription_type === 'year' &&
                        <div className='baloa-table'>{props.t('baloaProStripePaymentModal.yearPaymentBtnLbl', {_price: props.subscription_price, _currency: props.subscription_currency})}</div>
                    }
                    <div className='baloa-username'>{props.t('baloaProStripePaymentModal.paymentSubscriptionMsg')}</div>
                </div>
                {error && 
                    <div className='baloa-pro-stripe-payment-modal__error-message'>
                        <span className='hubicon-info'/>
                        <div className='baloa-table'>
                            <div>{props.t('baloaProStripePaymentModal.subscriptionErrorMsg')}</div>
                            <div>{error} </div>
                        </div>
                    </div>
                }
                <div className='baloa-pro-stripe-payment-modal__buttons'>                    
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={'small'}
                        onClick={handlePaymentClick}
                        disabled={!stripe || loading || !formValid || succeeded}
                    >
                        {loading ?
                            <span className="baloa-pro-stripe-payment-modal__loading" />
                        :                    
                            <span>{props.t('baloaProStripePaymentModal.susbcribeBtnLbl')}</span>
                        }
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaProStripePaymentModalComponent);
