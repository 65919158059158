// React
import React from 'react';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import TournamentMatchComponent from '../tournament-match/TournamentMatchComponent';

// Styles
import './TournamentMatchesDaysAdminComponent.styles.css';

//i18
import { useTranslation } from 'react-i18next';

function TournamentMatchesDaysAdminFiltersComponent ({ 
    date_matches, 
    staff, 
    handleDateClick, 
    handleDeleteMatchTournament, 
    deleteDate,
    handleOnFinishMatchActionClick,
    handleEditMatchTournament,
    tournament_fields,
    handleSubmitEditInfoMatch,
    cities,
    new_page_field,
    handleFieldsAdminActions,
    handleTournamentFieldActions,
    tournament_selected_date,
    has_filters,
    handleNewMatchTournament,
    tournament_dates_filter,
    handleAddStaf,
    handleMatchReport,
    date_matches_is_loading,
    handleDownloadMatchReport,
    next_date_matches,
    handleViewMoreMatches,
    active_group_id,
}) {    

    const { t } = useTranslation('league');
    const [expanded, setExpanded] = React.useState('panel0');
    const [optionsOpen, setOptionsOpen] = React.useState(false);
    const [optionIndex, setOptionIndex] = React.useState();
    const [group, setGroup] = React.useState();       

    React.useEffect( () => {
        if(tournament_selected_date && tournament_dates_filter) {
            let set_tournament_selected_date = tournament_dates_filter.filter( date => date.id === tournament_selected_date );
            let panel_position = set_tournament_selected_date[0]?.name?.split(' ');
            let new_position = panel_position? panel_position[1] : 1;
            new_position -= 1;
            handleChange( `panel${new_position}`, set_tournament_selected_date[0] )( null, true );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournament_selected_date]);

    //Me reinicia la UI a la jornada 1 cuando cambio de grupo
    React.useEffect( () => {
        if(active_group_id && !group && group !== active_group_id){
            setGroup(active_group_id);
        }
        if(active_group_id && group && group !== active_group_id){
            setExpanded('panel0');
            setGroup(active_group_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active_group_id]);

    const handleChange = (panel, day) => (event, newExpanded) => {
        if(newExpanded){
            handleDateClick(day);
        }
        setExpanded(newExpanded ? panel : false);
    }

    const handleOptionsActions = (day) => {
        deleteDate(day, tournament_dates_filter.length);
        setOptionsOpen(false);        
    }

    const handleOpenOptions = (event, index) => {
        if(optionsOpen) {
            setOptionsOpen(false);
        } else {
            setOptionsOpen(true);
        }
        setOptionIndex(index);
        event.stopPropagation();
    }
    
    return (
        <div className="tournament-matches-days-admin__container">
            { tournament_dates_filter.length === 0 
                ?
                <Accordion expanded={ true } >
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-label="Expand"
                        aria-controls={ `aria_controls_0` }
                        id={ `accordion_id_0` }
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={ (event) => event.stopPropagation() }
                            onFocus={ (event) => event.stopPropagation() }
                            control={ <Checkbox disabled icon={<span className='icon-options' />} checkedIcon={<span className='icon-options' />} /> }
                            label={ t(`options.tournaments.pages.matchRefereesPayment.Matchday 1`) }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='tournament-matches-days-admin__msg baloa-hyperlink-2'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNoMatchesMessage')}</div>
                        <div className='tournament-matches-days-admin__msg baloa-hyperlink-2' onClick={ () => handleNewMatchTournament() }>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelCreateMatch')}</div>
                    </AccordionDetails>
                </Accordion>
                :
                tournament_dates_filter?.map( (day, index) => (
                    <Accordion key={index} onChange={ handleChange(`panel${index}`, day) } expanded={ expanded === `panel${index}` } >
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-label="Expand"
                            aria-controls={`aria_controls_${index}` }
                            id={ `accordion_id_${index}` }
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={ (event) =>  handleOpenOptions(event, index) }
                                onFocus={ (event) => event.stopPropagation() }
                                control={ 
                                            <div className='tournament-matches-days-admin__day-options' >
                                                {tournament_dates_filter?.length !== 1 &&
                                                    <div className={ `tournament-matches-days-admin__day-options__action ${ optionsOpen && (index === optionIndex) ? 'show' : '' } ` } onClick={ () => handleOptionsActions(day) } >
                                                        <span className='baloa-names'>{t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningDeleteDate.labelAction')}</span>
                                                    </div>
                                                }                                                
                                                <Checkbox icon={<span className='icon-options' />} checkedIcon={ <span className='icon-options' /> } />
                                            </div> 
                                            
                                        }
                                //disabled={tournament_dates_filter?.length === 1}
                                label={ day.caption }
                            />                            
                        </AccordionSummary>
                            {date_matches_is_loading? 
                                <AccordionDetails>
                                    <div className="phase-fixtures-resume__loader"></div>
                                </AccordionDetails>
                                :
                                date_matches?.length > 0  
                                ?
                                <AccordionDetails>
                                    { date_matches?.map( match => (
                                        <TournamentMatchComponent 
                                            match={ match }
                                            staff={ staff }
                                            handleDeleteMatchTournament={ handleDeleteMatchTournament }
                                            handleOnFinishMatchActionClick={ handleOnFinishMatchActionClick }
                                            handleEditMatchTournament={ handleEditMatchTournament }
                                            tournament_fields={ tournament_fields }
                                            handleSubmitEditInfoMatch={ handleSubmitEditInfoMatch }
                                            cities = { cities }
                                            new_page_field={ new_page_field }
                                            handleFieldsAdminActions={ handleFieldsAdminActions }
                                            handleTournamentFieldActions={ handleTournamentFieldActions }
                                            handleAddStaf= { handleAddStaf }
                                            handleMatchReport= { handleMatchReport }
                                            handleDownloadMatchReport={ handleDownloadMatchReport }
                                        />
                                    ))}
                                    {next_date_matches && 
                                        <div className='tournament-matches-days-admin__view-more baloa-names' onClick={()=>{handleViewMoreMatches(next_date_matches)}}>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelViewMore')}</div>
                                    }                                   
                                </AccordionDetails>
                                :
                                <AccordionDetails>
                                    { !has_filters
                                        ?
                                        <React.Fragment>
                                            <div className='tournament-matches-days-admin__msg baloa-hyperlink-2'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNoMatchesMessage')}</div>
                                            <div className='tournament-matches-days-admin__msg baloa-hyperlink-2' onClick={ () => handleNewMatchTournament() } >{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelCreateMatch')}</div>
                                        </React.Fragment>
                                        :
                                        <div className='tournament-matches-days-admin__msg baloa-hyperlink-2'>{t('No hay resultados')}</div>

                                    }
                                </AccordionDetails>
                            }                        
                    </Accordion>
                ))}           
        </div>
    );
}

export default TournamentMatchesDaysAdminFiltersComponent;